import React, { Component } from 'react';
import Navbar from './includes/navbar';
import Footer from './includes/footer';
import './career.css';
import $ from "jquery";


class TravoliersClub extends Component {


    componentDidMount() {
        // Mobile menu

        $('.toggle_menu').mouseup(function () {
            $(this).toggleClass("on");
            if($(window).width() <= 550) {
                if($(".mobile_menu").hasClass('visible')) {
                    $('.mobile_menu').hide();
                }
                else {
                    $('.mobile_menu').show();
                }
                $('.mobile_menu').toggleClass('visible');
            } else {
                if($(".topline_bg nav").hasClass('visible')) {
                    $('.topline_bg nav').hide();
                }
                else {
                    $('.topline_bg nav').show();
                }
                $('.topline_bg nav').toggleClass('visible');
            }

        });

    }


    render() {
        return (


            <div className="travoilier-main">

                <header style={{marginBottom: '0px'}} className="careers-bg travolier_bg">
                    <Navbar />


                    <div className="header_content content" style={{paddingTop: '0', marginTop:'200px'}}>
                        <span style={{color: 'white', fontSize: '3.5em', fontWeight:'bold'}}>The Ultimate VIP Club For Travelers</span>
                        <p style={{color: 'white', fontSize: '2em', fontWeight:'bold'}}>Save Money. Get Cashback. Earn Rewards.</p>
                    </div>
                </header>


                <div className="main sm-sz">

                    <section className="video_section" id="about">
                        <div className="content">
                            <h2 className="section_header section_header_line" style={{textAlign:'left'}}>You're Invited to Join the Travolier's Club</h2>

                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-7" style={{padding: '20px 40px 20px 0px'}}>
                                        <span>Do you like to travel? Do you like to save money? Then Travolier is for you. .<br /><br /> The Travolier's Club is an exclusive VIP club for life-long nomads, spontaneous adventurers, travel bloggers, family vacationers and business travelers alike.
 <br/><br/>We believe in a theory that if you do what you love, it becomes your passion. <br/><br/>If you're reading this, you've been targeted as a passionate traveler and have been chosen as one of the lucky few who will get early access to the Travolier's Club and earn 5 HotelierCoin just for joining.
</span> <br></br><br></br>
<span>Members receive the following benefits:</span>
<ul className="member_benefits">
    <li>Up to 4% instant cashback on all purchases</li>
    <li>Access to exclusive prices & deals (20-30% off)
</li>
    <li>Personalized experiences through preference profiles
</li>
    <li>Rewards for sharing your travels with the community
</li>
    <li>Enhanced security protections for your personal data
</li>
   
</ul>
                                    </div>

                                    <div className="col-sm-5" style={{textAlign: 'center'}}>
                                        <img className="right-col-image" width="460" style={{marginTop: '20px',width:'100%', border: '2px solid #6b2574', display: 'block', maxWidth: '100%;'}} src="https://cdn.pixabay.com/photo/2015/07/15/11/49/success-846055_960_720.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                   


                    <div className=" app_download_section subscribe_now hotelier_in_numbers __web-inspector-hide-shortcut__" style={{padding: '80px 0px'}}>
                        <div className="content">
                            <div style={{ maxWidth: '100%', padding: '80px 60px', textAlign: 'center'}}>
                                <h4 style={{ fontFamily: 'inherit', textAlign: 'center', fontSize:'1em',lineHeight:'20px', fontWeight: '600', color: '#383838'}}>Just download the app to earn 5 HotelierCoin and register to start saving money on hotels, vacation homes, car rentals, flights and other travel experiences.</h4>
                                <h4 style={{ fontFamily: 'inherit', textAlign: 'center', fontSize:'1em',lineHeight:'20px', fontWeight: '600', color: '#383838'}}>There's only one catch...</h4>
                                <h4 style={{ fontFamily: 'inherit', textAlign: 'center', fontSize:'1em',lineHeight:'20px', fontWeight: '600', color: '#383838'}}>This offer is only available for a limited time while supplies last. So if you're interested, click the button below to join the Travolier's Club today.
                                </h4>
                                <a href="https://play.google.com/store/apps/details?id=com.app.travolier&hl=en" target="_blank" className="btn">Join</a>

                                
                            </div>
                        </div>
                    </div>


                {/* <div className="row main_row">
                    <div className="col-sm-6">

                    </div>
                    <div className="col-sm-6">
                        <h2>BECOME A TRAVOLIER MEMBER TO UNLOCK EXCLUSIVE DEALS</h2>
                        <p>As soon as you enter the Travolier programme, we are set to offer you exclusive deals, services and special attention while exploring with us. Whether on holiday or travelling for business, we do everything we can to meet your needs and make your trips as pleasant as possible</p>
                        <p>We offer the first of its kind coin reward to our members, thanks to our implementation and integration of blockchain technology to take luxury recreation to the next level.</p>
                        </div>
                        
                </div>
                <div className="row main_row">
                   
                    <div className="col-sm-6">
                        <h2>Purchase More – Earn More</h2>
                        <p>HotelierCoin holders don’t need to be loyal to one brand to earn loyalty rewards. Members can earn HotelierCoin by booking from multiple hotel brands, stand-alone hotels, vacation homes, airlines, car rentals, airport transfers, vacation packages, including usage of independent leisure service providers like city hosts or city chefs. 
                            </p>
                        <p>Our loyalty program is Travolier. A program set aside to reward travelers, hotel and vacation homeowners, leisure service providers and travel influencers. </p>
                        <p>With it, travelers get access to exclusive hotel rates, travel managers, personalised fintech wallet and debit cards. You can also earn cashback in HotelierCoin with every transaction within our parent website Hoteliermart.com to become a Travolier as members that hold HotelierCoin are called.</p>
                        <p>Hoteliercoin can be earned via activities like posting travel posts, pictures and reviews for helping and growing our travel community.</p>
                        </div>
                        <div className="col-sm-6">

                    </div>
                        
                </div>
                <div className="row main_row">
                    <div className="col-sm-6">

                    </div>
                    <div className="col-sm-6">
                        <h2>Start Collecting Loyalty Point</h2>
                        <p>Start collecting points right away by booking directly on our brand website.
                            </p>
                        <p>Travolier who spends over USD 5k in Hoteliercoin becomes Ultimate Travolier. A status that changes to lifetime ultimate Travolier after holding it for 5 years. </p>
                        <p>This comes with perks like:</p>
                        <ul>
                            <li>Personal travel manager for travel plans</li>
                            <li>Increased cashback</li>
                            <li>Upgrades on services; and much more</li>   
                        </ul>
                        </div>
                        
                </div>
                <div className="row main_row">
                   
                   <div className="col-sm-6">
                       <h2>Membership Level</h2>
                       <p>Earning in our loyalty program is based on membership level. The Travolier membership levels are categorized as Travolier (Member), Ultimate and Special Categories Executive and Influencers.</p>
                        <p><strong>Travolier</strong>  Everyone who enrolls to be a member and holds HotelierCoin Benefits: Exclusive Members Rate with up to 2% Cashback in HotelierCoin Access to global call center (travel managers) for USD 3 per reservation - travel managers to curate your vacation.</p>
                        <p>
                            <strong>
                            Ultimate
                            </strong>
                            Travolier who spends USD 5K per month in HotelierCoin for 12 months, after 5 years of Ultimate Status, automatically qualifies for lifelong Ultimate’s. Or Ultimate membership can be bought for USD 750 per year. 
                        </p>
                        <p>
                            <strong>
                            Benefits: 
                            </strong>
                            Exclusive Members Rate with up to 4% Cashback in HotelierCoin with one room night a year for USD 250 Will received HTLC via Airdrop as per the HotelierCoin spent per month. Complimentary access to global call center - travel managers to curate your vacation.
                        </p>
                        <p>*Ultimate program to start after 6 months launch of Travolier Program, 6 months spending will also be taken into consideration.</p>
                       </div>
                       <div className="col-sm-6">

                   </div>
                       
               </div>

               <div className="row main_row">
                    <div className="col-sm-6">

                    </div>
                    <div className="col-sm-6">
                        <h2>Community Points for both Travolier and Ultimate Travolier</h2>
                      
                        <ul>
                            <li>Earns 01 community points for every travel post & pictures with #Travolier, #Betravolier, #HotelierCoin #Cityname #hotel or restaurant name</li>
                            <li>Earn 05 Community points for assisting other travolier with travel plans – by commenting their travel blogs.</li>
                            <li>1000 Community Points qualify for 100 USD worth of Hotelier Coin which can be redeemed through HotelierMart.com</li>   
                        </ul>
                        <p>
                            <strong>Influencers:</strong>
                            This category are design for Travel Bloggers to monetize their contents and influence. 
                        </p>
                        <p>*Community Points program will be launched in 12 -24 months of Travolier program</p>
                        </div>
                        
                </div>
                <div className="row main_row">
                   
                   <div className="col-sm-6">
                       <h2>Influencers: This category are design for Travel Bloggers to monetize their contents and influence.</h2>
                      <ul>
                          <li>Earns 05 community points for every travel post & pictures with #Travolier, #Betravolier, #HotelierCoin #Cityname #hotel or restaurant name
                              </li>
                              <li>Earn 15 Community points for assisting other travolier with travel plans – by commenting their travel blogs.</li>
                              <li>1000 Community Points qualify for 100 USD worth of Hotelier Coin which can be redeemed through HotelierMart.com</li>
                    </ul>            <p>*Community Points program will be launched in 12 -24 months of Travolier program </p>     
                    <p>
                        <strong>Executive</strong> – Membership for USD 750 per year per company</p>  
                        <p>
                        <strong>Benefits</strong>: Exclusive Members Rate with up to 2% Cashback in HotelierCoin to the individual traveler and 2% cashback to the company.
                        </p>
                        <p>
                        Company will be received HTLC via Airdrop as per the HotelierCoin spent per month.
                        </p>
                        <p>
                        Complimentary access to global call center - travel managers to curate your business travel.
                        </p>
                        <p>
                        Access to B2E booking platform with company name: travel.standardbank.hoteliermart.com
                        </p>
                        <p>
                        *Executive program and B2E platform to start after 3 months launch of HotelierMart.com
                        </p>
                        <p>
                        *HotelierCoins are cryptocurrency, which can by earned for every transaction at HotelierMart.com, SearchStays.com and Travolier for every travel content contribution. HTLC will be listed in crypto exchange where coins will be traded or monetized. HTLC can also be redeem at Hoteliermart.com for booking hotels, vacation homes, airline tickets, airport transfers, car rental including hiring city hosts.
                        </p>
                       </div>
                       <div className="col-sm-6">

                         </div>
                       
               </div> */}

                </div>


                <Footer />


            </div>
                    );
    }
}

export default TravoliersClub;
