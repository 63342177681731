import React, { Component } from "react"
import Navbar from "./includes/navbar"
import Footer from "./includes/footer"
import "./career.css"
import SCF from "./public/img/scf.png"
import HTLC from "./public/img/htlc.png"

import $ from "jquery"

class Terms extends Component {
  componentDidMount() {
    // Mobile menu
  }

  render() {
    return (
      <div className={"container"}>
        <header
          style={{
            marginBottom: "0px",
            backgroundImage: "none",
            minHeight: "50px",
          }}
        >
          <Navbar />
        </header>

        <div className={"contentbx"} style={{ padding: "20px" }}>
          <h1 style={{ color: "black", paddingTop: "50px" }}>
            SUMMARY OF PRINCIPAL TERMS OF THE
            <br />
            <br />
            SIMPLE AGREEMENT FOR FUTURE TOKENS
            <br />
            <br />
            ISSUED BY
            <br />
            <br />
            HOTELIER COIN
            <br />
            <br />
            “HTLC” SAFT TOKEN
            <br />
          </h1>

          <div style={{ textAlign: "center" }}>
            <img src={HTLC} />
            <h3>July 18th, 2019</h3>
            <br />
          </div>

          <p>
            The following Summary of Principal Terms of the Simple Agreement for
            Future Tokens (the <b>“SAFT Term Sheet”</b>) is subject to revision
            and is qualified in its entirety by reference to the Simple
            Agreement for Future Tokens (the <b>“SAFT”</b>) which will be
            redeemable for HTLC coins upon the HTLC token public exchange
            listing event ( referred to herein as “IEO”- an Initial Exchange
            Offering). The SAFT is being issued by Global Hotelier Pte Ltd (the{" "}
            <b>“Company”</b>). To the extent that the terms set forth in this
            Term Sheet are inconsistent with or contrary to the terms of the
            SAFT, the SAFT will control.
          </p>
          <br />
          <p>
            The terms described in this SAFT Term Sheet may change, possibly
            substantially. Before purchasing a SAFT Interest, potential
            investors should review the final terms of the investment available
            herein this document.
          </p>
          <br />
          <h4 style={{ background: "yellow" }}>
            <b>
              This offer is not available to resident persons or entities of the
              United States of America and as restricted by other governing
              authorities.
            </b>
          </h4>
          <br />
          <p>
            Details regarding the IEO, also simultaneous to the Token Generation
            Event, and the white paper developed describing the HTLC Tokens
            (will be available on August 7th, 2019 and is also available online
            and herein as an attachment. Investing in a SAFT involves
            significant risks, including the risk of losing all or part of an
            investor’s investment.
          </p>
          <ol
            type="I"
            style={{ listStyleType: "upper-roman", paddingLeft: "10px" }}
          >
            <li>
              <b>OVERVIEW</b>
            </li>
          </ol>
          <br />
          <p>
            <b>Summary of the Investment</b>
          </p>
          <br />
          <p>
            <center>
              <b>Opportunity</b>
            </center>
          </p>
          <br />
          <p>
            As discussed in more detail below,{" "}
            <b>Global Hotelier Pte Ltd (the “Company”</b>) is offering a Simple
            Agreement for Future Tokens (each a <b>“SAFT”</b>) to be exchanged
            upon the IEO date for “HTLC” coins, that provide investors the right
            to receive the Company’s HTLC Tokens (as defined below) on the first
            date of the Company’s first offer and sale of immediately
            deliverable Tokens (the <b>“IEO Token Generation Event”</b>). This
            right is subject to the restrictions described on this Term Sheet
            including the “HTL Vesting Schedule” described herein.
          </p>
          <br />
          <p>
            <center>
              <b>The Company</b>
            </center>
          </p>
          <br />
          <p>
            Hoteliercoin is a travel currency that rewards the entire travel
            ecosystem through HotelierMart.com. HotelierCoin rewards both
            travelers, hotel & vacation homeowners for their loyalty like no
            other reward scheme.{" "}
          </p>
          <br />
          <p>
            Hotelier Coin loyalty program is called as Travolier - The most
            Holistic Travel Loyalty Program. The loyal members will be called as
            Travolier. Travolier will have access to exclusive members deals
            featuring - Multi Branded & Independent Hotels, Vacation Homes, Car
            Rentals, Airlines & Travel Packages. The HotelierCoin member & the
            coin holder gets access to preferred hotel rates & travel
            experiences with increase cash back percentage. No matter which
            airline they fly, whichever branded or independent hotel they choose
            to stay, our Travelers earn HotelierCoin as a cashback reward
            through hoteliermart.com for every transaction.
          </p>
          <br />
          <p>
            The Hoteliermart.com - The Ultimate Hotel & Travel Booking platform
            along with HTLC Tokens for travel and hospitality utility purpose.
            Hoteliercoin will be live from day one of launching hotelier coin
            (HTLC).
          </p>
          <br />
          <p>
            Hotelier Coin is on a mission to disrupt the travel loyalty program
            by replacing loyalty points with loyalty coins. The company’s vision
            to be the ultimate decentralized travel application by rewarding &
            synchronizing the hospitality & travel industry.
          </p>
          <br />
          <p>
            <b>Formation: Global Hotelier Pte Ltd </b> (“The company”) and was
            formed in <b>26th in October, 2018.</b>
          </p>
          <br />
          <p>
            <b>Tokens Offered:</b> The company has authorized 150M “HTLC”
            cryptocurrency utility tokens (“SAFT”). The HTLC tokens which are
            based on the Ethereum (Ethereum is an open-source, public,
            blockchain-based{" "}
          </p>
          <br />
          <p>
            distributed computing platform and operating system featuring smart
            contract functionality. It supports a modified version of Nakamoto
            consensus via transaction based state transitions) blockchain and
            platform, to be linked into the company’s cryptocurrency tokens
            created in the future and has issued and set aside 150 million
            “HTLC” tokens pursuant to a Simple Agreement for Future Tokens{" "}
            <b>(“SAFT”)</b> to be issued by Global Hotelier Pte Ltd and named
            HTLC coins and a total of up to 150 million, including up to 50
            million reserved HTLC tokens related to the HTLC. Once the reserve
            falls below 5M HTLC tokens, the company has special functionality to
            mint new tokens for the purpose of Hotelier Coin rewards, community
            building, and team. HTLC offered in the total of all company IEO’s
            events ( “Initial Exchange Offerings”) .{" "}
          </p>
          <br />
          <img
            src={SCF}
            style={{ width: "100%", height: "auto", textAlign: "center" }}
          />
          <br />
          <p>
            Each Unit of “HTLC” rights to tokens is offered on an exchange basis
            for other acceptable cryptocurrency solely at the discretion of the
            company, or on a cash or service exchange basis at the unit price of
            $1.2 USD per “HTLC” token. The “HTLC” tokens will not be offered
            publicly on any exchange. The company may elect to offer HTLC tokens
            at a discount at its sole discretion with the objective to grow
            hotelier coin ecosystem.
          </p>
          <br />
          <p>
            <b>Closing Date:</b>The closing date of the private sale HTLC
            offering will be October 1st, 2019, or upon completion of the
            offering , whichever comes sooner. The company may extend the
            offering date for up to an additional 90 days, as its election.
          </p>
          <br />
          <p>
            <b>Use of Proceeds: </b>The use of proceeds will be invested to
            power the travel ecosystem to ensure HTLC token holders / members
            gets the best travel deals, and investment is not limited to
            marketing, operations and further enhance the platform to improve
            loyal members (travolier.com) experience.
          </p>
          <br />
          <p>
            Worldwide Offices: The offices are located at: The offices
            headquarters are located in Singapore. The company maintains
            correspondent offices at 28 C Stanley Street, Singapore 068737
          </p>
          <br />
          <h4>
            <center>
              <b>The HTLC TOKENS</b>
            </center>
          </h4>
          <br />
          <p>
            The Tokens named “HTLC TOKENS” are being developed with a long term
            plan to be a form of crypto asset, or virtual currency, and utility
            token that can be used for travel & hospitality utility functions
            (as defined below under “Features of the Network”) developed and
            operated by the Company. More information on these functions is
            provided in this Term Sheet under, “The Network and the Tokens.”
            There is no guarantee that the company will list or exchange such
            HTLC tokens for free trading tokens. HTLC tokens are redeemable only
            for HTLC - ERC20 tokens.{" "}
          </p>
          <br />
          <h4>
            <center>
              <b>Simple Agreement for Future Tokens</b>
            </center>
          </h4>
          <br />
          <p>
            The HTLC currently being offered to potential investors as an
            investment contract between the Company and each investor (the{" "}
            <b>“Investor”</b>) that requires the Company to deliver Tokens to
            the Investor on the terms described below, in exchange for a payment
            of funds by the Investor (the “Investment”) as of the execution date
            of the HTLC.
          </p>
          <br />
          <p>
            Potential Investors should be aware that they will only receive the
            right to receive Tokens, and not the Tokens, when they execute a
            HTLC.
          </p>
          <br />
          <h4>
            <center>
              <b>The Offering</b>
            </center>
          </h4>
          <br />
          <p>
            The offering of HTLCs (the <b>“Offering”</b>) may take place in
            multiple rounds. Each round will permit an Investor to purchase a
            HTLC on different terms. The SAFT converts to one token of HTLC upon
            the vesting period schedule and dates.
          </p>
          <br />
          <p>
            Participation in the Friends/Advisor Sale and the Presale is by
            invitation only and in the Company’s sole discretion and provides a
            10% additional discount.
          </p>
          <br />
          <p>
            Investors must be experienced investors with prior investing
            experience.
          </p>
          <br />
          <p>
            Investors must pass KYC (“Know Your customer”) standards established
            for international banking and submit proof of their identity and
            residency.
          </p>
          <br />
          <p>
            <b>
              The company is restricting the offering to residents and companies
              only outside of the USA.
            </b>
          </p>
          <br />
          <p>
            <b>II. THE SAFT</b>
          </p>
          <br />
          <h4>
            <center>
              <b>Rights to Tokens</b>
            </center>
          </h4>
          <br />
          <h3>
            <u>
              <b>PHASE II</b>
            </u>
          </h3>
          <br />
          <p>
            HTLC(SAFT) will be priced at $1.2 USD per unit each starting
            12:00:00 PM UT On August 7th, 2019. The HTLC (SAFT) will be subject
            to the Use Restriction, as defined in the SAFT.
          </p>
          <br />
          <p>
            <b>Soft Cap:</b> $1 million USD
          </p>
          <br />
          <h4>
            <b>
              IEO Listing: The company’s forecasted hard cap is $10 million USD
              as a result of IEO listing(s).
            </b>
          </h4>
          <br />
          <h3>Investment Process</h3>
          <br />
          <p>
            The Company will accept Bitcoin, and Ether, and/or other
            cryptocurrencies, solely at the discretion of the company, in
            exchange for a SAFT. Instructions for transfers will be provided in
            the SAFT. The wallet address for payments in accepted virtual
            currency will be provided in the SAFT. A SAFT investment is affected
            through an electronically executed SAFT, available on August 7th,
            2019.
          </p>
          <br />
          <h4>
            <center>
              <b>Closings</b>
            </center>
          </h4>
          <br />
          <p>
            The initial offer for HTLC SAFT will close on October 1st 2019 at
            11:59:59 a.m. UTC. The company has the option to extend the offer
            and terms.{" "}
          </p>
          <br />
          <h4>
            <center>
              <b>Projected Timing for Receipt of HTL Tokens</b>
            </center>
          </h4>
          <br />
          <p>
            Investors will receive HTLC Tokens on the first date of the Initial
            Token Generation Event if not redeemed by the redemption date.{" "}
          </p>
          <br />
          <p>
            The Initial Token Generation Event will occur only after the Tokens
            have been fully developed and are fully functional on the Network
            and the company lists its token on an initial exchange offering
            (“IEO”). However, the company is committed to launch the network on
            the same day of issuance of the token. Network will get larger with
            time.
          </p>
          <br />
          <p>
            There is no guarantee that the HTLC tokens can be listed by the end
            of the closing date.
          </p>
          <br />
          <p>
            It is currently anticipated that the Company will list the HTLC
            Tokens for an IEO and on a public exchange by the (4th) quarter of
            2019. There are no guarantees as to the timing of the Initial Token
            Generation Event, which is dependent on many factors, including many
            outside the Company’s control.
          </p>
          <br />
          <center>
            <h3>HTL Vesting Schedule</h3>
            <h4>Vesting Schedule for HTL tokens received from SAFT </h4>
          </center>
          <br />
          <table>
            <thead>
              <tr>
                <th>
                  Amount of Investors SAFT Redeemed for as Free Trading HTLC
                  Tokens
                </th>
                <th>Date Released</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>10%</td>
                <td>Upon IEO</td>
              </tr>
              <tr>
                <td>25%</td>
                <td>90 days after IEO</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>25%</td>
                <td>180 days after IEO</td>
              </tr>
              <tr>
                <td>25%</td>
                <td>12 months after IEO</td>
              </tr>
              <tr>
                <td>15%</td>
                <td>18 months after IEO</td>
              </tr>
            </tfoot>
          </table>

          <br />
          <p>IEO: Initial Exchange Offering.</p>
          <p>
            There is no guarantee that an IEO can be conducted. The IEO
            forecasted date may change due to market conditions.
          </p>
          <br />
          <p>
            Upon IEO: Means upon the company conducting an actual public listing
            on a public cryptocurrency exchange.
          </p>
          <br />
          <h4>
            <center>
              <b>Dissolution Event</b>
            </center>
          </h4>
          <br />
          <p>
            If there is a Dissolution Event (as defined below), the Company will
            make commercially reasonable efforts to reimburse Investments
            received from Investors, but the Company cannot guarantee that
            sufficient funds will be available for any such reimbursement. The
            Company will give priority to the Presale Class of Investors before
            all other Investors.
          </p>
          <br />
          <p>
            <b>“Dissolution Event” </b> means: (i) a voluntary termination of
            operations;
            <br />
            (ii) a general assignment for the benefit of the Company’s
            creditors;
            <br />
            (iii) the failure of the Company to hold a IEO within two (2) years
            from October 1st, 2019; <br />
            (iv) any other liquidation, dissolution or winding up of the
            Company, whether voluntary or involuntary. A change of control and
            an initial public offering will not constitute a Dissolution Event.
            <br />
            (v) The sale of the company and, or its assets to another company
            through a merger or acquisition, and in such case if the company
            receives tokens in such acquisitions, such tokens may be allocated
            to the investors on a pro rata basis.
          </p>
          <br />
          <h4>
            <center>
              <b>Termination</b>
            </center>
          </h4>
          <br />
          <p>
            The SAFT will expire and terminate upon either (i) the issuance of
            Tokens to the Investor or (ii) the payment or setting aside for
            payment of amounts due to the Investor as a result of a Dissolution
            Event{" "}
          </p>
          <br />
          <h4>
            <center>
              <b>Transfers</b>
            </center>
          </h4>
          <br />
          <p>
            There are substantial restrictions on the transferability of the
            SAFT, and there will be no public market for the SAFT. A SAFT may
            not be offered, sold or transferred in the United States absent
            registration or an applicable exemption from the registration
            requirements. In particular, a SAFT may not be transferred unless it
            is approved by the company.{" "}
          </p>
          <br />
          <h4>
            <center>
              <b>Most Favored Nation (“MFN”)</b>
            </center>
          </h4>
          <br />
          <p>
            There are no MFN amendment rights in the SAFT. The Company is under
            no obligation to amend and restate any SAFT based on subsequent
            agreements executed with the company on different terms.
          </p>
          <br />
          <h4>
            <center>
              <b>Incorporation by Reference</b>
            </center>
          </h4>
          <br />
          <p>
            The SAFT is for the future delivery of Tokens and therefore
            incorporates by reference the provisions and representations in the
            SAFT Purchase Agreement (the <b>“Purchase Agreement”</b>). Investors
            will be provided the Purchase Agreement at the same time as the
            SAFT. By executing the SAFT, the Investor agrees to incorporate by
            reference and restate the representations in the Purchase Agreement
            under the SAFT.
          </p>
          <br />
          <h4>
            <center>
              <b>Use of Proceeds</b>
            </center>
          </h4>
          <br />
          <p>
            The Company will use the proceeds received based on the SAFTs net of
            any broker dealer fees to develop, sponsor, and promote the Tokens
            and the Network and for all other general business operations
            incident thereto and permitted by law.
          </p>
          <br />
          <p>
            These allocations are only estimates and are subject to change in
            the Company’s sole discretion and without notice.
          </p>
          <br />
          <p>
            <b>III. THE NETWORK AND THE TOKENS</b>
          </p>
          <br />
          <h4>
            <center>
              <b>Features of the Network</b>
            </center>
          </h4>
          <br />
          <p>
            The Company is launching its own network and digital currency which
            will be used as “utility token” and will be termed as The Travel
            Reward Currency.
          </p>
          <br />
          <p>
            Network to featuring multi branded and independent hotels, vacation
            homes, car rentals, airport transfers, airlines, travel packages and
            other hospitality and travel services. Network domain:
            <a href="https://www.travolier.com/">https://www.travolier.com/</a>
          </p>
          <br />
          <p>
            During the first three months our network will be as below:
            <br />( Numbers my change, below numbers were provided by the signed
            vendors).
          </p>
          <br />
          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
            <li>600 000+ hotels in 185+ countries, over 5 000+ cities</li>
            <li> 700 Airlines</li>
            <li> Airport transfers in 120 countries</li>
            <li>Car rentals available in 140 countries</li>
            <li> Leisure Service Providers</li>
          </ul>
          <p>And numbers keep growing!</p>
          <br />
          <h4>
            <center>
              <b>Purpose of the HTLC Tokens</b>
            </center>
          </h4>
          <br />
          <p>
            The HTLC Tokens are cryptocurrency type travel loyalty tokens, which
            will be used for hospitality and travel booking and other travel
            payment services. Network participants and users of the network can
            earn HTLC as a Cashback for every transaction, which will create the
            demand for HTLC. HTLC can also be utilized for Peer to Peer
            exchange. Uses of the Tokens include but are not limited to:
            Hoteliermart.com. HotelierCoin can be earned as a loyalty coin in
            form of cashback for every transaction in Hoteliermart.com. HTLC
            Tokens will be used to pay for Hotels, Airlines, Car rentals,
            Airport transfer, Vacation rentals, Travel Packages, not limited to
            paying for Tour guides, Chefs, or hospitality vendors.{" "}
          </p>
          <br />
          <h4>
            <center>
              <b>Additional Terms of the Tokens</b>
            </center>
          </h4>
          <br />
          <p>
            The HTLC Tokens will be transferable between holders. This may
            include the ability for a Token holder to "tip" another holder or
            Network participant in exchange for a service, among other things.
            Transfers of Tokens will be subject to the restrictions and
            limitations described online and updated online from time to time.
          </p>
          <br />
          <h4>
            <center>
              <b>Other Token Utility</b>
            </center>
          </h4>
          <br />
          <p>
            The Company intends for the HTLC Tokens to be used to pay for
            creating workspaces and securely sharing digital assets, among other
            things. The utility of the Tokens therefore relies in part on the
            third-party adoption of the Network for secure file sharing and
            syndication of content. There is no guarantee that such adoption
            will occur. It is possible that the Company may develop additional
            uses for the Tokens, but no additional uses are anticipated at this
            time. It is also possible that third-party exchange services may
            accept the Tokens and exchange them for sovereign fiat currency or
            other crypto assets. There is no guarantee, however, that any third
            party uses of Tokens or the Network will develop.
          </p>
          <br />
          <p>
            For more information about the terms of the Tokens, please visit{" "}
            <a href="www.hoteliercoin.io">
              <u>www.hoteliercoin.io</u>
            </a>
          </p>
          <br />
          <h4>IV. LEGAL & MISCELLANEOUS</h4>
          <br />
          <h4>
            <center>
              <b>Taxation</b>
            </center>
          </h4>
          <br />
          <p>
            There is no guarantee that the Company will be successful at
            developing the HTLC Tokens or the Network. Further, there is no
            guarantee that merchants and other potential Network participants
            will accept the Tokens as a medium of exchange for goods and
            services. A government could even prohibit the commercial or
            non-commercial use of the cryptographic methods necessary to the
            release of the Tokens and operation of the Network. Any of these
            negative outcomes could lead to the Company foregoing its plan to
            conduct the Token Generation Event and/or otherwise release the
            Tokens. As a result, the Company may be unable to deliver Tokens
            pursuant to the SAFT or reimburse the Purchase Price received under
            a SAFT.
          </p>
          <br />
          <p>
            There is no guarantee that the company will be able to list its HTLC
            token on a public utility token exchange.
          </p>
          <br />
          <p>
            There can be no assurance that any Investor will achieve his or her
            investment objective or avoid substantial losses by investing in
            Tokens through a SAFT. Instruments like the SAFT and the Tokens
            entail a high degree of risk, and Investors may lose some or all of
            their Investment. A potential Investor should execute a SAFT and
            invest in Tokens only if able to withstand a total loss of the
            Investment. Potential Investors are urged to consult with their
            personal investment, legal and tax advisors before executing a SAFT.
          </p>
          <br />
          <p>
            Crypto assets such as the Tokens are a new and relatively untested
            product. There is considerable uncertainty about the asset class’s
            long-term viability, which could be affected by a variety of
            factors, including many market-based factors such as economic
            growth, inflation, and others. In addition, the success of the
            Tokens and other types of crypto assets will depend on whether
            blockchain and other new technologies related to the Tokens turn out
            to be useful and economically viable. The Company does not control
            any of these factors, and therefore may not be able to control the
            long-term success of the Tokens as a feature of the Network, or the
            ability of the Tokens to maintain their value.
          </p>
          <br />
          <p>
            Transactions in crypto assets such as Tokens and certain other
            virtual currencies are irreversible. Failure to provide the Company
            with the correct public wallet address under the control of the
            Investor will, in most cases, result in the loss of the Tokens.
          </p>
          <br />
          <p>
            The terms described in this SAFT Term Sheet are subject to change
            prior to the Initial Token Generation Event, possibly substantially.
            The Company cannot guarantee that the Tokens and the Token
            Generation Event will have the same characteristics as described
            herein on the first date of the Initial Token Generation Event.
          </p>
          <br />
          <p>
            The undersigned purchaser agrees that the above terms are the SAFT
            terms of HTL Hotelier and they agree to the terms.
          </p>
        </div>

        <Footer />
      </div>
    )
  }
}

export default Terms
