import React, {Component} from 'react';
import Sidebar from "../cabinet/sidebar";
import TopBar from "./topBar"
import '../public/css/account-custom.css';
import './tokensale.css';
import {Link} from "react-router-dom";
import { IoIosCloseCircle } from "react-icons/io";



class paymentCancle extends Component {


    componentDidMount() {
        let current=this;
        if(!localStorage.getItem('token')){
            current.props.history.push("/");
        }

    }


    render() {
        return (
            <div>
                <Sidebar/>
                <TopBar/>
                <div className="main-div">
                    <div className="main_block calc main-block-custom" style={{marginTop: '40px'}} id="activeCurrency" data-rate="5412.16477899" data-symbol="BTC">
                        <div className="wrap">
                            <div className="box" style={{    width: '530px', margin: 'auto'}}>


                                <div className={'payment-box'} style={{textAlign:'center'}}>
                                    <IoIosCloseCircle style={{fontSize:'3em', color:'white'}} />

                                    <h1>Payment Canceled</h1>
                                    <h3>Transaction Number : 456454675</h3>
                                </div>



                                <div className="row" style={{width:'500px', background:'#6c2474'}}>

                                    <div className="halfCircleRight">

                                    </div>
                                    <div className="" style={{width:'460px', background:'#6c2474', paddingTop: '20px'}}>
                                        <hr className={'hrpymnt'} />
                                    </div>
                                    <div className="halfCircleLeft">

                                    </div>
                                </div>
                                <div className={'payment-box-opp'} style={{textAlign:'center'}}>
                                    <h1>Amount : 10 LTC</h1>
                                    <h3 style={{paddingBottom : '40px'}}>Currency : LTC</h3>
                                </div>




                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default paymentCancle;
