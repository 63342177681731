import React from 'react';
import './roadmap.css';
import './map';
import 'owl.carousel/dist/assets/owl.carousel.css';
import Plebiscite from './public/landing/img/partners/plebiscite.png';
import Sragy from './public/landing/img/partners/sragy.jpeg'
import RMS from './public/landing/img/partners/rms.png'

import $ from "jquery";


var jQuery = $
window.jQuery = $

// dynamically require owl
require('owl.carousel')






class Try extends React.Component {

    componentDidMount() {


        //carousel
        $('.partners').owlCarousel({
            loop: true,
            margin: 10,
            nav: true,
            autoplay: false,
            navText: ['',''],
            dots: false,
            responsive:{
                0:{
                    items: 1,
                },
                590:{
                    items: 2,
                },
                860:{
                    items: 3
                }
            }
        });

        // partners info

        $('.partner-div').on('click', '.read_more', function(e) {
            e.preventDefault();

            var $partner = $(this).parent();
            $(this).toggleClass('opened');
            if($(this).hasClass('opened')) {
                $(this).text('Show less');
                $($partner).find('.partner-description').css({
                    height: 'auto',
                });
            }
            else {
                $(this).text('Show more...');
                $($partner).find('.partner-description').css({
                    height: '3.1em',
                });
            }
        });

    }


    render() {

        return (
            <section id="partners" className="content">
                <h2 className="section_header section_header_line" style={{marginTop: '0px'}}>We are proud to present our
                    strategic partners</h2>
                <div className="partners owl-carousel owl-loaded owl-drag">


                    <div className="owl-stage-outer">
                        <div className="owl-stage" style={{transform: 'translate3d(-1077px, 0px, 0px)', transition: 'all 0s ease 0s', width: '3234px'}}>
                            <div className="owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="partner-wrap">
                                    <div className="partner-div">
                                        <a href="https://www.plebiscite.in/" target="_blank" className="partner">
                                            <img src={Plebiscite} alt="gravitas" />
                                        </a>
                                        <div className="partner-description">
                                            <p>
                                                Plebiscite IT Services is a blockchain and IT services company. They
                                                specialise in providing expert technical support to innovative start-up
                                                companies that utilise blockchain technology. Plebiscite have developed
                                                the most advanced blockchain architecture currently available on the
                                                market. Their enthusiasm to discover new ways to apply blockchain
                                                technology to modern industry continues to drive the company forward
                                                while their IT Services and Support remains invaluable. </p></div>
                                        <a className="read_more" href="#">Show more...</a>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-item-xxx owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="partner-wrap">
                                    <div className="partner-div">
                                        <a href="https://sragy.com/" target="_blank" className="partner">
                                            <img src={Sragy} alt="ti infotech" />
                                        </a>
                                        <div className="partner-description">
                                            <p>
                                                Sragy is a platform enthusiastically designed and maintained by a group
                                                of Cryptocurrency lovers, team who have worked in leading Information
                                                Technology and Financial Market. We have an extensive track record in
                                                crypto world and cherish the trust we’ve gained over the years.
                                            </p>

                                        </div>
                                        <a className="read_more" href="#">Show more...</a>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-item-xxx owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="partner-wrap">
                                    <div className="partner-div">
                                        <a href="https://www.rmscloud.com/about-us/our-partners/" target="_blank"
                                           className="partner">
                                            <img src={RMS} alt="rms cloud" />
                                        </a>
                                        <div className="partner-description">
                                            <p>
                                                RMS, The Hospitality Cloud, is a world-renowned software company
                                                headquartered in Australia.
                                                RMS specializes in producing and supporting Online Booking, Channel
                                                Management and Front Office
                                                Systems to the world’s hospitality industry.
                                            </p>
                                            <p>
                                                RMS has been at the forefront of property and reservations management
                                                systems development for
                                                over 30 years for the Holiday Park, Caravan and RV Park, Hotel, Motel
                                                and Apartment segments with
                                                specialty applications for the Mining, Defense, Facility and Shopping
                                                Center industries.
                                            </p>
                                            <p>
                                                The RMS Property Management System offers the unique ability to provide
                                                a real-time online booking
                                                functionality, enabling the collection of bookings from a property’s
                                                website, global distribution
                                                system and an expansive variety of third-party travel websites.
                                            </p>
                                            <p>
                                                Currently, RMS supports over 6,000 properties across 30 countries,
                                                enabling them to complete online
                                                bookings, maintain channel management and operate a front office system
                                                in a single application.
                                            </p>
                                        </div>
                                        <a className="read_more" href="#">Show more...</a>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-item-xxx owl-item active" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="partner-wrap">
                                    <div className="partner-div">
                                        <a href="https://www.plebiscite.in/" target="_blank" className="partner">
                                            <img src={Plebiscite} alt="gravitas" />
                                        </a>
                                        <div className="partner-description" style={{height: '3.1em'}}>
                                            <p>
                                                Plebiscite IT Services is a blockchain and IT services company. They
                                                specialise in providing expert technical support to innovative start-up
                                                companies that utilise blockchain technology. Plebiscite have developed
                                                the most advanced blockchain architecture currently available on the
                                                market. Their enthusiasm to discover new ways to apply blockchain
                                                technology to modern industry continues to drive the company forward
                                                while their IT Services and Support remains invaluable. </p></div>
                                        <a className="read_more" href="#">Show more...</a>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-item-xxx owl-item active" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="partner-wrap">
                                    <div className="partner-div">
                                        <a href="https://sragy.com/" target="_blank" className="partner">
                                            <img src={Sragy} alt="ti infotech" />
                                        </a>
                                        <div className="partner-description" style={{height: 'auto'}}>
                                            <p>
                                                Sragy is a platform enthusiastically designed and maintained by a group
                                                of Cryptocurrency lovers, team who have worked in leading Information
                                                Technology and Financial Market. We have an extensive track record in
                                                crypto world and cherish the trust we’ve gained over the years.
                                            </p>

                                        </div>
                                        <a className="read_more opened" href="#">Show less</a>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-item-xxx owl-item active" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="partner-wrap">
                                    <div className="partner-div">
                                        <a href="https://www.rmscloud.com/about-us/our-partners/" target="_blank"
                                           className="partner">
                                            <img src={RMS} alt="rms cloud" />
                                        </a>
                                        <div className="partner-description" style={{height: 'auto'}}>
                                            <p>
                                                RMS, The Hospitality Cloud, is a world-renowned software company
                                                headquartered in Australia.
                                                RMS specializes in producing and supporting Online Booking, Channel
                                                Management and Front Office
                                                Systems to the world’s hospitality industry.
                                            </p>
                                            <p>
                                                RMS has been at the forefront of property and reservations management
                                                systems development for
                                                over 30 years for the Holiday Park, Caravan and RV Park, Hotel, Motel
                                                and Apartment segments with
                                                specialty applications for the Mining, Defense, Facility and Shopping
                                                Center industries.
                                            </p>
                                            <p>
                                                The RMS Property Management System offers the unique ability to provide
                                                a real-time online booking
                                                functionality, enabling the collection of bookings from a property’s
                                                website, global distribution
                                                system and an expansive variety of third-party travel websites.
                                            </p>
                                            <p>
                                                Currently, RMS supports over 6,000 properties across 30 countries,
                                                enabling them to complete online
                                                bookings, maintain channel management and operate a front office system
                                                in a single application.
                                            </p>
                                        </div>
                                        <a className="read_more opened" href="#">Show less</a>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-item-xxx owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="partner-wrap">
                                    <div className="partner-div">
                                        <a href="https://www.plebiscite.in/" target="_blank" className="partner">
                                            <img src={Plebiscite} alt="gravitas" />
                                        </a>
                                        <div className="partner-description">
                                            <p>
                                                Plebiscite IT Services is a blockchain and IT services company. They
                                                specialise in providing expert technical support to innovative start-up
                                                companies that utilise blockchain technology. Plebiscite have developed
                                                the most advanced blockchain architecture currently available on the
                                                market. Their enthusiasm to discover new ways to apply blockchain
                                                technology to modern industry continues to drive the company forward
                                                while their IT Services and Support remains invaluable. </p></div>
                                        <a className="read_more" href="#">Show more...</a>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-item-xxx owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="partner-wrap">
                                    <div className="partner-div">
                                        <a href="https://sragy.com/" target="_blank" className="partner">
                                            <img src={Sragy} alt="ti infotech" />
                                        </a>
                                        <div className="partner-description">
                                            <p>
                                                Sragy is a platform enthusiastically designed and maintained by a group
                                                of Cryptocurrency lovers, team who have worked in leading Information
                                                Technology and Financial Market. We have an extensive track record in
                                                crypto world and cherish the trust we’ve gained over the years.
                                            </p>

                                        </div>
                                        <a className="read_more" href="#">Show more...</a>
                                    </div>
                                </div>
                            </div>
                            <div className="owl-item-xxx owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="partner-wrap">
                                    <div className="partner-div">
                                        <a href="https://www.rmscloud.com/about-us/our-partners/" target="_blank"
                                           className="partner">
                                            <img src={RMS} alt="rms cloud" />
                                        </a>
                                        <div className="partner-description">
                                            <p>
                                                RMS, The Hospitality Cloud, is a world-renowned software company
                                                headquartered in Australia.
                                                RMS specializes in producing and supporting Online Booking, Channel
                                                Management and Front Office
                                                Systems to the world’s hospitality industry.
                                            </p>
                                            <p>
                                                RMS has been at the forefront of property and reservations management
                                                systems development for
                                                over 30 years for the Holiday Park, Caravan and RV Park, Hotel, Motel
                                                and Apartment segments with
                                                specialty applications for the Mining, Defense, Facility and Shopping
                                                Center industries.
                                            </p>
                                            <p>
                                                The RMS Property Management System offers the unique ability to provide
                                                a real-time online booking
                                                functionality, enabling the collection of bookings from a property’s
                                                website, global distribution
                                                system and an expansive variety of third-party travel websites.
                                            </p>
                                            <p>
                                                Currently, RMS supports over 6,000 properties across 30 countries,
                                                enabling them to complete online
                                                bookings, maintain channel management and operate a front office system
                                                in a single application.
                                            </p>
                                        </div>
                                        <a className="read_more" href="#">Show more...</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="owl-nav disabled">
                        <div className="owl-prev"></div>
                        <div className="owl-next"></div>
                    </div>
                    <div className="owl-dots disabled"></div>
                </div>
            </section>
        )
    }
}

export default Try;






