import React, { Component } from "react";
import axios from "axios";

import Logo from "../public/img/logo_dark.png";
import RegisterForm from "./RegisterForm";
import Eye from "../public/img/eye.svg";
// import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-google';
import { ToastsContainer, ToastsStore } from "react-toasts";
import codes from "../codes.json";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import $ from "jquery";
import loader from "../public/img/loader_dots.gif";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ReCAPTCHA from "react-google-recaptcha";

// function onChange(value) {

//         this.setState({captcha:value});
//         //console.log("Captcha value:", this.state.captcha);
//       }
class Register extends Component {
  render() {
    //console.log(this.state)
    return (
      <div id="large-header" className="">
        <div className="page register-page">
          <div className="vimeo-wrapper">
            <iframe
              src="https://player.vimeo.com/video/355503337?background=1&autoplay=1&loop=1&byline=0&title=0"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            ></iframe>
          </div>
          <div className="form-outer text-center d-flex align-items-center register-from-outer">
            <div className="form-inner">
              <div className="logo text-uppercase">
                <a href="/">
                  {" "}
                  <img src={Logo} />{" "}
                </a>
              </div>

              <RegisterForm />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
