import React, {Component} from 'react';
import Sidebar from "../cabinet/sidebar";
import TopBar from "./topBar"
import account from '../public/css/account.css';
import '../public/css/account-custom.css';
import './tokensale.css';
import bch from "../public/img/bch-coin.png";
import eth from "../public/img/eth.png";
import ltc from "../public/img/ltc.png";
import dash from "../public/img/dash.png";
import btc from "../public/img/btc.png";
import dummy from "../public/img/dummy_user.png";
import { Link } from 'react-router-dom';

class MakePayment extends Component {

    constructor(props){
        super(props);
        this.activeFn = this.activeFn.bind(this);
        this.copySuccess = '';
        this.state = { copySuccess: '' };
    }

    activeFn(indexOnClicked) {
        var testarray = document.getElementsByClassName("custom-col3");
        for(var i = 0; i < testarray.length; i++)
        {
            testarray[i].className = "custom-col3 ";
        }
        testarray[indexOnClicked].className += 'active-class';
    }

    copyToClipboard = () => {
        //console.log(this.copySuccess.innerHTML);
    };

    initRef = c => (this.copySuccess = c);

    render() {
        return (
            <div>
                <Sidebar/>
                <TopBar/>
                <div className="main-div">
                    <div className="main_block calc main-block-custom" style={{marginTop: '40px', position:'relative'}} id="activeCurrency" data-rate="5412.16477899" data-symbol="BTC">
                        <div className="wrap">
                            <div style={{textAlign:'center'}}>
                                <h1 style={{color:'inherit', paddingTop:'50px'}}>Make Payment</h1>
                                <p className="auth_desc content-font-color-custom">Payment window is open of 30min, transaction done after this will be updated by support team manually.</p>
                            </div>
                            <div className="row5">
                                <div className="custom-col5" style={{verticalAlign: 'top'}}>
                                    <div style={{display:'flex', marginBottom:'50px', marginTop:'50px'}}>
                                        <div style={{width:'130px',paddingLeft:'20px'}}>
                                            <div className="loader-payments"></div>
                                            <span className={'confermation'}>4/10</span>
                                        </div>
                                        <div>
                                            <h3 style={{paddingTop: '15px'}}>Waiting for Payment</h3>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', marginBottom:'50px'}}>
                                        <div style={{width:'130px',paddingLeft:'20px'}}>
                                            <div className="no-loader-payments"></div>
                                        </div>
                                        <div>
                                            <h3 style={{paddingTop: '15px'}}>Exchanging</h3>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', marginBottom:'50px'}}>
                                        <div style={{width:'130px',paddingLeft:'20px'}}>
                                            <div className="no-loader-payments"></div>
                                        </div>
                                        <div>
                                            <h3 style={{paddingTop: '15px'}}>Updating Wallet</h3>
                                        </div>
                                    </div>
                                    <div style={{paddingLeft:'30px', marginTop:'50px'}}>
                                        <Link to={'tokensale'} className={'btn'} style={{borderRadius:'5px'}} >GO BACK</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MakePayment;
