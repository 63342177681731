import React from "react"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import Forbs from "./public/landing/img/news/forbs.png"
import Medium from "./public/landing/img/news/medium.com lgo.png"
import Crunchbase from "./public/landing/img/news/Crunchbase.png"

class Media extends React.Component {
  responsive = {
    0: { items: 1 },
    600: { items: 1 },
    960: { items: 3 },
  }

  stagePadding = {
    paddingLeft: 30,
    paddingRight: 30,
  }

  render() {
    return (
      <section className="media_sec">
        <div className="content" style={{ paddingTop: "0px" }}>
          <h2 className="section_title ">Media</h2>

          <AliceCarousel
            duration={2000}
            buttonsDisabled={true}
            autoPlay={true}
            mouseDragEnabled={true}
            onSlideChanged={console.debug}
            infinite={true}
            dotsDisabled={true}
            showSlideInfo={false}
            responsive={this.responsive}
            stagePadding={this.stagePadding}
          >
            <div className="item">
              <div className="news_item forbs">
                <a
                  target="_blank"
                  href="https://www.forbes.com/sites/rebeccacampbell1/2018/10/16/could-the-hotelier-coin-be-the-answer-to-revamping-the-hospitality-industry/#1e6571e6d419"
                >
                  <img src={Forbs} alt="news" className={"img-responsive"} />
                </a>
              </div>
            </div>
            <div className="item">
              <div className="news_item medium">
                <a target="_blank" href="https://medium.com/@HotelierCoin">
                  <img src={Medium} alt="news" className={"img-responsive"} />
                </a>
              </div>
            </div>
            <div className="item">
              <div className="news_item crunchbase">
                <a
                  target="_blank"
                  href="https://www.crunchbase.com/organization/hotelier-mart"
                >
                  <img
                    src={Crunchbase}
                    alt="news"
                    width={"320"}
                    className={"img-responsive"}
                  />
                </a>
              </div>
            </div>
          </AliceCarousel>
        </div>
      </section>
    )
  }
}

export default Media
