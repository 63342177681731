import React, { Component } from 'react';
import Sidebar from "../cabinet/sidebar";
import TopBar from "./topBar"
import '../public/css/account.css';
import '../public/css/account-custom.css';
import './tokensale.css';
import axios from 'axios';
import Coin from "../public/img/coin_logo.png";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import $ from "jquery";
class AirdropDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTravolierData:false,
            showAllTravolierData:true,
            tableData: [],
            airDropBalance:[],
            travolier_id:'',
            travolierData:[],
            airDropAll:[],
            formattedDate:''
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitTravolierAdd = this.onSubmitTravolierAdd.bind(this);
    }
    onSubmit(){       
      
     const header = {
         headers: {
             "authorization" : localStorage.getItem('authorization')
         }
     }
     
     axios.post('https://hoteliercoins.co/api/v1/travolier/search',{travolier_id:this.state.travolier_id},{headers: header.headers})
     .then(res=>{
         //console.log(res);
         if(res.data.status == true){  
           //rajesh   
           this.setState({showAllTravolierData:false});
           this.setState({showTravolierData:true})     
            this.setState({travolierData:res.data.data});
            //console.log(this.state.travolierData);
            // toastr.success('Travolier added successfully');
         }
         else if(res.data.message == "jwt expired"){
             localStorage.clear();
             this.props.history.push('/');
         }
         else{
             //console.log("error");
             //ToastsStore.info(res.data.message);
             toastr.error(res.data.message)
         }
         
        
     })
 }
 onSubmitTravolierAdd(){       
      
  const header = {
      headers: {
          "authorization" : localStorage.getItem('authorization')
      }
  }
  
  axios.post('https://hoteliercoins.co/api/v1/travolier/airdrop',{travolier_id:this.state.travolierData.travolier_id},{headers: header.headers})
  .then(res=>{
      //console.log(res);
      if(res.data.status == true){  
          this.setState({showTravolierData:false});
          this.setState({showAllTravolierData:true});
        toastr.success(res.data.data.message);       
        
         // toastr.success('Travolier added successfully');
      }
      else if(res.data.message == "jwt expired"){
          localStorage.clear();
          this.props.history.push('/');
      }
      else{
          //console.log("error");
          //ToastsStore.info(res.data.message);
          toastr.error(res.data.message)
          this.setState({showTravolierData:false});
          this.setState({showAllTravolierData:true});
      }
      
     
  })
}
    handleChange(e) {
      const { name, value } = e.target;     
      this.setState({ [name]: value });
      //console.log(this.state)
  }
    componentDidMount () {
      //console.log(this.state.travolierData)
        let ref=this;
        if(!localStorage.getItem('authorization')){
            ref.props.history.push("/");
        }
        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }
        axios.get(`https://hoteliercoins.co/api/v1/airdrop/details`, header)
        .then( res => {
          //console.log(res)
            if(res.data.status == true){
              //console.log(res,"For Email") 
                      
                this.setState({airDropBalance : res.data.data.details});
                //console.log(this.state.balance.userDepositAddress);
                //console.log(this.state.airDropBalance);               
               
            }
            else if(res.data.message == "jwt expired"){
              //console.log("res.data.message");
              localStorage.clear();
              
              this.props.history.push('/');
          }
            
        })
        .catch(function (error) {
            //console.log(error)
        });
        axios.get(`https://hoteliercoins.co/api/v1/airdrops/all`, header)
        .then( res => {
          //console.log(res)
            if(res.data.status == true){
              //console.log(res,"For Email") 
                      
                this.setState({airDropAll : res.data.data.airdrops});
                //console.log(this.state.balance.userDepositAddress);
                //console.log(this.state.airDropAll);               
               
            }
            else if(res.data.message == "jwt expired"){
              //console.log("res.data.message");
              localStorage.clear();
              
              this.props.history.push('/');
          }
            
        })
        .catch(function (error) {
            //console.log(error)
        })
        $(window).scroll(function(){
            if ($(window).scrollTop() >= 50) {
             // this.alert("hello");
                $('.inner_main').addClass('fixed-header');
                //$('.inner_main div').addClass('visible-title');
            }
            else {
                $('.inner_main').removeClass('fixed-header');
                //$('.token_sale_user_details div').removeClass('visible-title');
            }
        });
    }

    render() {
      
        return (

            <div className="inner_main airdrop_main_i" >
                
               
               <TopBar />
               <div className="user_details_col token_sale_user_details">
                                   
                        <div className="media align-items-center">
                                <span className="short_name mr-3">
                                  <img src={Coin} />
                                </span>
                                <div className="media-body">
                                   <h5 className="mt-0 mb-0"> {localStorage.getItem('userEmail')}</h5> 
                                  {/* {localStorage.getItem('userDepositAddress')} */}
                                  <i className="mdi mdi-content-copy"></i>
                                </div>
                          </div>

                         <div className="htlc_balance_top ">
                          <div className="row airdrop_row">
                          <div className="col-md-4  airdrop_col">
                            <div className="wrapper count-title d-flex">
                             
                              <div className="name"><strong className="text-uppercase">airdrop allocated balance</strong>
                                 <div className="count-number">{this.state.airDropBalance.airdrop_original_balance }</div> 
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="wrapper count-title d-flex">
                              
                              <div className="name"><strong className="text-uppercase">Airdrop allocated balance left </strong>
                              <div className="count-number">{this.state.airDropBalance.airdrop_balance_left }</div> 
                              </div>
                            </div>
                          </div>        
                          <div className="col-md-4">
                            <div className="wrapper count-title d-flex justify-content-between">
                              <div className="text-center">
                              <div className="name"><strong className="text-uppercase">Airdrop Sent </strong></div>
                              <div className="count-number">{this.state.airDropBalance.airdrop_sent }</div> 
                              </div>
                              <div className="text-center">
                              <div className="name"><strong className="text-uppercase">Travoliers Added </strong>
                              <div className="count-number">{this.state.airDropBalance.travoliers_added }</div> 
                              </div>
                             
                              </div>
                            </div>
                          </div>                      
                          </div>
                         </div>
                
           
        </div>
               <div className="page bg-white">  
  
              
                <section className="statistics">
       <div className="container-fluid">
        
         <div className="row ">
               <div className="col-lg-12">
               <div className="input-group search_box_group">
                    <input type="text" name="travolier_id" onChange={this.handleChange} className="form-control" placeholder="Search..."/>
                    <div className="input-group-append">
                    <button onClick={this.onSubmit} className="btn btn-primary" type="button">
                        <i className="fa fa-search"></i>
                    </button>
                    </div>
                </div> 
                 {
                   this.state.showTravolierData ?
                   <div className="member_data mt-4">
                   <table class="table table-striped">
                   <thead>
                     <tr>
                       <th scope="col">Travolier Id</th>
                       <th scope="col">Travolier Email</th>
                       <th scope="col">Travolier Phone</th>
                       <th scope="col">Action</th>
                     </tr>
                   </thead>
                   <tbody>
                     <tr>
                       <th scope="row">
                         {this.state.travolierData.travolier_id}
                       </th>
                       <td>{this.state.travolierData.travolier_email}</td>
                       <td>{this.state.travolierData.travolier_phone}</td>
                       <td>
                         <button onClick={this.onSubmitTravolierAdd} className="btn btn-primary">Add</button>
                       </td>
                     </tr>   
                   </tbody>
                 </table>
                   </div>
                   : null
                 }
                 {
                   this.state.showAllTravolierData ?
                   <div className="member_data mt-4">
                   <table class="table table-striped">
                   <thead>
                     <tr>
                       <th scope="col">Travolier Id</th>
                       <th scope="col">Airdrop Amount</th>
                       <th scope="col">Reference ID</th>    
                       <th scope="col">Date</th>   
                     </tr>
                   </thead>
                   <tbody>
                     {
                      this.state.airDropAll.map(el =><tr>
                        <td>{el.airdrop_sent_to}</td>
                        <td>
                        {el.airdrop_amount}
                        </td>
                        <td>
                        {el.reference_id}
                        </td>
                        <td>
                         {
                         
                          (new Date(el.date)).toLocaleDateString()
                        }
                        </td>
                      </tr>  )
                    }
                    
                   </tbody>
                 </table>
                   </div>
                   :null
                 }
               
            </div>
                     
         </div>
       </div>
     </section>
     </div> 

            </div>
        );
    }
}

export default AirdropDashboard;
