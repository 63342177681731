import React, { Component } from 'react';
import axios from 'axios';
import '../public/css/login-custom.css';
import '../public/css/auth.css';
import Eye from '../public/img/eye.svg';
import Logo from '../public/img/logo_dark.png';
import $ from "jquery";
import { ToastsContainer, ToastsStore } from "react-toasts";
import loader from "../public/img/loader_dots.gif";
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            submitted: false,
            loading: false,
            error: '',
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        //console.log(this.state,"Login DATA")
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        this.setState({ loading: true });

        const headers = { 'Postman-Token': '4b96e9e5-f22a-4a5f-bbea-16baa0eec664',
            'cache-control': 'no-cache',
            'Content-Type': 'application/json' }
        let abcd=this;
        $(".action_loader").show();
         axios.post('https://hoteliercoins.co/api/v1/login', this.state,{headers : headers})
            .then(function (response) {
                //console.log(response,"HELLO")

                if(response.data.status === true) {
                    ToastsStore.success("Login Successful");
                    $(".action_loader").hide();
                    //console.log(response)
                    localStorage.setItem('authorization',"JWT "+response.data.data.token);
                    abcd.props.history.push("/cabinet/tokensale");
                }
                else {
                    $(".action_loader").hide();
                    $("#login-wrong-credentials-error").show();
                }

            })
            .catch(function (error) {
                //console.log(error,'CATCH');

            });
    }

    componentDidMount(){
        let abcd=this;
        if(localStorage.getItem('authorization')){
            abcd.props.history.push("/");
        }
        $(document).ready(function(){
           $("#login-username").focus();
            // setTimeout(function() {
            //     $(".login-page").trigger("click");
            // },10);
           
           

            $('.form-validate').each(function() {  
                $(this).validate({
                    errorElement: "div",
                    errorClass: 'is-invalid',
                    validClass: 'is-valid',
                    ignore: ':hidden:not(.summernote),.note-editable.card-block',
                    errorPlacement: function (error, element) {
                        // Add the `invalid-feedback` class to the error element
                        error.addClass("invalid-feedback");
                        //console.log(element);
                        if (element.prop("type") === "checkbox") {
                            error.insertAfter(element.siblings("label"));
                        } 
                        else {
                            error.insertAfter(element);
                        }
                    }
                });
            });
        })
        var materialInputs = $('input.input-material');

    // activate labels for prefilled values
    materialInputs.filter(function () {
        return $(this).val() !== "";
    }).siblings('.label-material').addClass('active');

    // move label on focus
    materialInputs.on('focus', function () {
        $(this).siblings('.label-material').addClass('active');
    });

    // remove/keep label on blur
    materialInputs.on('blur', function () {
        $(this).siblings('.label-material').removeClass('active');

        if ($(this).val() !== '') {
            $(this).siblings('.label-material').addClass('active');
        } else {
            $(this).siblings('.label-material').removeClass('active');
        }
    });

   
    }
    render() {
        return (
         
            <div id="large-header" className=""> 
                 <div className="page login-page">
      <div className="">
      <div className="vimeo-wrapper vm-wrapper">
                  
                  <iframe src="https://player.vimeo.com/video/355503337?background=1&autoplay=1&loop=1&byline=0&title=0"
                          frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
              </div>
        <div className="form-outer text-center d-flex align-items-center">
          <div className="form-inner">
            <div className="logo text-uppercase">
                <a href="/">
              <img src={Logo}/>  </a>  
            </div>
            {/* <p>Welcome to Hotelier Coin dashboard</p> */}
            <form method="get" className="text-left form-validate" autoComplete="hide" onSubmit={this.handleSubmit}>
              <div className="form-group-material">
                <input id="login-username" type="email"  name="email"  onChange={this.onChange} required data-msg="Please enter a valid email address" className="input-material"/>
                <label for="login-username" className="label-material">Email</label>
              </div>
              <div className="form-group-material">
                <input id="login-password" type="password" name="password" onChange={this.onChange} required data-msg="Please enter your password" className="input-material"/>
                <label for="login-password" className="label-material">Password</label>
              </div>
              <span id="login-wrong-credentials-error" class="is-invalid invalid-feedback" style={{display: "none"}}>Email or password is incorrect</span>
              <div className="form-group text-center">
                  <button id="login" type="submit"  className="btn btn-primary btn-block">
                      <div className="action_loader" style={{display:'none'}}>
                          <img src={loader} />
                      </div>
                      Login
                      </button>               
              </div>
            </form>
            <a href="/forgotpassword" className="forgot-pass">Forgot Password?</a><small>Do not have an account? </small><a href="/register/" className="signup">Signup</a>
          </div>
          
        </div>
      </div>
    </div>

                <div>
                    <ToastsContainer store={ToastsStore} />
                </div>
            </div>
        );
    }
}

export default Login;



