import React, { Component } from 'react';
import '../public/css/account.css';
import '../public/css/account-custom.css';
import './tokensale.css';
import axios from 'axios';



class changePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_password: '',
            new_password: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }
        let abcd=this;
          axios.patch('https://hoteliercoins.co/api/v1/profile/changepassword', this.state, header)
            .then(function (response) {
                if(response.data.status === true) {
                     alert("Password Updated");
                }
            })
            .catch(function (error) {
            });
    }


    render() {
        return (
            <div className="main_block faq main-block-custom changePassword">

                <div className="wrap">
                    <div className="table_wrap" tabIndex="1" style={{overflow: 'hidden', outline: 'none'}}>

                        <form name="fos_user_change_password_form" method="post" onSubmit={this.handleSubmit}
                              className="form-placeholder-custom">
                            <div className="field-wrap field-pass input-edit">
                                <input type="password" id="fos_user_change_password_form_current_password"
                                       name="current_password"
                                       required="required" onChange={this.handleChange} autoComplete="current-password"
                                       placeholder="Current password" className="input-custom input-edit" />

                            </div>
                            <div className="field-wrap field-pass input-edit">
                                <input type="password" id="fos_user_change_password_form_plainPassword_first"
                                       name="new_password"
                                       required="required" onChange={this.handleChange} autoComplete="new-password"
                                       placeholder="New password" className="input-custom input-edit" />
                            </div>
                            <div className="field-wrap field-pass input-edit">
                                <input type="password" id="fos_user_change_password_form_plainPassword_second" name="confirm_new_password" required="required" autoComplete="new-password" placeholder="Confirm password" className="input-custom input-edit" />
                            </div>
                            <button href="#" className="btn" onChange={this.handleChange} style={{ width: '100%', marginTop: '10px', padding: '15px', borderRadius: '5px'}}>Confirm password</button>
                            <input type="hidden" id="fos_user_change_password_form__token" name="fos_user_change_password_form[_token]" value="oBCbsj50aNAyz_aMeyB1ahD_eVoaFpt5WSOYhEFsffA" /></form>

                    </div>
                </div>

            </div>
        );
    }
}

export default changePassword;
