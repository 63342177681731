import React, { Component } from "react";
import $ from "jquery";
import Sidebar from "../cabinet/sidebar";
import TopBar from "./topBar";
import account from "../public/css/account.css";
import "../public/css/account-custom.css";
import "./tokensale.css";
import BCH_img from "../public/img/bch-coin.png";
import ETH_img from "../public/img/eth.png";
import LTC_img from "../public/img/ltc.png";
import BTC_img from "../public/img/btc.png";
import DASH_img from "../public/img/dash.png";
import { Link } from "react-router-dom";
import loader from "../public/img/loader_dots.gif";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Coin from "../public/img/coin_logo.png";
import axios from "axios";
import Forbs from "../public/img/forbs.png";

class TokenSale extends Component {
  img = {
    btc: BTC_img,
    ltc: LTC_img,
    dash: DASH_img,
    eth: ETH_img,
    bch: BCH_img,
  };

  constructor(props) {
    super(props);
    let data = [];

    this.state = {
      value: "",
      copySuccess: "",
      items: [],
      profile: [],
      isLoaded: false,
      balance: [],
      currencyList: ["btc", "eth", "bch", "ltc", "nano", "trx", "dash"],
      currency: "btc",
      currencyRate: "0",
      amount: 0,
      clicked: false,
      msg: false,
      youGet: 0,
      selectedDash: false,
      isFirstPurchased: false,
    };

    this.onSelect = this.onSelect.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.buy = this.buy.bind(this);
  }

  initRef = (c) => (this.copySuccess = c);

  onSelectChange(e) {
    $(".amount_txt").val("");
    $(".htlc_txt").val("");
    //console.log((".htlc_txt").val(0))
    const { name, value } = e.target;
    this.setState({ [name]: value });
    //console.log('name'+name, value)

    if (value == "DASH") {
      this.setState({ selectedDash: true });
      $("#exampleModal").modal("show");
    } else {
      this.setState({ selectedDash: false });
    }

    // if(value == "D")
    this.setState({ youGet: 0 });
    this.setState({ amount: 0 });
    //console.log("end")
  }
  onSelect(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // //console.log('name'+name, value)
    const header = {
      headers: {
        authorization: localStorage.getItem("authorization"),
      },
    };

    axios
      .get(
        "https://hoteliercoins.co/api/v1/rates/" + this.state.currency,
        header
      )
      .then((res) => {
        this.setState({ currencyRate: res.data.rate_usd });
      });

    let { currencyRate, amount } = this.state;
    //console.log(currencyRate,amount)
    const totalPurchaseValue = currencyRate * Number(amount);
    //console.log(totalPurchaseValue)
    var converter = 0;
    if (totalPurchaseValue > 75000) {
      converter = totalPurchaseValue / 1.2 + totalPurchaseValue * 0.35;
    } else if (totalPurchaseValue > 50000 && totalPurchaseValue < 75000) {
      converter = totalPurchaseValue / 1.2 + totalPurchaseValue * 0.25;
    } else if (totalPurchaseValue > 25000 && totalPurchaseValue < 50000) {
      converter = totalPurchaseValue / 1.2 + totalPurchaseValue * 0.2;
    } else if (totalPurchaseValue > 10000 && totalPurchaseValue < 25000) {
      converter = totalPurchaseValue / 1.2 + totalPurchaseValue * 0.15;
    } else {
      //console.log("hola")
      converter = totalPurchaseValue / 1.2 + totalPurchaseValue * 0.1;
    }

    let result = converter;
    this.setState({ youGet: result });
  }
  logout(e) {
    e.preventDefault();
    localStorage.clear();
    this.props.history.push("/");
  }
  loadData() {
    // alert("hello");
    $(".overlay").removeClass("hide");
    const header = {
      headers: {
        authorization: localStorage.getItem("authorization"),
      },
    };
  }

  //Private sale calculator
  saleCalculator() {
    let usdValue = this.state.amount * this.state.currencyRate;
    let tokenGet = (this.state.amount * this.state.currencyRate) / 1.2;
    // if (usdValue >= 75000){
    //     tokenGet = (tokenGet)*1.35;
    //     console.log("35%")
    //     return tokenGet
    // } else if ( usdValue >= 50000){
    //     tokenGet = (tokenGet)*1.25;
    //     console.log("25%")
    //     return tokenGet
    // }
    // else if ( usdValue > 25000){
    //     tokenGet = (tokenGet)* 1.20;
    //     console.log("20%")
    //     return tokenGet
    // }
    // else if ( usdValue > 10000){
    //     tokenGet = (tokenGet)* 1.15;
    //     console.log("15%")
    //     return tokenGet
    // }
    // else {
    //     tokenGet = (tokenGet)* 1.10;
    //     console.log("10%")
    //     return tokenGet
    // }
    // HOT-206 CHANGES
    if (usdValue >= 50000) {
      tokenGet = tokenGet * 1.5;
      console.log("50%");
      return tokenGet;
    } else if (usdValue >= 40000) {
      tokenGet = tokenGet * 1.4;
      console.log("40%");
      return tokenGet;
    } else if (usdValue >= 30000) {
      tokenGet = tokenGet * 1.3;
      console.log("30%");
      return tokenGet;
    } else if (usdValue > 20000) {
      tokenGet = tokenGet * 1.2;
      console.log("20%");
      return tokenGet;
    } else if (usdValue > 15000) {
      tokenGet = tokenGet * 1.15;
      console.log("15%");
      return tokenGet;
    } else {
      tokenGet = tokenGet * 1.1;
      console.log("10%");
      return tokenGet;
    }
  }
  buy() {
    //alert("hello");
    if ($(".htlc_txt").val() < 100) {
      toastr.error("Minimum HTLC purchasing amount is 250");
    } else {
      let { profile } = this.state;
      //console.log(profile)
      const data = {
        title: "HTLC Purchase ",
        user_id: profile.email,
        currency: this.state.currency.toUpperCase(),
        amount: this.state.amount,
        coingate_success: "https://hoteliercoin.io/cabinet/payment-successful",
        coingate_cancel: "https://hoteliercoin.io/cabinet/payment-canceled",
        coingate_callback: "https://hoteliercoins.co/api/v1/coingate-callback",
      };

      const header = {
        headers: {
          authorization: localStorage.getItem("authorization"),
          "Content-Type": "application/json",
        },
      };

      if ((this.state.amount > 0) & (this.state.currency != null)) {
        this.setState({ clicked: true });
      } else {
        this.setState({ msg: true });
      }
      if (profile.isPhoneVerified) {
        $(".action_loader").show();
        axios
          .post("https://hoteliercoins.co/api/v1/payment-htlc", data)
          .then((res) => {
            if ((this.state.amount > 0) & (this.state.currency != null)) {
              //console.log(res)
              window.open(res.data.data.payment_url).focus();
              //path.history.push('/cabinet/makepayment')
              $(".action_loader").hide();
              this.setState({ clicked: true });
            } else {
              //console.log("error ui" + res.data.data.message)
              $(".action_loader").hide();
              this.setState({ msg: true });
              toastr.error(res.data.message);
            }
          })
          .catch(function (error) {
            //console.log(error);
            //path.history.push('https://hoteliercoin.io/cabinet/payment-canceled')
          });
      } else {
        toastr.info("You need to verify your phone number to continue");
      }
    }
  }

  componentDidMount() {
    //alert('hello')
    setRandomClass();
    setInterval(function () {
      setRandomClass();
    }, 2000);

    function setRandomClass() {
      var col = $(".htlc_balance_top .col-md-4");
      var number = col.length;
      var random = Math.floor(Math.random() * number);
      col.removeClass("special");
      col.eq(random).addClass("special");
    }
    let abcd = this;
    if (!localStorage.getItem("authorization")) {
      abcd.props.history.push("/login");
    }
    const header = {
      headers: {
        authorization: localStorage.getItem("authorization"),
      },
    };

    //console.log("hello")
    //console.log("auth"+ localStorage.getItem('authorization'));

    axios
      .get(`https://hoteliercoins.co/api/v1/userInfo/balance`, header)
      .then((res) => {
        if (res.data.status == true) {
          //console.log(res,"For Email")
          $(".overlay").addClass("hide");
          this.setState({ balance: res.data.data[0] });
          //console.log(this.state.balance.userDepositAddress);
          //console.log(this.state.balance);
          localStorage.setItem(
            "userDepositAddress",
            this.state.balance.userDepositAddress
          );
          localStorage.setItem("userEmail", this.state.balance.userId);
        } else if (res.data.message == "jwt expired") {
          //console.log("res.data.message");
          localStorage.clear();

          this.props.history.push("/login");
        }
      })
      .catch(function (error) {
        //console.log(error)
      });

    axios
      .get("https://hoteliercoins.co/api/v1/userInfo/profile", {
        headers: header.headers,
      })
      .then((res) => {
        console.log(res);
        this.setState({ profile: res.data.data });

        this.setState({
          isFirstPurchased: this.state.profile.isFirstDashPurchase,
        });
      })
      .catch(function (error) {
        //console.log(error);
      });

    axios
      .get(
        "https://hoteliercoins.co/api/v1/rates/" + this.state.currency,
        header
      )
      .then((res) => {
        this.setState({ currencyRate: res.data.rate_usd });
      });

    $(window).scroll(function () {
      if ($(window).scrollTop() >= 50) {
        // this.alert("hello");
        $(".inner_main").addClass("fixed-header");
        //$('.inner_main div').addClass('visible-title');
      } else {
        $(".inner_main").removeClass("fixed-header");
        //$('.token_sale_user_details div').removeClass('visible-title');
      }
    });
  }
  render() {
    let { balance, youGet } = this.state;

    return (
      <div className="inner_main">
        <TopBar />
        <div className="user_details_col token_sale_user_details fixedsticky">
          <div className="media">
            <span className="short_name mr-3">
              <img src={Coin} />
            </span>
            <div className="media-body">
              <h5 className="mt-0 mb-0">{balance.userId}</h5>
              {balance.userDepositAddress}
              <i className="mdi mdi-content-copy"></i>
            </div>
          </div>

          <div className="htlc_balance_top">
            <div className="row">
              <div class="col-md-4">
                <div class="wrapper count-title d-flex">
                  <div class="name">
                    <strong class="text-uppercase">Main Balance</strong>
                    <div class="count-number">
                      {balance.userBalance && balance.userBalance > 0
                        ? balance.userBalance.toFixed(2)
                        : 0}{" "}
                      HTLC (~{" "}
                      {balance.userBalance && balance.userBalance
                        ? (balance.userBalance * 1.2).toFixed(2)
                        : 0}{" "}
                      USD)
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="wrapper count-title d-flex">
                  <div class="name">
                    <strong class="text-uppercase">Escrow Balance</strong>
                    <div class="count-number">
                      {balance.userBalanceEscrow &&
                      balance.userBalanceEscrow > 0
                        ? balance.userBalanceEscrow.toFixed(2)
                        : 0}{" "}
                      HTLC (~{" "}
                      {balance.userBalanceEscrow && balance.userBalanceEscrow
                        ? (balance.userBalanceEscrow * 1.2).toFixed(2)
                        : 0}{" "}
                      USD)
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="wrapper count-title d-flex">
                  <div class="name">
                    <strong class="text-uppercase">Locked Balance</strong>
                    <div class="count-number">
                      {balance.lockedBalance && balance.lockedBalance > 0
                        ? balance.lockedBalance.toFixed(2)
                        : 0}{" "}
                      HTLC (~{" "}
                      {balance.lockedBalance && balance.lockedBalance
                        ? (balance.lockedBalance * 1.2).toFixed(2)
                        : 0}{" "}
                      USD)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page">
          <section className="statistics">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="vimeo-wrapper token_sale_wrapper">
                    <iframe
                      src="https://player.vimeo.com/video/356599089?background=1&autoplay=1&loop=1&byline=0&title=0"
                      frameborder="0"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen
                    ></iframe>
                    <div className="video_content_banner">
                      <h5>
                        Upto 40% Discount and zero processing fee for payments
                        by HTLC Book Hotels, Villas, Flights, Experiences and
                        more.
                      </h5>
                      <a
                        className="btn btn-primary book_now_token_sale"
                        href="https://travolier.com/"
                        target="_blank"
                      >
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-5">
                  <div className="card data-usage has-left-border">
                    <div className="card-header align-items-center d-none">
                      <h4>HotelierCoin</h4>
                    </div>

                    <div className="card-body d-none">
                      <div className="row d-flex align-items-center">
                        <div className="col-sm-12">
                          <ul class="list-group list-group-flush token_balance_list">
                            <li class="list-group-item">
                              Main Balance:{" "}
                              {balance.userBalance && balance.userBalance > 0
                                ? balance.userBalance.toFixed(2)
                                : 0}{" "}
                              HTLC (~{" "}
                              {balance.userBalance && balance.userBalance
                                ? (balance.userBalance * 1.2).toFixed(2)
                                : 0}{" "}
                              USD)
                            </li>
                            <li class="list-group-item">
                              Escrow Balance:{" "}
                              {balance.userBalanceEscrow &&
                              balance.userBalanceEscrow > 0
                                ? balance.userBalanceEscrow.toFixed(2)
                                : 0}{" "}
                              HTLC (~{" "}
                              {balance.userBalanceEscrow &&
                              balance.userBalanceEscrow
                                ? (balance.userBalanceEscrow * 1.2).toFixed(2)
                                : 0}{" "}
                              USD)
                            </li>
                            <li class="list-group-item">
                              Locked Balance:{" "}
                              {balance.lockedBalance &&
                              balance.lockedBalance > 0
                                ? balance.lockedBalance.toFixed(2)
                                : 0}{" "}
                              HTLC (~{" "}
                              {balance.lockedBalance && balance.lockedBalance
                                ? (balance.lockedBalance * 1.2).toFixed(2)
                                : 0}{" "}
                              USD)
                            </li>
                          </ul>

                          <p className="mt-5">
                            Portfolio Value (~USD){" "}
                            {balance.userBalance && balance.userBalance
                              ? (
                                  balance.userBalance +
                                  balance.userBalanceEscrow +
                                  balance.lockedBalance * 1.2
                                ).toFixed(2)
                              : 0}{" "}
                            USD
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card-header d-flex align-items-center">
                      <h4>Launch sale is active</h4>
                    </div>
                    <div className="card-body">
                      <div className="row d-flex align-items-center">
                        <div className="col-sm-12">
                          <h4>Normal Rates: 1 HTLC = 1.2 USD</h4>
                          <h4>Special Rates in Launch Sale:</h4>
                        </div>
                      </div>
                      <p>Purchase above $100,000: 12% extra HTLC tokens</p>
                      <p>
                        Purchase above $50,000 - $100,000: 10% extra HTLC tokens
                      </p>
                      <p>
                        Purchase above $5,000 - $50,000: 7% extra HTLC tokens
                      </p>
                      <p>Purchase $1,000 - $5,000: 5% extra HTLC tokens</p>

                      <p>*Extra tokens will be credited as locked tokens</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="card">
                    <div className="card-header d-flex align-items-center">
                      <h4>Choose currency and calculate HTLC token price</h4>
                    </div>
                    <div className="card-body">
                      <p>Minimum purchase is 100 HTLC.</p>
                      <p>
                        Purchase limits : ~10 BTC equivalent or lesser for
                        BTC,LTC,BCH,DASH and ETH for any order.{" "}
                      </p>

                      <form>
                        <div
                          className="form-group mt-4"
                          onChange={this.onSelect}
                        >
                          <select
                            className="custom-select"
                            name="currency"
                            onChange={this.onSelectChange}
                          >
                            <option value="BTC">Bitcoin (BTC)</option>
                            <option value="ETH">Ethereum (ETH)</option>
                            <option value="BCH">Bitcoin Cash (BCH)</option>
                            <option value="LTC">Litecoin (LTC)</option>
                            <option value="DASH">Dash Coin (DASH)</option>
                          </select>
                        </div>
                        <div
                          className="form-group position-relative"
                          onChange={this.onSelect}
                        >
                          <input
                            type="text"
                            name="amount"
                            autoComplete="off"
                            required="required"
                            placeholder="Enter Amount"
                            onKeyUp={this.onSelect}
                            className="form-control amount_txt"
                          />
                          <span className={"inputiconsl"}>
                            {this.state.currency}
                          </span>
                        </div>

                        <div className="form-group position-relative">
                          <input
                            type="text"
                            placeholder="HTLC"
                            value={this.saleCalculator()}
                            className="form-control htlc_txt"
                          />

                          <span className={"inputiconsl"}>HTLC</span>
                          {this.state.selectedDash ? (
                            <span className="bonus_span">+ 1% HTLC Bonus</span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <button
                            type="button"
                            onClick={this.buy}
                            className="btn btn-primary btn-block"
                          >
                            <div
                              className="action_loader"
                              style={{ display: "none" }}
                            >
                              <img src={loader} />
                            </div>
                            Buy HotelierCoin
                          </button>
                        </div>
                      </form>
                      <p className="mt-3">
                        * The actual HTLC amount might differ from what this
                        calculator displays and it will be based on exchange
                        rate that coingate provides.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="card">
                    <div
                      id="media-quote"
                      className="media-quote-div token_sale_quote"
                    >
                      <a href="#">
                        <img
                          src="https://uploads-ssl.webflow.com/5c0ebe0bc3abf564c79e6c17/5c0ebe0bc3abf516759e6d25_quotation-mark.svg"
                          width="50"
                          alt="Light quote icon"
                          className="speech-icon"
                        />
                        <div className="quote-text">
                          Could The Hotelier Coin Be The Answer To Revamping The
                          Hospitality Industry?
                        </div>
                        <img
                          src={Forbs}
                          height="35"
                          alt="Forbs logo"
                          className="media-logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-12 col-md-12">
                  <div id="new-updates" className="card updates recent-updated">
                    <div
                      id="updates-header"
                      className="card-header d-flex justify-content-between align-items-center"
                    >
                      <h2 className="h5 display">News Updates</h2>
                    </div>
                    <div id="updates-box" role="tabpanel">
                      <ul className="news list-unstyled">
                        <li className="d-flex justify-content-between">
                          <div className="left-col d-flex">
                            {/* <div className="icon"><i className="icon-rss-feed"></i></div> */}
                            <div className="">
                              <p>
                                We are thrilled to announce our new Director of
                                Blockchain Partnerships, Felix Mago. Felix is
                                the Co-Founder of Dash Next & Dash Thailand{" "}
                              </p>
                              <a href="http://bit.ly/felixhtlc" target="_blank">
                                http://bit.ly/felixhtlc
                              </a>
                            </div>
                          </div>
                          {/* <div className="right-col text-right">
                                      <div className="update-date">24<span className="month">Jan</span></div>
                                    </div> */}
                        </li>

                        <li className="d-flex justify-content-between">
                          <div className="left-col d-flex">
                            {/* <div className="icon"><i className="mdi mdi-feed"></i></div> */}
                            <div className="">
                              <p>
                                We are excited to announce that Hotelier Coin
                                has been shortlisted for the Travolution Awards
                                for Best Technological Innovation. Join us on
                                the 14th of November at the Hilton London
                                Bankside
                              </p>
                              <a
                                href="http://bit.ly/htlctravolutionary"
                                target="_blank"
                              >
                                http://bit.ly/htlctravolutionary
                              </a>
                            </div>
                          </div>
                          {/* <div className="right-col text-right">
                                      <div className="update-date">24<span className="month">Jan</span></div>
                                    </div> */}
                        </li>
                        <li className="d-flex justify-content-between">
                          <div className="left-col d-flex">
                            {/* <div className="icon"><i className="mdi mdi-feed"></i></div> */}
                            <div className="">
                              {/* <strong>Lorem ipsum dolor sit amet.</strong> */}
                              <p>
                                Here's Vipin Mahasayan and Rana Mukherji
                                presenting at one of the best technology
                                conferences in the world—the Rise Conference in
                                Hong Kong. We had a great time and would like to
                                thank the Rise Conference and hashtag#HongKong
                                for having us.{" "}
                              </p>
                              <a
                                href="http://bit.ly/vipranahtlc"
                                target="_blank"
                              >
                                http://bit.ly/vipranahtlc
                              </a>
                            </div>
                          </div>
                          {/* <div className="right-col text-right">
                                      <div className="update-date">24<span className="month">Jan</span></div>
                                    </div> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Notice
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Choosing this option also makes you eligible for a flat 1% HTLC
                extra on your purchase. The extra HTLC will be added in your
                main balance and can be used right away. Thanks for choosing us!
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TokenSale;
