import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Brand1 from './public/landing/img/brands/hotelier_dark.png';
import Brand2 from './public/landing/img/brands/searchstays_dark.png';
import Brand3 from './public/landing/img/brands/travolier_balck.png';
import California from './public/landing/img/network-logos/california.png';
import Europestays from './public/landing/img/network-logos/europestays.png';
import Fishing from './public/landing/img/network-logos/fishing.png';
import Florida from './public/landing/img/network-logos/florida.png';
import Golfing from './public/landing/img/network-logos/golfing.png';
import Illionis from './public/landing/img/network-logos/illionis.png';
import Longterm from './public/landing/img/network-logos/longterm.png';
import Magnificent from './public/landing/img/network-logos/magnificent.png';
import Nchomerentals from './public/landing/img/network-logos/nchomerentals.png';
import Nyhome from './public/landing/img/network-logos/nyhome.png';
import Petfriendly from './public/landing/img/network-logos/petfriendly.png';
import Skihomestays from './public/landing/img/network-logos/skihomestays.png';
import Vria from './public/landing/img/network-logos/vria.png';
import Canadastays from './public/landing/img/network-logos/canadastays.png';
import $ from "jquery";



class OurBrands extends React.Component {

    componentDidMount() {
        $(function() {
            $('.our-brands').slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                arrows: false,
                dots: false,
               
                pauseOnHover: false,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                }, {
                    breakpoint: 520,
                    settings: {
                        slidesToShow: 2
                    }
                }]
            });
        });
    }
    responsive = {
        0: { items: 1 },
        600: { items: 2 },
        960: { items: 3 }
    }

    stagePadding = {
        paddingLeft: 30,
        paddingRight: 30,
    }
    

    render() {

        return (
            <section>
                <div className="content" style={{paddingTop: '0px'}}>
                    <h2 className="section_header section_header_line" style={{marginTop: '0px', textAlign: 'left'}}>Our brands</h2>

                    {/* <AliceCarousel duration={1600} autoPlay={true}  buttonsDisabled={true} mouseDragEnabled={true} onSlideChanged={console.debug} infinite={true} dotsDisabled={true} showSlideInfo={false} responsive={this.responsive} stagePadding={this.stagePadding}>
                    <div className="item">
                        <div className="member-wrap">
                            <a href="https://www.hoteliermart.com" target="_blank">
                                <div className="member" style={{backgroundColor: '#fff'}}>
                                    <div className="">
                                        <img src={Brand1} alt="Hoteliermart" style={{marginTop:'15px'}} className={'img-responsive'} />
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item">
                            <div className="member-wrap">
                                <a href="https://searchstays.com/" target="_blank">
                                    <div className="member" style={{backgroundColor: '#fff'}}>
                                        <div className="">
                                            <img src={Brand2} alt="Searchstays" style={{marginTop:'10px', width:'280px'}} className={'img-responsive'} />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item">
                            <div className="member-wrap">
                                <a href="#" target="_blank">
                                    <div className="member" style={{backgroundColor: '#fff'}}>
                                        <div className="">
                                            <img src={Brand3} alt="Travolier" style={{marginTop:'8px', width:'205px'}} className={'img-responsive'} />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </AliceCarousel> */}
                 <section className="our-brands slider">
            <div className="slide">
                <a href="https://www.hoteliermart.com/" target="_blank"><img src={Brand1} /></a>
                </div>
            <div className="slide">
            <a href="https://SearchStays.com" target="_blank"><img src={Brand2} /></a>
            </div>
            <div className="slide">
            <a href="https://hoteliercoin.io/travolierclub" target="_blank"><img className="travolier_logo_brand" src={Brand3} /></a>
            </div>
            <div className="slide">
            <a href="https://GoCaliforniaStays.com" target="_blank"><img src={California} /></a>
            </div>
            <div className="slide">
            <a href="http://europehomestays.com/" target="_blank"><img src={Europestays} /></a>
            </div>
            <div className="slide">
            <a href="https://FishingStays.com" target="_blank"><img src={Fishing} /></a>
            </div>
            <div className="slide">
            <a href="https://GoFloridaStays.com" target="_blank"><img src={Florida} /></a>
            </div>
            <div className="slide">
            <a href="https://GolfingStays.com" target="_blank"><img src={Golfing} /></a>

            </div>
             <div className="slide">
            <a href="https://GoCanadaStays.com" target="_blank"><img src={Canadastays} /></a>
            </div> 
            <div className="slide">
            <a href="https://GoExtendedStays.com" target="_blank"><img src={Longterm} /></a>
            </div>
            <div className="slide">
            <a href="https://MagnificentStays.com" target="_blank"><img src={Magnificent} /></a>
            </div>
            {/* <div className="slide">
            <a href="https://" target="_blank"><img src={Nchomerentals} /></a>
            </div> */}
            <div className="slide">
            <a href="https://GoNewYorkStays.com" target="_blank"><img src={Nyhome} /></a>
            </div>
            <div className="slide">
            <a href="https://GoPetFriendlyStays.com" target="_blank"><img src={Petfriendly} /></a>
            </div>
            <div className="slide">
            <a href="https://SkiHomestays.com" target="_blank"><img src={Skihomestays} /></a>
            </div>
            {/* <div className="slide">
            <a href="https://" target="_blank"><img src={Vria} /></a></div> */}
            {/* <div className="slide">
            <a href="https://" target="_blank"><img src={Washington} /></a>
            </div> */}
            </section>
                </div>
            </section>
        )
    }
}

export default OurBrands;

