import React, { Component } from 'react';
import Sidebar from "../cabinet/sidebar";
import TopBar from "./topBar"
import '../public/css/account.css';
import '../public/css/account-custom.css';
import './tokensale.css';
import axios from 'axios';
import Coin from "../public/img/coin_logo.png";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import $ from "jquery";
class History extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableData: [],
        };
    }

    componentDidMount () {
        let ref=this;
        if(!localStorage.getItem('authorization')){
            ref.props.history.push("/");
        }
        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }

        axios.get('https://hoteliercoins.co/api/v1/userInfo/history', header).then(res => {
            //console.log(res);
            if(res.data.status){
                ref.setState({ tableData: res.data.data.data });
                $('.datatable').DataTable();
            }
            else if(res.data.message == "jwt expired"){
                localStorage.clear();
                this.props.history.push('/');
            }
            else{
                toastr.error(res.data.message)
            }
               
                
        });
    }

    render() {

        return (

            <div className="inner_main history_main_i">
                
               
               <TopBar />
               <div className="user_details_col token_sale_user_details">
                                   
                        <div className="media">
                                <span className="short_name mr-3">
                                  <img src={Coin} />
                                </span>
                                <div className="media-body">
                                   <h5 className="mt-0 mb-0">{localStorage.getItem('userEmail')}</h5> 
                                  {localStorage.getItem('userDepositAddress')}
                                  <i className="mdi mdi-content-copy"></i>
                                </div>
                          </div>

                         <div className="htlc_balance_top d-none">
                          <div className="row">
                          <div class="col-md-4">
                            <div class="wrapper count-title d-flex">
                             
                              <div class="name"><strong class="text-uppercase">Main Balance</strong>
                                {/* <div class="count-number">{ balance.userBalance && balance.userBalance  > 0 ? (balance.userBalance).toFixed(2)  : 0 } HTLC (~ { balance.userBalance && balance.userBalance ? (balance.userBalance*1.2).toFixed(2) : 0 } USD)</div> */}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="wrapper count-title d-flex">
                              
                              <div class="name"><strong class="text-uppercase">Escrow Balance</strong>
                              {/* <div class="count-number">{ balance.userBalanceEscrow && balance.userBalanceEscrow  > 0 ? (balance.userBalanceEscrow).toFixed(2)  : 0 } HTLC (~ { balance.userBalanceEscrow && balance.userBalanceEscrow ? (balance.userBalanceEscrow*1.2).toFixed(2) : 0 } USD)</div> */}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="wrapper count-title d-flex">
                             
                              <div class="name"><strong class="text-uppercase">Locked Balance</strong>
                                {/* <div class="count-number">{ balance.lockedBalance && balance.lockedBalance  > 0 ? (balance.lockedBalance).toFixed(2)  : 0 } HTLC (~ { balance.lockedBalance && balance.lockedBalance ? (balance.lockedBalance*1.2).toFixed(2) : 0 } USD)</div> */}
                              </div>
                            </div>
                          </div>
                          </div>
                         </div>
                
           
        </div>
               <div className="page bg-white">  
  
              
                <section className="statistics">
       <div className="container-fluid">
        
         <div className="row ">
               <div className="col-lg-12">
                 <div className="table-responsive-sm">
                 <table className="table table-striped table-bordered datatable">
               <thead>
                                                <tr>
                                                            <th className="content-font-color-custom">Payment ID</th>
                                                            <th className="content-font-color-custom">Payment Type</th>
                                                            <th className="content-font-color-custom">Payment Amount</th>
                                                            <th className="content-font-color-custom">Timestamp</th>
                                                            <th className="content-font-color-custom">Payment Mode</th>
                                                            <th className="content-font-color-custom">Payment Currency</th>
                                                        </tr>
                                             </thead>
                                            {
                                                this.state.tableData.length ?
                                              
                                                    <tbody>
                                                       
                                                        {
                                                            this.state.tableData.map((item) => {
                                                                return <tr>
                                                                    <td> {item.paymentId} </td>
                                                                    <td> {item.type_of_payment} </td>
                                                                    <td> HTLC {item.payment_amount} </td>
                                                                    <td> {item.timestamp} </td>
                                                                    <td> {item.payment_mode} </td>
                                                                    <td> {item.payment_currency} </td>
                                                                </tr>
                                                            })

                                                        }

                                                    </tbody>

                                                    :

                                                    <tfoot>
                                                      <tr>
                                                        <td colSpan="6">
                                                        <div id="table_error_message_start"
                                                         className="error_message error-message-custom"
                                                         style={{display:'flex', marginTop:'30px'}}>
                                                        <p>No transactions</p>
                                                    </div>
                                                        </td>
                                                      </tr>
                                                    </tfoot>
                                                   
                                            }
                                        </table>  
                 </div>
               
                     
            </div>
                     
         </div>
       </div>
     </section>
     </div> 

            </div>
        );
    }
}

export default History;
