import React, { Component } from 'react';
import axios from 'axios';
import '../public/css/login-custom.css';
import '../public/css/auth.css';
import Eye from '../public/img/eye.svg';
import Logo from '../public/img/hotelier_logo.svg';
import { ToastsContainer, ToastsStore } from "react-toasts";
import {Link} from "react-router-dom";



class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: null,
            cpassword: null,
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        //console.log(this.state)
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        this.setState({ loading: true });

        //console.log(this.state);
        axios.post('https://hoteliercoins.co/api/v1/forgotpasswordsuccess', this.state)
            .then(function (response) {
                //console.log(response);

                ToastsStore.info(response.data.message);


            })
            .catch(function (error) {
                //console.log(error,'erroer');

            });
    }

    componentDidMount(){
        let abcd=this;
        if(localStorage.getItem('token')){
            abcd.props.history.push("/");
        }
    }
    render() {
        return (
            <div id="large-header" className="large-header login-background-custom">
                <div className="wrap">
                    <div className="logo"><a href="/"><img src={Logo} alt="logo" /></a></div>
                    <div className="form main-block-custom-login">
                        <div className="form_content">
                            <h1 className="login-title-color-custom">Welcome to Hotelier Coin dashboard</h1>
                            <p className="welcome_message content-font-color-custom-login">Please sign in to get access</p>
                            <form className="form-placeholder-custom" onSubmit={this.handleSubmit}>
                                <div className="field-wrap">
                                    <input className="input-custom" type="text" id="username" name="code" required="required" placeholder="Enter Code" value={this.state.code}  onChange={this.onChange} />
                                </div>
                                <div className="field-wrap field-pass">
                                    <input className="input-custom" type="password" id="password" name="password" required="required" autoComplete="current-password" placeholder="Enter New Password" onChange={this.onChange} />
                                    <div className="show_password show-password-custom">
                                        <img src={Eye} alt="eye" />
                                    </div>
                                </div>
                                <div className="field-wrap field-pass">
                                    <input className="input-custom" type="password" id="password" name="cpassword" required="required" autoComplete="current-password" placeholder="Confirm Password"  value={this.state.cpassword} onChange={this.onChange} />
                                    <div className="show_password show-password-custom">
                                        <img src={Eye} alt="eye" />
                                    </div>
                                </div>
                                <button className="button button-block button-custom-l" style={{width:'300px'}}>Change Password<span
                                    className="circle circle-custom-l"><span className="arrow arrow-custom-l"></span></span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <div>
                    <ToastsContainer store={ToastsStore} />
                </div>
            </div>
        );
    }
}

export default Reset;



