import React, { Component } from "react";
import axios from "axios";

import Logo from "../public/img/logo_dark.png";
import Eye from "../public/img/eye.svg";
// import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-google';
import { ToastsContainer, ToastsStore } from "react-toasts";
import codes from "../codes.json";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import $ from "jquery";
import loader from "../public/img/loader_dots.gif";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ReCAPTCHA from "react-google-recaptcha";

const initialState = {
  name: "",
  password: "",
  cPassword: "",
  phone: "",
  countryCode: "",
  email: "",
  nameErr: "",
  emailErr: "",
  passwordErr: "",
  cpasswordErr: "",
  captcha: "",
  isRefrralCodeVisible: false,
};
// function onChange(value) {

//         this.setState({captcha:value});
//         //console.log("Captcha value:", this.state.captcha);
//       }
class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showRefrralCode = this.showRefrralCode.bind(this);
  }

  showRefrralCode() {
    this.setState({ isRefrralCodeVisible: true });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    //console.log(this.state)
  }

  validate = () => {
    let emailErr = "";
    let nameErr = "";
    //let emailErr='';
    let passwordErr = "";
    let cpasswordErr = "";

    if (!this.state.name) {
      nameErr = "Name can not be blank";
      $("#register-username").addClass("is-invalid");
    } else {
      $("#register-username").removeClass("is-invalid");
    }
    if (!this.state.email.includes("@")) {
      emailErr = "Invalid email";
      $("#register-email").addClass("is-invalid");
    } else {
      $("#register-email").removeClass("is-invalid");
    }
    if (!this.state.password) {
      passwordErr = "Password can not be blank";
      $("#register-password").addClass("is-invalid");
    } else {
      $("#register-password").removeClass("is-invalid");
    }
    if (this.state.cPassword != this.state.password) {
      cpasswordErr = "Password and Confirm Password did not Match";
      $("#register-cpassword").addClass("is-invalid");
    } else {
      $("#register-cpassword").removeClass("is-invalid");
    }

    if (nameErr || emailErr || passwordErr || cpasswordErr) {
      this.setState({ emailErr, nameErr, passwordErr, cpasswordErr });
      return false;
    }
    if (
      !this.state.reCaptchaResponse ||
      this.state.reCaptchaResponse.trim().length === 0
    ) {
      return { success: false, message: "Captcha is required." };
    }
    return true;
  };
  handleSubmit(e) {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      //console.log("valid email")
      const { password, cPassword } = this.state;

      // perform all neccassary validations
      if (password !== cPassword) {
        toastr.error("Password and Confirm Password did not Match");
        return;
      }
      this.setState({ submitted: true });
      this.setState({ loading: true });
      let abcd = this;
      $(".action_loader").show();
      axios
        .post("https://hoteliercoins.co/api/v1/register", this.state)
        .then((response) => {
          if (response.data.status) {
            //console.log(response, "IF")
            this.setState({ tempid: response.data.data.tempid });
            localStorage.setItem(
              "authorization",
              "JWT " + response.data.data.token
            );
            localStorage.setItem("request_id", response.data.data.request_id);

            toastr.success("OTP Successfully Sent");
            $(".action_loader").hide();
            this.setState(initialState);
            this.props.history.push("/verify-mobile");
          } else {
            $(".action_loader").hide();
            //console.log(response, "ELSE")
            toastr.info(response.data.message);
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  }
  sendCountryCode(code) {
    this.setState({ countryCode: code });
  }

  componentDidMount() {
    let abcd = this;
    if (localStorage.getItem("authorization")) {
      abcd.props.history.push("/");
    }

    // loadReCaptcha();

    var materialInputs = $("input.input-material");

    // activate labels for prefilled values
    materialInputs
      .filter(function () {
        return $(this).val() !== "";
      })
      .siblings(".label-material")
      .addClass("active");

    // move label on focus
    materialInputs.on("focus", function () {
      $(this).siblings(".label-material").addClass("active");
    });

    // remove/keep label on blur
    materialInputs.on("blur", function () {
      $(this).siblings(".label-material").removeClass("active");

      if ($(this).val() !== "") {
        $(this).siblings(".label-material").addClass("active");
      } else {
        $(this).siblings(".label-material").removeClass("active");
      }
    });
  }

  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    //console.log("captcha",recaptchaToken)
    this.setState({ captcha: recaptchaToken });
  }

  render() {
    //console.log(this.state)
    return (
      <>
        <form
          name="fos_user_registration_form"
          method="post"
          onSubmit={this.handleSubmit}
          className="text-left form-validate"
        >
          <div className="form-group-material">
            <input
              id="register-username"
              type="text"
              onChange={this.handleChange}
              name="name"
              maxLength="180"
              pattern=".{2,}"
              data-msg="Please enter your name"
              className="input-material"
            />
            <label for="register-username" className="label-material">
              Name
            </label>

            <div class="invalid-feedback">{this.state.nameErr}</div>
          </div>
          <div className="form-group-material">
            <input
              id="register-email"
              type="email"
              onChange={this.handleChange}
              name="email"
              required
              data-msg="Please enter a valid email address"
              className="input-material"
            />
            <label for="register-email" className="label-material">
              Email Address
            </label>
            <div class="invalid-feedback">{this.state.emailErr}</div>
          </div>
          <div className="form-group-material">
            <input
              id="register-password"
              type="password"
              onChange={this.handleChange}
              name="password"
              required
              autoComplete="new-password"
              data-msg="Please enter your password"
              className="input-material"
            />
            <label for="register-password" className="label-material">
              Password
            </label>
            <div class="invalid-feedback">{this.state.passwordErr}</div>
          </div>
          <div className="form-group-material">
            <input
              id="register-cpassword"
              type="password"
              onChange={this.handleChange}
              name="cPassword"
              required
              autoComplete="new-password"
              data-msg="Please enter your confirm password"
              className="input-material"
            />
            <label for="register-cpassword" className="label-material">
              Confirm Password
            </label>
            <div class="invalid-feedback">{this.state.cpasswordErr}</div>
          </div>
          <label for="register-mobile" className="label-material">
            Mobile
          </label>
          <div className="form-group">
            <IntlTelInput
              inputClassName="form-control"
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                //console.log('onPhoneNumberChange number', value);
                this.setState({
                  countryCode: countryData.dialCode,
                  phone: value,
                });
              }}
            />
            {/* <input id="register-mobile" type="text" placeholder="Enter mobile number" onChange={this.handleChange} name="phone" required className="form-control"  /> */}
          </div>
          <div className="form-group">
            <ReCAPTCHA
              sitekey="6Lcn-rMUAAAAAN61FCzTcXmPJYiNiL8SJDgI5GOp"
              onChange={(value) => {
                this.setState({ captcha: value });
              }}
            />
            {/* <ReCaptcha ref={(el) => {this.captchaDemo = el;}}  size="normal" render="explicit" sitekey="6Lcn-rMUAAAAAN61FCzTcXmPJYiNiL8SJDgI5GOp" onloadCallback={this.onLoadRecaptcha} verifyCallback={this.verifyCallback} /> */}
          </div>
          <div className="form-group">
            {this.state.isRefrralCodeVisible ? (
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                name="referral_code"
                placeholder="Enter referral code"
              />
            ) : (
              <h5>
                <a href="javascript:void(0)" onClick={this.showRefrralCode}>
                  Have Referral Code?
                </a>
              </h5>
            )}
          </div>
          <div className="form-group terms-conditions text-center mt-0">
            <label for="register-agree">
              By continuing, you confirm that you are over 18 years old, and you
              agree to our Terms of Usage{" "}
              <a href="https://hoteliercoin.io/terms">terms and conditions</a>
            </label>
          </div>
          <div className="form-group text-center mb-0">
            <input
              type="hidden"
              id="fos_user_registration_form__token"
              name="fos_user_registration_form[_token]"
              value="xLZG-4xBJgi3-EaWbhXmozx3hRglNIekX-P-dHq_-GA"
            />
            <button
              id="register"
              type="submit"
              className="btn btn-primary btn-block mb-0"
              onClick={this.handleSubmit}
            >
              <div className="action_loader" style={{ display: "none" }}>
                <img src={loader} />
              </div>
              Register
            </button>
          </div>
        </form>
        <small>Already have an account? </small>
        <a href="/login" className="signup">
          Login
        </a>
      </>
    );
  }
}

export default RegisterForm;
