import React, { Component } from "react"
import Logo1 from "../public/img/hoteliercoin-logo.png"
import Logo2 from "../public/img/travolior_logo.png"
import $ from "jquery"

class Navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tableData: localStorage.getItem("authorization"),
    }
  }

  componentDidMount() {
    $(".toggle_menu").mouseup(function () {
      $(this).toggleClass("on")
      if ($(window).width() <= 550) {
        if ($(".mobile_menu").hasClass("visible")) {
          $(".mobile_menu").hide()
        } else {
          $(".mobile_menu").show()
        }
        $(".mobile_menu").toggleClass("visible")
      } else {
        if ($(".topline_bg nav").hasClass("visible")) {
          $(".topline_bg nav").hide()
        } else {
          $(".topline_bg nav").show()
        }
        $(".topline_bg nav").toggleClass("visible")
      }
    })
  }

  render() {
    return (
      <>
        <div className="topline_wrap">
          <div className="container">
            <div className="topline topline_bg">
              <div className="header_logo">
                <div className="logo">
                  <a href="/">
                    <img src={Logo1} alt="Hotelier Logo" />{" "}
                  </a>
                  <a href="https://www.travolier.com/">
                    <img src={Logo2} alt="Hotelier Logo" className="logo_2" />
                  </a>
                </div>
              </div>

              <nav>
                <ul>
                  <li>
                    <a href="/how-it-work">HTLC Token</a>
                  </li>
                  {/* <li>
                  <a href="/#platform">Ecosystem</a>
                </li> */}
                  <li>
                    <a href="/team">Team</a>
                  </li>
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/roadmap">Roadmap</a>
                  </li>

                  {/* <li><a href="/" className="contact_link">Contact Us</a></li>
                          <li><a href="/" className="contact_link">Contacts Us</a></li> */}
                  <li>
                    <a href="/register/" className="link_btn">
                      {" "}
                      Join Now & Buy HotelierCoin
                    </a>
                  </li>
                  {/* <li><a href="/travolierclub" className="link_btn">Traveler's Club</a></li>
                              <li><a href="/privatesale" className="link_btn">Private Sale</a></li> */}
                </ul>
              </nav>

              {/* <a href="#" className="lang">Eng</a> */}

              <div className="right_topline_buttons">
                {/*<a href="#subscribe" className="btn transparent subscribe_link">Subscribe</a>*/}
                {!this.state.tableData ? (
                  <a href="/login" className="btn account">
                    SIGN IN
                  </a>
                ) : (
                  <a href="/cabinet/tokensale" className="btn account">
                    Dashboard
                  </a>
                )}
              </div>

              <div className="toggle_menu">
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile_menu" style={{ display: "none" }}>
          <ul>
            <li>
              <a href="/how-it-work" className="mobile_menu_link">
                HTL Token
              </a>
            </li>
            <li>
              <a href="/team" className="mobile_menu_link">
                Team
              </a>
            </li>
            <li>
              <a href="/faq" className="mobile_menu_link">
                FAQ
              </a>
            </li>
            <li>
              <a href="/Roadmap">Roadmap</a>
            </li>
            {/*<li><a href="pdf/HotelierCoinLP.pdf" target="_blank">Lightpaper</a></li>*/}
            <li>
              <a href="/careers">Careers</a>
            </li>
            {/* <li>
              <a href="/travolierclub" className="link_btn">
                Travolier's Club
              </a>
            </li> */}

            {/*<li><a href="#subscribe" className="mobile_menu_link">Subscribe</a></li>*/}
          </ul>
          <div className="right_topline_buttons">
            {/*<a href="#subscribe" className="btn transparent subscribe_link">Subscribe</a>*/}
            {!this.state.tableData ? (
              <a href="/login" className="btn account">
                SIGN IN
              </a>
            ) : (
              <a href="/cabinet/tokensale" className="btn account">
                Dashboard
              </a>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default Navbar
