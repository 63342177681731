import React, { Component } from 'react';
import axios from 'axios';
import '../public/css/login-custom.css';
import '../public/css/auth.css';
import Eye from '../public/img/eye.svg';
import Logo from '../public/img/logo_dark.png';
import { ToastsContainer, ToastsStore } from "react-toasts";
import loader from "../public/img/loader_dots.gif";
import $ from "jquery";

class verifyMobile extends Component {

    constructor(props) {

        super(props);

        this.state = {
            request_id: localStorage.getItem("request_id"),
            otp:''
            
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResend=this.handleResend.bind(this)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        //console.log(this.state)
    }
handleResend(){
    //console.log("Mobile" ,this.state.phone)
    //console.log("countrycode",this.state.countryCode)
    axios.post('https://hoteliercoins.co/api/v1/user/resendotp',this.state)
               
            .then( response => {
                if(response.data.status) {  
                    ToastsStore.success("OTP Resend successfuly");
                }
                else {
                    ToastsStore.info(response.data.message);
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
           
}
    handleSubmit(e) {
        e.preventDefault();     
        $(".action_loader").show();  
        axios.post('https://hoteliercoins.co/api/v1/verify-phone', this.state)
            .then( response => {
                if(response.data.status) {
                    //console.log(response, "IF")
                    // this.setState({token : response.data.data.token}); 
                    this.props.history.push("/cabinet/tokensale");       
                    $(".action_loader").hide();            
                }
                else {
                    //console.log(response,"ELSE");
                    $(".action_loader").hide(); 
                    ToastsStore.info(response.data.message);
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
    }
    componentDidMount(){
        var materialInputs = $('input.input-material');

        // activate labels for prefilled values
        materialInputs.filter(function () {
            return $(this).val() !== "";
        }).siblings('.label-material').addClass('active');
    
        // move label on focus
        materialInputs.on('focus', function () {
            $(this).siblings('.label-material').addClass('active');
        });
    
        // remove/keep label on blur
        materialInputs.on('blur', function () {
            $(this).siblings('.label-material').removeClass('active');
    
            if ($(this).val() !== '') {
                $(this).siblings('.label-material').addClass('active');
            } else {
                $(this).siblings('.label-material').removeClass('active');
            }
        });
    
    }
    render() {
        //console.log(this.state)
        return (
            <div id="large-header" className=""> 
            <div className="page login-page">
 <div className="">
 <div className="vimeo-wrapper">
             
             <iframe src="https://player.vimeo.com/video/355503337?background=1&autoplay=1&loop=1&byline=0&title=0"
                     frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
         </div>
   <div className="form-outer text-center d-flex align-items-center">
     <div className="form-inner">
       <div className="logo text-uppercase">
           <a href="/">
         <img src={Logo}/>  </a>  
       </div>
     
       {/* <p>Welcome to Hotelier Coin dashboard</p> */}
       <form method="get" className="text-left form-validate" autoComplete="hide" onSubmit={this.handleSubmit}>        
       <p class="mt-3 mb-2">Enter the OTP to verify your mobile number.</p>
         <div className="form-group-material">
           <input id="login-password" type="number" name="otp" required="required"   onChange={this.handleChange} className="input-material"/>
           <label for="login-password" className="label-material">Enter OTP</label>
         </div>
         
         <div className="form-group text-center">
             <button id="login" type="submit"  className="btn btn-primary btn-block">
                 <div className="action_loader" style={{display:'none'}}>
                     <img src={loader} />
                 </div>
                    Login
                </button>               
         </div>
       </form>
       <a href="javascript:void(0)" className="forgot-pass" onClick={this.handleResend}>Resend OTP</a>
       <a href="/cabinet/tokensale" className="forgot-pass" >Skip</a>
     </div>
     
   </div>
 </div>
</div>

           <div>
               <ToastsContainer store={ToastsStore} />
           </div>
       </div>
         
        );
    }
}

export default verifyMobile;



