import React, { Component } from "react";
import Heroicon from "./public/landing/img/benefits/heroicon.svg";
import Expenses from "./public/landing/img/benefits/expenses.png";
import Platform from "./public/landing/img/benefits/platform.png";
import Crypto from "./public/landing/img/benefits/crypto.png";

import Options from "./public/landing/img/benefits/option.png";

import Cashback from "./public/landing/img/benefits/cashback.png";

import OnestopShop from "./public/landing/img/benefits/onestopshop.png";

class Benefits extends Component {
  render() {
    return (
      <section className="content" style={{ paddingTop: "0px" }}>
        <h2
          className="section_header section_header_line"
          style={{ textAlign: "left" }}
        >
          Platform benefits
        </h2>
        <div className="benefits">
          <div className="benefit benefit1">
            <div className="first_benefit_main">
              <img src={Heroicon} alt="exchange platform" />
              <h4>
                Travolier club - Travel and experience booking Platform (B2C)
              </h4>
              <h4>Travolier social - A social network for travelers (B2C)</h4>
              <h4>Hotel room exchange - (B2B)</h4>
              <h4>Corporate Travel Management (B2E)</h4>
            </div>
          </div>
          <div className="benefit">
            <img src={Expenses} alt="Reduced operating/Booking Expenses" />
            <h4>
              The blockchain powered, ultimate travel & experience booking
              platform reducing booking & transactions fees in a secure
              environment
            </h4>
          </div>
          <div className="benefit">
            <img src={Crypto} alt="Travel Utility Crypto Investors" />
            <h4>
              One travel reward currency is covering the entire travel ecosystem
              that can be traded or monetized
            </h4>
          </div>
          <div className="benefit">
            <img
              src={Options}
              alt="First Time Bulk Room-Night Buy/Sell Option"
            />
            <h4>
              The Hotel Room Exchange - a secondary hotel-room marketplace to
              trade multiple hotel rooms night’s tokens
            </h4>
          </div>
          <div className="benefit">
            <img src={Cashback} alt="Cash Back" />
            <h4>
              One borderless travel currency, powered by StableCoin for cross
              border travel payments, integrated with a FinTech wallet
            </h4>
          </div>
          <div className="benefit">
            <img
              src={OnestopShop}
              alt="One-stop-shop for hospitality Industry"
            />
            <h4>A one-stop-shop for the entire hospitality and travel trade</h4>
          </div>
        </div>
        <div className="join">
          <p>Be a Part of the Future of Hospitality</p>
          <a
            href="https://www.travolier.com/"
            target="_blank"
            className="btn subscribe_link"
          >
            Join Now
          </a>
        </div>
      </section>
    );
  }
}

export default Benefits;
