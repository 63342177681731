import React, { Component } from "react"
import { ToastsContainer, ToastsStore } from "react-toasts"
import LogoFooter from "../public/landing/img/logo_white.png"
import LogoIcon from "../public/landing/img/logo_icon.png"
import FooterBanner from "../public/landing/img/footer_banner.png"
import TravolutionLogo from "../public/landing/img/travolution_logo.png"
import LogoSHRC from "../public/landing/img/logo_schr.png"
import axios from "axios"
import $ from "jquery"

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = { name: "", email: null, message: null }

    this.contact = this.contact.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
    //console.log(this.state)
  }

  componentDidMount() {
    //contact us popup
    $(".contact_link").on("click", function (e) {
      e.preventDefault()
      $("#contact-form-popup").css({ display: "flex" })
    })

    //close pop up
    $(".close-popup").on("click", function (e) {
      e.preventDefault()
      $("#contact-form-popup").css({ display: "none" })
    })
  }

  contact(event) {
    axios
      .post("https://hoteliercoins.co/api/v1/contact")
      .then((response) => {
        ToastsStore.success("Email Sent Successfully")
      })
      .catch(function (error) {
        ToastsStore.info("Something went wrong")
      })

    //close pop up
    $(function () {
      $("#contact-form-popup").css({ display: "none" })
    })

    event.preventDefault()
  }

  render() {
    return (
      <footer>
        <div className="content">
          <div className="contact_info">
            <a href="/" className="logo">
              <img src={LogoFooter} alt="hotelier" />
            </a>
            <p></p>
            <h6>Global Hotelier Pte. Ltd.</h6>
            <p>
              160 Robinson Road #14-04 <br /> Singapore (068914){" "}
            </p>
            <p>
              TEL: <a href="tel:+65-3159-1101 ">+65-3159-1101</a>
            </p>
            <p>
              MAIL:
              <a href="mailto:info@hoteliercoin.com ">info@hoteliercoin.com </a>
            </p>
            <ul className="socials">
              <li>
                <a
                  href="https://www.linkedin.com/company/hoteliercoin/?viewAsMember=true"
                  target="_blank"
                  className="linkedin"
                ></a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/Travolier/"
                  target="_blank"
                  className="fb"
                ></a>
              </li>
              <li>
                <a
                  href="https://t.me/hoteliercoin"
                  target="_blank"
                  className="telegram"
                ></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCH4N7RyDe1qSd-Lh4a8zs-Q/"
                  target="_blank"
                  className="youtube"
                ></a>
              </li>
              <li>
                <a
                  href="https://twitter.com/travolier"
                  target="_blank"
                  className="twitter"
                ></a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.ie/Travolier/"
                  target="_blank"
                  className="pinterest"
                ></a>
              </li>
            </ul>
          </div>
          <div className="image_container">
            <div className="logo_icon">
              <img src={LogoIcon} alt="hoteliercoin" />
            </div>
            <a
              target="_blank"
              href="https://www.travolution.com/articles/113230/travolution-awards-2019-winners-revealed"
            >
              <img src={TravolutionLogo} className="travolution_logo" />
            </a>
          </div>
          <div className="useful_links">
            <ul>
              <li>
                <a target="_blank" href="https://travolier.com/HowitWorks">
                  About HotelierCoin
                </a>
              </li>
              <li>
                <a target="_blank" href="https://travolier.com/AboutUs">
                  About Travolier
                </a>
              </li>

              <li>
                <a href="/faq ">FAQ</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/" className="contact_link">
                  Contact
                </a>
              </li>

              <li>
                <a href="/privacy">Privacy &amp; Cookie Policy</a>
              </li>
              <li>
                <a href="/terms">Terms &amp; Conditions</a>
              </li>
              <li>
                <a href="/disclaimer">Disclaimer</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="footer_banner">
          <img src={LogoSHRC} className="shrc_logo" />
        </div> */}
        <div className="footer_banner">
          <img src={FooterBanner} />
        </div>
        <div className="copyright">
          <p className="content">
            Copyright © 2018 - 2020 Global Hotelier PTE. LTD. Singapore
          </p>
        </div>

        <div
          id="contact-form-popup"
          className="popup_wrap"
          style={{ display: "none" }}
        >
          <div className="popup">
            <a href="#" className="close-popup">
              Close
            </a>
            <div className="popup-content">
              <div className="contacts">
                <h3 className="contact-title">Headquarters</h3>
                <p>
                  Global Hotelier Pte. Ltd.
                  <br />
                  160 Robinson Road #14-04
                  <br />
                  Singapore (068914)
                </p>

                <a href="mailto:info@hoteliercoin.com">info@hoteliercoin.com</a>
              </div>
              <form name="contact_us" onSubmit={this.contact}>
                <h3 className="contact-title">Contact Us</h3>
                <input
                  type="text"
                  id="contact_us_name"
                  className="contact-custom"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  required="required"
                  placeholder="Your name"
                />
                <input
                  type="email"
                  id="contact_us_email"
                  className="contact-custom"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  required="required"
                  placeholder="E-mail"
                />
                <textarea
                  id="contact_us_message"
                  name="contact_us[message]"
                  required="required"
                  name="message"
                  value={this.state.message}
                  onChange={this.handleChange}
                  placeholder="Write your message"
                ></textarea>
                <button className="btn" onClick={this.contact} value="Send">
                  Send
                </button>
                <input
                  type="hidden"
                  id="contact_us__token"
                  name="contact_us[_token]"
                  value="zxN6MwsSoPeevrIoRSeZmSrW4qgbHRpG-ZPHH6ck2iQ"
                />
              </form>
            </div>
          </div>
        </div>
        <div>
          <ToastsContainer store={ToastsStore} />
        </div>
      </footer>
    )
  }
}

export default Footer
