import React from "react"
import "./roadmap.css"
import "./map"
import $ from "jquery"
import Travel from "./public/img/travel.jpg"
import Banner1 from "./public/landing/img/banner/banner_how.jpg"
// import imgH1 from "./public/landing/img/hotel/1.png";
// import imgH2 from "./public/landing/img/hotel/2.png";
// import imgH3 from "./public/landing/img/hotel/3.jpg";
// import imgH4 from "./public/landing/img/hotel/4.png";
// import imgH5 from "./public/landing/img/hotel/5.png";
// import imgH6 from "./public/landing/img/hotel/6.png";
// import imgH7 from "./public/landing/img/hotel/7.png";
// import imgH8 from "./public/landing/img/hotel/8.png";
// import imgH9 from "./public/landing/img/hotel/9.png";
// import imgH10 from "./public/landing/img/hotel/10.png";
// import imgH11 from "./public/landing/img/hotel/11.png";
// import imgH12 from "./public/landing/img/hotel/12.png";
// import imgH13 from "./public/landing/img/hotel/13.png";
// import imgH14 from "./public/landing/img/hotel/14.png";
// import imgH15 from "./public/landing/img/hotel/15.png";
// import imgH16 from "./public/landing/img/hotel/16.png";
// import imgH17 from "./public/landing/img/hotel/17.png";
// import imgA1 from "./public/landing/img/airlines/1.png";
// import imgA2 from "./public/landing/img/airlines/2.png";
// import imgA3 from "./public/landing/img/airlines/3.png";
// import imgA4 from "./public/landing/img/airlines/4.png";
// import imgA5 from "./public/landing/img/airlines/5.png";
// import imgA6 from "./public/landing/img/airlines/6.png";
// import imgA7 from "./public/landing/img/airlines/7.png";
// import imgA8 from "./public/landing/img/airlines/8.png";
// import imgA9 from "./public/landing/img/airlines/9.png";
// import imgA10 from "./public/landing/img/airlines/10.png";
// import imgA11 from "./public/landing/img/airlines/11.png";
// import imgA12 from "./public/landing/img/airlines/12.png";
// import imgA13 from "./public/landing/img/airlines/13.png";
// import imgA14 from "./public/landing/img/airlines/14.png";
// import imgA15 from "./public/landing/img/airlines/15.png";
// import imgA16 from "./public/landing/img/airlines/16.png";
// import imgA17 from "./public/landing/img/airlines/17.png";
// import imgA18 from "./public/landing/img/airlines/18.png";
// import imgA19 from "./public/landing/img/airlines/19.png";

class ourCustomer extends React.Component {
  componentDidMount() {
    $(function () {
      $(".customer-logos").slick({
        slidesToShow: 6,
        slidesToScroll: 6,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        dots: false,
        rows: 3,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      })
    })

    /* Check the location of each element */
    $(".content").each(function (i) {
      var bottom_of_object = $(this).offset().top + $(this).outerHeight()
      var bottom_of_window = $(window).height()

      if (bottom_of_object > bottom_of_window) {
        $(this).addClass("hidden")
      }
    })

    $(window).scroll(function () {
      /* Check the location of each element hidden */
      $(".hidden").each(function (i) {
        var bottom_of_object = $(this).offset().top + $(this).outerHeight()
        var bottom_of_window = $(window).scrollTop() + $(window).height()

        /* If the object is completely visible in the window, fadeIn it */
        if (bottom_of_window > bottom_of_object) {
          $(this).animate({ opacity: "1" }, 700)
        }
      })
    })
  }

  render() {
    return (
      <>
        {/* <section className="how_work_sec">
          <div className="container">
            <img src={Banner1} />
          </div>
        </section> */}
        <section className="one_sec">
          <div className="container">
            <div className="one_travel_wrap">
              <div className="row">
                <div className="col-md-4 one_text_col">
                  <h2 className="one_text">ONE</h2>
                </div>
                <div className="col-md-8">
                  <ul className="one_travel_ul">
                    <li>
                      <i className="mdi mdi-dots-horizontal"></i> holistic
                      reward for all hotel & airline brands
                    </li>
                    <li>
                      <i className="mdi mdi-dots-horizontal"></i> travel reward
                      currency
                    </li>
                    <li>
                      <i className="mdi mdi-dots-horizontal"></i>{" "}
                      blockchain-backed travel database - traveler, home/hotel
                      owner & leisure service provider
                    </li>
                    <li>
                      <i className="mdi mdi-dots-horizontal"></i> decentralize
                      travel exchange – travel token marketplace
                    </li>
                    <li>
                      <i className="mdi mdi-dots-horizontal"></i> platform
                      connects travel fintech, travel posts to travel booking to
                      rewards
                    </li>
                    <li>
                      <i className="mdi mdi-dots-horizontal"></i> platform
                      club's boutique hotels, home, leisure service providers &
                      traveler earns rewards
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default ourCustomer
