import React, { Component } from 'react';
import axios from 'axios';
import '../public/css/login-custom.css';
import '../public/css/auth.css';
import Eye from '../public/img/eye.svg';
import Logo from '../public/img/logo_dark.png';
import { ToastsContainer, ToastsStore } from "react-toasts";
import {Link} from "react-router-dom";
import loader from "../public/img/loader_dots.gif";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import $ from "jquery";



class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        };

        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        //console.log(this.state)
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        this.setState({ loading: true });
        let abcd=this;
        //console.log(this.state);
        axios.post('https://hoteliercoins.co/api/v1/forgot-password', this.state)
            .then(function (res) {
                //console.log(res);
                if(res.data.status){
                    toastr.success('Password reset email sent successfully!');
                   // abcd.props.history.push("/resetting/password");
                }
                else{
                    toastr.error(res.data.message)
                }
            })


            .catch(function (error) {
                //console.log(error,'error');

            });
    }

    componentDidMount(){
        var materialInputs = $('input.input-material');

        // activate labels for prefilled values
        materialInputs.filter(function () {
            return $(this).val() !== "";
        }).siblings('.label-material').addClass('active');
    
        // move label on focus
        materialInputs.on('focus', function () {
            $(this).siblings('.label-material').addClass('active');
        });
    
        // remove/keep label on blur
        materialInputs.on('blur', function () {
            $(this).siblings('.label-material').removeClass('active');
    
            if ($(this).val() !== '') {
                $(this).siblings('.label-material').addClass('active');
            } else {
                $(this).siblings('.label-material').removeClass('active');
            }
        });
    }
    render() {
        return (
            <div id="large-header" className=""> 
            <div className="page login-page">
 <div className="">
 <div className="vimeo-wrapper">
             
             <iframe src="https://player.vimeo.com/video/355503337?background=1&autoplay=1&loop=1&byline=0&title=0"
                     frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
         </div>
   <div className="form-outer text-center d-flex align-items-center">
     <div className="form-inner">
       <div className="logo text-uppercase">
           <a href="/">
         <img src={Logo}/>  </a>  
       </div>
     
       {/* <p>Welcome to Hotelier Coin dashboard</p> */}
       <form method="get" className="text-left form-validate" autoComplete="hide" onSubmit={this.handleSubmit}>        
       <p class="mt-3 mb-2">Enter your registered email</p>
         <div className="form-group-material">
           <input id="login-password" type="email" name="email"
                                           required="required" autoComplete="username"   onChange={this.onChange} className="input-material"/>
           <label for="login-password" className="label-material">Enter Email</label>
         </div>
         
         <div className="form-group text-center">
             <button id="login" type="submit"  className="btn btn-primary btn-block">
                 <div className="action_loader" style={{display:'none'}}>
                     <img src={loader} />
                 </div>
                 Submit
                 </button>               
         </div>
       </form>
       <small>I don`t have an account. </small><a href="/register/" className="signup">Register now</a>
     </div>
     
   </div>
 </div>
</div>

           <div>
               <ToastsContainer store={ToastsStore} />
           </div>
       </div>
            
        );
    }
}

export default Forgot;






