import React, { Component } from "react"

import Slider from "react-slick"
import $ from "jquery"
import playIcon from "./public/landing/img/icons/play.png"
import CoinGlow from "./public/landing/img/coin_glow.png"
import coin from "./public/landing/img/coin.png"
import AlwayOnImage from "./public/landing/img/sticky.png"

import Navbar from "./includes/navbar"

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tableData: localStorage.getItem("token"),
    }
  }

  onCheck = (event) => {
    event.preventDefault()

    if (!localStorage.getItem("authorization")) {
      this.props.history.push("/login")
    } else {
      this.props.history.push("/cabinet/tokensale")
    }
  }
  componentDidMount() {
    // Mobile menu
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
    }
    return (
      <div style={{ position: "relative" }}>
        <Navbar />

        <Slider {...settings} className="slider_main">
          <div className="slide_item slide0">
            <div className="coin_glow">
              <img src={CoinGlow} />
            </div>
            <div className="slide_content">
              <h2>AWARD-WINNING TRAVEL REWARD CURRENCY</h2>
              <h3>
                Rewarding the Traveler – Synchronizing the Hospitality Industry.
              </h3>
            </div>
          </div>
          <div className="slide_item slide1">
            <div className="slide_content white_text">
              <h2>A TRAVEL LOYALTY MARKETPLACE.</h2>
              <h3>Loyalty Points are Replaced With Loyalty Coins</h3>
            </div>
          </div>
          <div className="slide_item slide2">
            <div className="slide_content">
              <h2>DECENTRALIZE TRAVEL DATABASE.</h2>
              <h3>Powering The Loyalty Program</h3>
            </div>
          </div>
          <div className="slide_item slide3">
            <div className="slide_content">
              <h2>ALTERNATIVE TRAVEL MARKET INFRASTRUCTURE.</h2>
              <h3>Tokenizing The Travel & Hospitality Industry</h3>
            </div>
          </div>
          <div className="slide_item slide4">
            <div className="slide_content">
              <h2>distributed. TRAVEL PAYMENT INFRASTRUCTURE</h2>
              <h3>Clubbing Stand-Alone Hotels, Vacation Homes & Travelers</h3>
            </div>
          </div>
        </Slider>
        <div className="bottom_banner_sec">
          <ul class="ul_block">
            <li class="">
              <div class="main-page-service-block">
                <h4>600K+</h4>
                <h2>Hotels</h2>
              </div>
            </li>
            <li class="">
              <div class="main-page-service-block">
                <h4>185+</h4>
                <h2>Countries</h2>
              </div>
            </li>
            <li class="">
              <div class="main-page-service-block">
                <h4>5000+</h4>
                <h2>Cities</h2>
              </div>
            </li>
            <li class="mobile_ss ss_1">
              <div class="main-page-service-block">
                <h4>700</h4>
                <h2>Airlines</h2>
              </div>
            </li>
            <li class="Travel_Reward mobile_ss">
              <div class="main-page-service-block">
                <h4>1</h4>
                <h2>Loyalty PROGRAM</h2>
              </div>
            </li>
          </ul>
        </div>

        {/* <div className="always_on_stick">
          <a href="/register">
            <img className="sticky" src={AlwayOnImage} />
          </a>
        </div> */}

        <a
          className="popup-youtube_btn"
          href="https://www.youtube.com/embed/5Fvie7dE3lk?rel=0&autoplay=1"
        >
          <img src={playIcon} />
        </a>

        <div className="video_popup_wrap popup_wrap">
          <div className="popup">
            <a href="#" className="close-popup">
              Close
            </a>
            <div className="video_popup">
              <iframe
                src="https://www.youtube.com/embed/5Fvie7dE3lk"
                allowFullScreen=""
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home
