import React, { Component } from "react"
import Navbar from "./includes/navbar"
import Footer from "./includes/footer"
import Benefits from "./benefits"
import OurBrands from "./OurBrands"
import HomeSlider from "./homeSlider"
import OurNetwork from "./OurNetwork"
import Media from "./Media"
import Banner from "./public/landing/img/banner/banner_crypto.png"
import StrategicPartners from "./StrategicPartners"

import OurTeam from "./OurTeam"
import RoadMap from "./roadMap"
import Book from "./book"
import OurCustomer from "./ourCustomer"
import videoBg from "./public/landing/img/video/video_bg.jpg"
import playIcon from "./public/landing/img/video/play.svg"
import HLogo from "./public/landing/img/video/logo.svg"

import dashLogo from "./public/landing/img/dash.png"
import logoWhite from "./public/landing/img/logo_white.png"
import TravlogoWhite from "./public/landing/img/travolier_white.png"
import Rana from "./public/landing/img/team/RanaMukherji.jpg"

import Frobs from "./public/img/forbs.png"
import WinnerImg from "./public/img/winner.svg"
import WinnerAward from "./public/landing/img/award.jpeg"
import Award1 from "./public/landing/img/award_1.jpg"
import Award2 from "./public/landing/img/award_2.png"
import Award3 from "./public/landing/img/award_3.png"
import $ from "jquery"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import IdIcon from "./public/landing/img/icons/new/id-card.png"
import HasTag from "./public/landing/img/icons/new/hashtag.png"
import Thinking from "./public/landing/img/icons/new/critical-thinking.png"
import PicturesIcon from "./public/landing/img/icons/new/picture.png"
import HomeIcon from "./public/landing/img/icons/new/home.png"
import Golf from "./public/landing/img/icons/new/golf.png"

import GraphTwoImg from "./public/landing/img/Graph2.png"
import DtmiLogo from "./public/landing/img/dtmi_logo.png"
import RegisterForm from "./auth/RegisterForm"

const responsive2 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}
class Home extends Component {
  constructor(props) {
    super(props)

    this.onePager = this.onePager.bind(this)
    this.whitePaper = this.whitePaper.bind(this)
  }

  componentDidMount() {
    $(".faq_list li").on("click", function () {
      $(this).toggleClass("active")
      $(this).find(".hint").slideToggle(200)
    })
    $(function () {
      $(".popup-youtube").magnificPopup({
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        iframe: {
          patterns: {
            youtube: {
              index: "youtube.com",
              id: "v=",
              src: "https://www.youtube.com/embed/OBXSlG63E9o?rel=0&autoplay=1",
            },
          },
        },
      })

      $(".popup-youtube_btn").magnificPopup({
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        iframe: {
          patterns: {
            youtube: {
              index: "youtube.com",
              id: "v=",
              src: "https://www.youtube.com/embed/5Fvie7dE3lk?rel=0&autoplay=1",
            },
          },
        },
      })
    })

    // $("#close_welcome").click(function(){
    //     $(".welcome_popup_modal").hide();
    // })

    $(".moreless-button").click(function () {
      $(this).closest(".moretext").toggle()

      if ($(this).text() === "Read more") {
        $(this).text("Read less")
      } else {
        $(this).text("Read more")
      }
    })
  }

  onePager() {
    const url = "pdf/onepager.pdf"
    window.open(url).focus()
  }
  whitePaper() {
    const url = "pdf/onepager.pdf"
    window.open(url).focus()
  }

  render() {
    return (
      <div>
        <header style={{ marginBottom: "0px" }}>
          <HomeSlider />
        </header>

        {/* <Book /> */}

        {/* <div class="welcome_popup_modal">
       
<div class="my-popup-background-layer"> 
 
  <div class="my-popup-container mlctr-popup">
 
    <a class="mlctr-close" id="close_welcome">
        <i class="mdi mdi-close"></i>
    </a>
      
       <div class="mlctr-message-success" >
        <div class="row">
            <div class="col-md-7">
                    <h1 class="">
                        
                            <img class="t-logo" src={TravlogoWhite} alt="hotelier"/></h1>
                           <h2 >TRAVELER'S CLUB REWARDED WITH HOTELIERCOIN</h2>
                           
                        <h2>Create an account for the industry’s first of its kind loyalty program. Earn HotelierCoin while exploring the world. HotelierCoin as an instant cashback reward with every purchase at HotelierMart.</h2>
                        <h2>
                        For the first time, a blockchain powered loyalty program that grows and empowers the traveler in you. Loyalty is priceless, but we do our best to reward it — piggy bank your journey with HotelierCoin rewards!
                        </h2>
                        <h2>
                        The most powerful travel reward currency, which can be traded, monetized, or redeem for future travel expenses.
                        </h2>
                       
                      
            </div>
            <div class="col-md-5 card-img-col">
                    <img src={cardHome} alt="hotelier mart logo"/>
            </div>
        </div>
       
         
         
        
        
        
       </div>
       
  
    </div>
    </div>
</div> */}

        <div className="">
          <section
            className="video_section"
            id="about"
            style={{ marginBottom: "0px" }}
          >
            <div className="content">
              <div className="qoute_wrap">
                <img
                  src="https://uploads-ssl.webflow.com/5c0ebe0bc3abf564c79e6c17/5c0ebe0bc3abf516759e6d25_quotation-mark.svg"
                  width="50"
                  alt="Light quote icon"
                  class="speech-icon"
                />
                <div class="quote-text">
                  Could The Hotelier Coin Be The Answer To Revamping The
                  Hospitality Industry?
                </div>
                <a
                  target="_blank"
                  href="https://www.forbes.com/sites/rebeccacampbell1/2018/10/16/could-the-hotelier-coin-be-the-answer-to-revamping-the-hospitality-industry/#2e037d35d419"
                >
                  <img src={Frobs} className="forbs_logo" />
                </a>
              </div>

              {/* <div class="banner banner--gradient-bg">
                <div>
                  <div class="banner__title">We proudly accept Dash</div>
                  <div class="banner__text">
                    An introduction offer - Every purchase with Dash, earns an
                    additional 0.5% in HotelierCoin as cashback. Earn an
                    additional 1% bonus when purchasing HTLC with Dash.
                  </div>
                </div>
                <div class="banner__cta">
                  <img src={dashLogo} />
                </div>
              </div> */}

              {/* <section className="winner_award_cc_ss">
                <div
                  className="container our_customer_main"
                  style={{ paddingTop: "0px" }}
                >
                  <div className="one_travel_wrap">
                    <h2
                      className="section_header section_header_line"
                      style={{ marginTop: "0px", textAlign: "left" }}
                    >
                      Travolution Awards 2019
                    </h2>
                    <div className="row">
                      <div className="col-md-6 one_text_col">
                        <img src={WinnerAward} style={{ width: "100%" }} />
                      </div>
                      <div className="col-md-6 winner_right_cc">
                        <p>
                          <a href="https://www.travolution.com/articles/113230/travolution-awards-2019-winners-revealed">
                            <img src={Award1} style={{ height: "75px" }} />
                          </a>
                        </p>
                        <p>
                          <a href="https://www.travolution.com/articles/113230/travolution-awards-2019-winners-revealed">
                            <img src={Award3} style={{ height: "70px" }} />
                          </a>
                        </p>
                        <p>
                          <a href="https://www.travolution.com/articles/113230/travolution-awards-2019-winners-revealed">
                            <img src={Award2} style={{ width: "100%" }} />
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}

              {/* <div className="winner_wrap">
                <div class="banner-container">
                  <div class="winning_banner">
                    <div class="banner__logo">
                      <div class="banner__text">
                        <a href="#something">
                          <img
                            alt=""
                            src={WinnerImg}
                            title="Travolution Awards 2019"
                            width="180"
                            className="winning_icon"
                          />
                        </a>
                      </div>
                      <div class="banner__irregular-section"></div>
                    </div>
                    <div class="banner__intro">
                      <div class="banner__text">
                        <strong>Travolution Awards 2019</strong>
                      </div>
                      <div class="banner__irregular-section"></div>
                    </div>
                    <div class="banner__content">
                      <div class="banner-slides">
                        <div class="banner-slides__item">
                          2019 Travolution Award Winner, by Travel Weekly U.K.
                        </div>

                        <div class="banner-slides__item">
                          "Best Technology Innovation" <br />
                        </div>

                        <div class="banner-slides__item">
                          Nominated: Best Start-up
                        </div>
                      </div>
                    </div>
                    <div class="banner__cta">
                      <a
                        target="_blank"
                        href="https://www.travolution.com/articles/113230/travolution-awards-2019-winners-revealed"
                        class="banner__btn"
                      >
                        View more
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="video hd-video" style={{ paddingLeft: "50px" }}>
                <iframe
                  style={{ marginTop: "50px" }}
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/HjLOUVcSghw"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen=""
                ></iframe>

                <div className="wp_btn_wrap">
                  <a
                    className={"wpBtn"}
                    href="/pdf/htlc_onepager_102019.pdf"
                    target="_blank"
                  >
                    Download OnePager
                  </a>
                  <a
                    className={"wpBtn"}
                    href="/pdf/htlc_wp_102019.pdf"
                    target="_blank"
                  >
                    Download Whitepaper
                  </a>
                </div>

               
              </div> */}
            </div>
          </section>

          {/* <OurBrands /> */}

          <section className="four_points_sec">
            <div className="container ">
              <h2 className="section_title">
                360 DEGREE - FOUR POINTS SOLUTION
              </h2>
              <div className="row four_container">
                <div className="col-md-6 right_padding">
                  <h2 className="section_title">
                    A TRAVEL LOYALTY MARKETPLACE,
                  </h2>
                  <p>
                    where both traveler's & boutique hotels/vacations homes earn
                    <strong> LOYALTY COINS instead of LOYALTY POINTS</strong> -
                    as cashback from travel booking, travel fintech payments to
                    social travel posts and reviews.
                  </p>
                  <p>
                    <strong>One TRAVEL REWARD CURRENCY</strong> that connects
                    multiple brands, independent homes with travelers at
                    wholesale rates. HotelierCoin can be monetized, traded, or
                    redeemed for all travelrelated payments.
                  </p>
                </div>
                <div className="col-md-6 left_padding">
                  <h2 className="section_title">
                    DECENTRALIZE. TRAVEL DATABASE, POWERING THE LOYALTY PROGRAM
                  </h2>
                  <p>
                    with digital identity, traveler's profile with independent
                    hotel/homeowner to empower them to know the customer &
                    customer receives brand like experience. Traveler's booking
                    & traveling experience contents are as per their preferences
                    curated by AI/NEO SCIENCE travel genie.
                  </p>
                </div>
                <div className="col-md-6 right_padding">
                  <h2 className="section_title">
                    ALTERNATE. TRAVEL MARKETPLACE, TOKENIZING THE TRAVEL &
                    HOSPITALITY INDUSTRY,
                  </h2>
                  <p>
                    from hotel rooms futures to travel services tokens, can be
                    traded in a secondary marketplace.
                  </p>
                  <p>
                    <strong>
                      TRAVEL VOUCHER IS REPLACED WITH TRAVEL TOKENS.
                    </strong>
                  </p>
                </div>
                <div className="col-md-6 left_padding">
                  <h2 className="section_title">
                    DISTRIBUTED. TRAVEL PAYMENT INFRASTRUCTURE
                  </h2>
                  <p>
                    with an integrated end-to-end fintech wallet with digital
                    banking where hotel owners, service providers, and travelers
                    can finally access one common platform and buy futures & pay
                    for services with no middlemen, high fees or lack of
                    efficiency.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <StrategicPartners />
          <OurCustomer />

          {/* <div
            className="subscribe_now hotelier_in_numbers"
            style={{ marginBottom: "0px" }}
          >
            <div className="content">
              <div>
                <h4>HotelierCoin in numbers</h4>
                <p>
                  <span>600, 000</span>+ <span>hotels</span> in 185+ countries,
                  over 5 000+ cities
                </p>
                <p>
                  <span>700 Airlines</span>
                </p>
                <p>
                  <span>Airport transfers in 120 countries</span>
                </p>
                <p>
                  <span>Car rentals available in 140 countries</span>
                </p>
                <p>
                  <span>Leisure Service Providers</span>
                </p>
                <p>
                  <span>And numbers keep growing!</span>
                </p>
              </div>
            </div>
          </div> */}

          {/* <Benefits /> */}

          {/* <div className="subscribe_now">
            <div className="content">
              <div>
                <h4>
                  THIS ISN’T JUST INNOVATION. <br />
                  IT’S DISRUPTION.
                </h4>
                <p>
                  HotelierCoin aims to build a platform that will facilitate
                  global travel payments and reward the entire travel &
                  hospitality industry.
                </p>
              </div>
            </div>
          </div> */}

          <section className="vide_sec_sssssss">
            <div className="container">
              <h2 className="section_title">THE TRAVEL REWARD CURRENCY</h2>
              <div className="coin">
                <div className="coin_info">
                  <p>
                    <strong>HTLC</strong> The available supply and demand of the
                    HotelierCoin token ensure equilibrium. HotelierCoin
                    increases in capitalization due to an increase in demand
                    through Travolier.com and other travel & fintech partners,
                    combined with smart contract managed token emissions.
                  </p>
                </div>

                <div class="video video_bbbbb">
                  <img src={videoBg} alt="video" />
                  <a
                    className="popup-youtube"
                    href="https://www.youtube.com/watch?v=OBXSlG63E9o&feature=youtu.be"
                  >
                    <img src={playIcon} alt="play icon" class="play" />
                  </a>
                </div>
              </div>
            </div>
          </section>

          {/* <div className="subscribe_form" id="subscribe">
                        <div className="content">
                        <h2 className="section_header section_header_line" style={{marginTop: '0px', textAlign:'left'}}>Introducing Hotelier Coin Rewards</h2>
                            <div>
                                <p>Big things are about to happen. <br/>Stay tuned.</p>
                            </div>
                        </div>
                    </div> */}

          {/* <OurTeam />

          <RoadMap /> */}
          {/* <section className="graph_section">
            <div className="container">
              <h2 className="section_title">
                DECENTRALIZED TRAVEL DATABASE. POWERING THE LOYALTY PROGRAM
              </h2>
              <div className="ss_row">
                <div className="slide_list">
                  <img src={IdIcon} />
                  <h5>Travelers Digital Identity In Blockchain</h5>
                </div>
                <div className="slide_list">
                  <img src={HasTag} />
                  <h5>Travelers Owns The Data</h5>
                </div>
                <div className="slide_list">
                  <img src={Thinking} />
                  <h5>Travel Profile is Curated with AI/Neo Science</h5>
                </div>
                <div className="slide_list">
                  <img src={PicturesIcon} />
                  <h5>
                    Travelers Earns Loyalty Coin for all Travel Posts, Reviews &
                    Ratings
                  </h5>
                </div>
                <div className="slide_list">
                  <img src={HomeIcon} />
                  <h5>
                    Boutique Hotel/Homeowners Have access to Traveler's Profile
                  </h5>
                </div>
                <div className="slide_list">
                  <img src={Golf} />
                  <h5>Leisure Service Provider Digital Identity</h5>
                </div>
              </div>
              <ul>
                <li>Travelers Digital Identity in Blockchain</li>
                <li>
                  Boutique Hotel/ Homeowners Have access to Traveler's Profile
                </li>
                <li>Travel Profile is Curated with AI/Neo Science</li>
                <li>
                  Travelers Earn Loyalty Coin for all Travel Posts, Reviews &
                  Ratings
                </li>
                <li>Travelers Own the Data</li>
                <li>Leisure Service Provider</li>
              </ul>
            </div>
          </section> */}
          <section className="graph_section graphtwo">
            <div className="container">
              <h2 className="section_title ">
                DISTRIBUTED. TRAVEL PAYMENT INFRASTRUCTURE
              </h2>
              <div className="row">
                <div className="col-md-6 dtmi_logo">
                  <h2>POWERING CUSTOMER-CENTRIC TRAVEL MARKETPLACE </h2>

                  {/* <ul>
                    <li>
                      Specialized travel fintech wallet payments & debit cards
                      with HotelierCoin as a cashback reward.{" "}
                    </li>
                    <li>
                      Digital banking solutions for boutique hotels, vacation
                      homes, leisure service providers, restaurants, homeowners
                      & more.{" "}
                    </li>
                    <li>
                      For leisure service providers, restaurants, homeowners &
                      more.
                    </li>
                    <li>Crypto to fiat gateway. </li>
                    <li>
                      Gold price backed, a borderless currency fortravel
                      payments
                    </li>
                  </ul> */}

                  <div className="ashfkajhsd">
                    <img src={DtmiLogo} />
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <img src={GraphTwoImg} className="gg_one" />
                </div>
              </div>
            </div>
          </section>
          <section className="vision_sec">
            <div className="container">
              <h2 className="section_title ">MISSION</h2>

              <div className="mission_text">
                <h5>
                  "We are in a mission to create an alternative travel market
                  infrastructure to connect buyers with sellers – in a secured
                  holistic marketplace."
                </h5>
              </div>
            </div>
          </section>
          <Media />
          <section className="cryptoBanner">
            <div className="container">
              <img src={Banner} />
            </div>
          </section>

          {/* <OurNetwork /> */}

          <section className="register_form_sec">
            <div className="container">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <h2>Register at HotelierCoin</h2>
                  <h5 className=" mb-4">
                    Buy <strong>HotelierCoin</strong> with all major{" "}
                    <strong>Crypto Currencies</strong> and book your favorite
                    hotel at Members-rates only.
                  </h5>
                  <RegisterForm />
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    )
  }
}

export default Home
