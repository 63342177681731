import React, { Component } from 'react';
import Sidebar from "../cabinet/sidebar";
import TopBar from "./topBar"
import '../public/css/account.css';
import '../public/css/account-custom.css';
import './tokensale.css';

class Faq extends Component {
    componentDidMount(){
        let abcd=this;
        if(!localStorage.getItem('token')){
            abcd.props.history.push("/");
        }
    }
    render() {
        return (
            <div>
                <Sidebar/>
                <TopBar />


                <div style={{background:'white', width: '85%', float: 'right', padding:'40px'}}>
                    <div style={{display: 'flex'}}>

                        <div className="main_block faq main-block-custom" style={{width:'100%'}}>
                        {/*<div className="title title-custom">*/}
                            {/*<h2>F.A.Q.</h2>*/}
                            {/*<h3>Few very interesting words</h3>*/}
                        {/*</div>*/}
                        <ul className="faq-underline-custom" style={{width:'100%'}}>
                            <li>
                                <div className="item">
                                    <p className="font-emphasis-custom">What is Hotelier Coin?</p>
                                </div>
                                <div className="hint">
                                    <p className="content-font-color-custom">Hotelier Coin is a blockchain-powered hotel
                                        room booking, sales and trading platform. It was created by a team of hospitality
                                        experts determined to build a B2B, B2C and Peer-to-Peer solution that connects
                                        businesses, investors and consumers while reducing costs, maximizing profit and
                                        adding value to all stakeholders.</p>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <p className="font-emphasis-custom">Where is Hotelier Coin registered?</p>
                                </div>
                                <div className="hint">
                                    <p className="content-font-color-custom">Hotelier Coin is registered in Singapore.
                                        Incorporation details`:` Hotelier Coin Pte. Ltd. 28C Stanley Street, Singapore
                                        068737.</p>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <p className="font-emphasis-custom">What is HOTEL-HTL?</p>
                                </div>
                                <div className="hint">
                                    <p className="content-font-color-custom">HOTEL – HTL is our purpose-built borderless
                                        hospitality currency, it will power the entire Hotelier Coin platform, simplify
                                        transactions, reduce costs and reward loyalty. We work with DigiCrypts, a Canadian
                                        publicly listed token economics company, operate on the Ethereum blockchain and
                                        designed a strategy focused on stable growth in value to ensure that HTL empowers
                                        the entire hospitality ecosystem seamlessly.</p>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <p className="font-emphasis-custom">Who are the partners and team?</p>
                                </div>
                                <div className="hint">
                                    <p className="content-font-color-custom">All the partners and team members were
                                        handpicked by the Founder, Rana Mukherji. The team is comprised of top experts in
                                        hotel management, blockchain, cryptocurrencies and finance, and ranges from an
                                        absolute legend with 50 years of experience to a highly talented 18-year-old
                                        computer science professional. In addition to an outstanding team of experts, we
                                        partnered with leading award-winning companies around the globe that specialize in
                                        areas that include customized technology solutions and reservation management
                                        solutions.</p>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <p className="font-emphasis-custom">Why Blockchain and Smart Contracts?</p>
                                </div>
                                <div className="hint">
                                    <p className="content-font-color-custom">Blockchain and smart contracts enable a
                                        decentralized platform by creating a seamless ecosystem where transactions and
                                        operations become efficient and, above all, secure. Together, they secure the
                                        interests of all parties involved, create an unhackable environment and have the
                                        power to transform the entire hospitality ecosystem.</p>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <p className="font-emphasis-custom">How many hotels do you have in your system?</p>
                                </div>
                                <div className="hint">
                                    <p className="content-font-color-custom">Hotelier Coin is set to take the hospitality
                                        industry by storm, we are starting with 180 000 hotels across 180 000 cities in our
                                        booking system and 6000 hotels in our hotel property management system and still
                                        growing.</p>
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <p className="font-emphasis-custom">What other services will you offer?</p>
                                </div>
                                <div className="hint">
                                    <p className="content-font-color-custom">Hotelier Coin focuses on offering a complete
                                        hospitality and travel solution. Our services will range from car rental services
                                        and tour packages to hiring and hospitality products.</p>
                                </div>
                            </li>

                        </ul>
                    </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default Faq;
