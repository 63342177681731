import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
//import Arkansas from './public/landing/img/network-logos/arkansas.png';
import California from './public/landing/img/network-logos/california.png';
import Europestays from './public/landing/img/network-logos/europestays.png';
import Fishing from './public/landing/img/network-logos/fishing.png';
import Florida from './public/landing/img/network-logos/florida.png';
import Golfing from './public/landing/img/network-logos/golfing.png';
import Illionis from './public/landing/img/network-logos/illionis.png';
import Longterm from './public/landing/img/network-logos/longterm.png';
import Magnificent from './public/landing/img/network-logos/magnificent.png';
import Nchomerentals from './public/landing/img/network-logos/nchomerentals.png';
import Nyhome from './public/landing/img/network-logos/nyhome.png';
import Petfriendly from './public/landing/img/network-logos/petfriendly.png';
import Skihomestays from './public/landing/img/network-logos/skihomestays.png';
import Vria from './public/landing/img/network-logos/vria.png';
import Washington from './public/landing/img/network-logos/washington.png';

class OurNetwork extends React.Component {

    responsive = {
        0: { items: 1 },
        600: { items: 1 },
        960: { items: 3 }
    }

    stagePadding = {
        paddingLeft: 30,
        paddingRight: 30,
    }

    render() {

        return (
            <section>
                <div className="content our-network-main" style={{paddingTop: '0px'}}>
                    <h2 className="section_header section_header_line" style={{marginTop: '0px', textAlign: 'left'}}>Our Network</h2>

                    <AliceCarousel duration={2000} autoPlay={true} buttonsDisabled={true} mouseDragEnabled={true} onSlideChanged={console.debug} infinite={true} dotsDisabled={true} showSlideInfo={false} responsive={this.responsive} stagePadding={this.stagePadding}>
                        {/*<div className="item">*/}
                            {/*<div className="owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>*/}
                                {/*<div className="member-wrap" style={{padding: '30px'}}>*/}
                                    {/*<a target="_blank" href="http://arkansasstays.com/">*/}
                                        {/*<img src={Arkansas} width="150" />*/}
                                    {/*</a>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        <div className="item">
                            <div className="owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="http://californiahomestays.com">
                                        <img src={California} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="http://floridahomestays.com">
                                        <img src={Florida} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="http://europehomestays.com">
                                        <img src={Europestays} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="http://illinoisstays.com/">
                                        <img src={Illionis} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="owl-item" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="#">
                                        <img src={Washington} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="owl-item active" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="#">
                                        <img src={Nchomerentals} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className="item">
                            <div className="owl-item active" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="http://nyhomerentals.com">
                                        <img src={Nyhome} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="owl-item" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="https://vacationrentalsinamerica.com/">
                                        <img src={Vria} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="https://fishingstays.com/">
                                        <img src={Fishing} width="150" /></a>
                                </div>
                            </div>
                        </div>


                        <div className="item">
                            <div className="owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="https://golfingstays.com/">
                                        <img src={Golfing} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>



                        <div className="item">
                            <div className="owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="http://goextendedstays.com/">
                                        <img src={Longterm} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="owl-item cloned" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="#">
                                        <img src={Magnificent} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className="item">
                            <div className="owl-item active" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="http://petfriendlyvacationhome.com/">
                                        <img src={Petfriendly} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className="item">
                            <div className="owl-item" style={{width: '349.333px', marginRight: '10px'}}>
                                <div className="member-wrap" style={{padding: '30px'}}>
                                    <a target="_blank" href="">
                                        <img src={Skihomestays} width="150" />
                                    </a>
                                </div>
                            </div>
                        </div>



                        {/*<div className="item">*/}
                            {/*<div className="owl-item" style={{width: '349.333px', marginRight: '10px'}}>*/}
                                {/*<div className="member-wrap" style={{padding: '30px'}}>*/}
                                    {/*<a target="_blank" href="http://gonorthcarolinastays.com/">*/}
                                        {/*<img src="https://www.searchstays.com/images/15.png" />*/}
                                    {/*</a>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}

                        {/*<div className="item">*/}
                            {/*<div className="owl-item" style={{width: '349.333px', marginRight: '10px'}}>*/}
                                {/*<div className="member-wrap" style={{padding: '30px'}}>*/}
                                    {/*<a target="_blank" href="">*/}
                                        {/*<img src="https://www.searchstays.com/images/16.png"/>*/}
                                    {/*</a>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}


                    </AliceCarousel>
                </div>
               
            </section>



        )
    }
}

export default OurNetwork;

