import React, { Component } from "react"
import Home from "./home"
import Login from "./auth/login"
import Register from "./auth/register"
import Careers from "./career"
import TravoliersClub from "./travolierClub"
import PrivateSale from "./privateSale"
import EmailVerification from "./emailverification"
import TokenSale from "./cabinet/TokenSale"
import AirdropDashboard from "./cabinet/airdropDashboard"
import Faq from "./cabinet/faq"
import History from "./cabinet/history"
import Account from "./cabinet/account"
import AccountEdit from "./cabinet/accountEdit"
import Make from "./cabinet/makePayment"
import Forgot from "./auth/forgot"
import Reset from "./auth/reset"
import Try from "./try"
import Withdraw from "./cabinet/withdraw"
import { BrowserRouter as Router, Route } from "react-router-dom"
import PaymentSuccessful from "./cabinet/paymentSuccess"
import PaymentCanceled from "./cabinet/paymentCancle"
import Privacy from "./privacy"
import $ from "jquery"
import Terms from "./terms"
import VerifyMobile from "./auth/verify-mobile"
import EmailVerify from "./auth/email_verify"
import ResetPassword from "./auth/reset_password"
import FaqHome from "./Faq"
import HowItWork from "./HowItWork"
import roadMap from "./roadMap"
import OurTeam from "./OurTeam"
import Disclaimer from "./disclaimer"

import RegisterTry from "./auth/registerTry"

class App extends Component {
  componentWillMount() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop()

      //>=, not <=
      if (scroll >= 500) {
        //clearHeader, not clearheader - caps H
        $("header .topline_wrap").addClass("darkHeader")
      } else {
        $("header .topline_wrap").removeClass("darkHeader")
      }
    }) //missing );
    window.scrollTo(0, 0)

    // $(document).on("click", function(e) {
    //     if ($(e.target).is(".topline") === false) {
    //       $(".mobile_menu").hide();
    //     }
    //   });
    $(".toggle_menu").mouseup(function () {
      $(this).toggleClass("on")
      if ($(window).width() <= 550) {
        if ($(".mobile_menu").hasClass("visible")) {
          $(".mobile_menu").hide()
        } else {
          $(".mobile_menu").show()
        }
        $(".mobile_menu").toggleClass("visible")
      } else {
        if ($(".topline_bg nav").hasClass("visible")) {
          $(".topline_bg nav").hide()
        } else {
          $(".topline_bg nav").show()
        }
        $(".topline_bg nav").toggleClass("visible")
      }
    })
    $("body").mouseup(function (e) {
      e.preventDefault()
      var $tgt = $(e.target)
      if (!$tgt.closest(".mobile_menu, .toggle_menu").length) {
        if ($(".mobile_menu").hasClass("visible")) {
          $(".mobile_menu").hide().removeClass("visible")
          $(".toggle_menu").removeClass("on")
        }
      }
      if ($tgt.closest(".mobile_menu a").length) {
        if ($(".mobile_menu").hasClass("visible")) {
          $(".mobile_menu").hide().removeClass("visible")
        }
      }
    })
  }

  render() {
    return (
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgotpassword" component={Forgot} />
        <Route exact path="/resetting/password" component={Reset} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/travolierclub" component={TravoliersClub} />
        <Route exact path="/privatesale" component={PrivateSale} />
        <Route exact path="/emailverification" component={EmailVerification} />
        <Route exact path="/cabinet/tokensale" component={TokenSale} />
        <Route
          exact
          path="/cabinet/airdrop-dashboard"
          component={AirdropDashboard}
        />
        <Route exact path="/cabinet/faq" component={Faq} />
        <Route exact path="/cabinet/withdraw" component={Withdraw} />
        <Route exact path="/cabinet/history" component={History} />
        <Route exact path="/cabinet/profile" component={Account} />
        <Route exact path="/cabinet/profile/edit" component={AccountEdit} />
        <Route exact path="/cabinet/makepayment" component={Make} />
        <Route
          exact
          path="/cabinet/payment-successful"
          component={PaymentSuccessful}
        />
        <Route
          exact
          path="/cabinet/payment-canceled"
          component={PaymentCanceled}
        />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/verify-mobile" component={VerifyMobile} />
        <Route exact path="/try" component={Try} />
        <Route exact path="/confirmation/:id" component={EmailVerify} />
        <Route exact path="/reset/:id" component={ResetPassword} />
        <Route exact path="/reset/:id" component={ResetPassword} />
        <Route exact path="/register-try" component={RegisterTry} />
        <Route exact path="/faq" component={FaqHome} />
        <Route exact path="/how-it-work" component={HowItWork} />
        <Route exact path="/team" component={OurTeam} />
        <Route exact path="/roadmap" component={roadMap} />
        <Route exact path="/disclaimer" component={Disclaimer} />
      </Router>
    )
  }
}

export default App
