import React, {Component} from 'react';
import Sidebar from "../cabinet/sidebar";
import TopBar from "./topBar"
import '../public/css/account-custom.css';
import './tokensale.css';
import { IoIosCheckmarkCircle } from "react-icons/io";
import Coin from "../public/img/coin_logo.png";
import loader from "../public/img/loader_dots.gif";



class paymentSuccess extends Component {

    componentDidMount() {
        let current=this;
        if(!localStorage.getItem('authorization')){
            current.props.history.push("/");
        }

         setTimeout(function(){ current.props.history.push("/cabinet/tokensale"); }, 2000);

    }


    render() {
        return (
            <div className="inner_main">
                
                <TopBar/>
                <div className="page">  
    <div className="user_details_col">
            <div className="container-fluid">
                                   
                        <div className="media">
                                <span className="short_name mr-3">
                                  <img src={Coin} />
                                </span>
                                <div className="media-body">
                                  {/* <h5 className="mt-0 mb-0">{balance.userId}</h5>
                                  {balance.userDepositAddress}
                                  <i className="mdi mdi-content-copy"></i> */}
                                </div>
                              </div>
                
            </div>
        </div>
                <section className="statistics payment_success">
                    <div className="overlay"></div>
                    <div className="" id="activeCurrency" data-rate="5412.16477899" data-symbol="BTC">
                        <div className="wrap">
                            <div className="box" style={{    width: '530px', margin: 'auto'}}>


                                <div className={'payment-box'} style={{textAlign:'center'}}>
                                    <IoIosCheckmarkCircle style={{fontSize:'3em', color:'white', marginTop:'5px'}} />
                                    <h1 style={{marginBottom:'0px',fontSize: '30px', paddingBottom : '20px', paddingTop:'15px'}}>Payment Successful</h1>
                                </div>



                                <div className="row m-0" style={{width:'500px', background:'#6c2474'}}>

                                    <div className="halfCircleRight">

                                    </div>
                                    <div className="" style={{width:'460px', background:'#6c2474', paddingTop: '20px'}}>
                                    <hr className={'hrpymnt'} />
                                    </div>
                                    <div className="halfCircleLeft">

                                    </div>
                                </div>
                                <div className={'payment-box-opp'} style={{textAlign:'center'}}>
                                    <h1>Amount Paid</h1>
                                    <h3 style={{paddingBottom : '40px', color:'#f9c6ff'}}>Currency Update : HTLC</h3>
                                </div>




                            </div>
                            <img className="loader_img" src={loader}/>
                        </div>
                    </div>

                </section>
                </div>
            </div>
        
        );
    }
}

export default paymentSuccess;
