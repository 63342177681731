import React, { Component } from 'react';
import Sidebar from "../cabinet/sidebar";
import TopBar from "./topBar"
import '../public/css/account.css';
import '../public/css/account-custom.css';
import './tokensale.css';
import ChangePassword from './changePassword'
import axios from 'axios';

class ProfileEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const header = {
            headers: {
                "Authorization" : "JWT " + localStorage.getItem('token')
            }
        }
          axios.post('https://hoteliercoins.co/api/v1/profile/update', {name: this.state.name}, header)
            .then(function (response) {
            })
            .catch(function (error) {
            });

        this.props.history.push("/cabinet/profile");


    }

    componentDidMount(){
        let current=this;
        if(!localStorage.getItem('token')){
            current.props.history.push("/cabinet/profile");
        }
    }
    render() {
        return (
            <div>
                <Sidebar/>
                <TopBar />


                <div style={{background:'white', width: '85%', float: 'right', padding:'40px'}}>
                    <div style={{display: 'flex'}}>

                        <div className="main_block faq main-block-custom" style={{width:'60%'}}>

                            <div className="wrap" style={{padding:'50px'}}>

                                <div className="table_wrap" tabIndex="1" style={{overflow: 'hidden', outline: 'none'}}>
                                    <form name="app_user_type" method="post" className="form-placeholder-custom" onSubmit={this.handleSubmit}>
                                        <div className="field-wrap" style={{margin:'0px'}}>
                                            <input type="text" id="app_user_type_firstName" name="name"  placeholder="Name" className="input-custom" value={this.state.name} onChange={this.handleChange} />
                                        </div>
                                        <div className="field-wrap" style={{margin:'0px'}}>
                                            <input type="text" id="app_user_type_firstName" name="email" placeholder="Email" className="input-custom" onChange={this.handleChange} />
                                        </div>
                                        {/* <div className="field-wrap" style={{margin:'0px'}}>
                                            <input type="text" id="app_user_type_email" name="app_user_type[email]" required="required" placeholder="Mobile" className="input-custom" value={'9878470358'} />
                                        </div> */}

                                        <div className="field-wrap" style={{margin:'0px'}}>
                                            <button href="#" className="btn" style={{ width: '100%', marginTop: '10px', padding: '15px', borderRadius: '5px'}}>SAVE INFORMATION</button>
                                        </div>
                                    </form>



                                </div>
                            </div>

                        </div>


                      <ChangePassword/>

                      
                    </div>

                </div>


                <div className="auth_wrap">
                    <p className="auth_desc content-font-color-custom">
                        Please strengthen the security of your account by adding 2FA.
                    </p>

                    <p className="auth_desc content-font-color-custom">
                        Please be careful while linking 2FA to your device. Once it is all set, the changes could be
                        done in the app or extension only. Please note that you need to confirm this action by entering
                        the code that will come to your e-mail.

                    </p>
                    <p className="auth_desc content-font-color-custom">
                        Install Google Authenticator to your device :

                    </p>
                    <div className="link_wrap">
                        <a href="http://itunes.apple.com/app/google-authenticator/id388497605?mt=8" target="_blank"
                           rel="noreferrer nofollow noopener" className="apple">

                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                           target="_blank" rel="noreferrer nofollow noopener" className="android">
                        </a>
                    </div>

                </div>


                <div style={{background:'white', width: '85%', float: 'right', padding:'40px', paddingTop:'0px'}}>
                    <div style={{display: 'flex'}}>

                        <div className="main_block faq main-block-custom" style={{width:'100%'}}>

                            <div className="wrap" style={{padding:'50px'}}>

                                <p className="auth_desc content-font-color-custom">Please strengthen the security of your account by adding 2FA.</p>

                                <p className="auth_desc content-font-color-custom">Please be careful while linking 2FA to your device. Once it is all set, the changes could be done in the app or extension only. Please note that you need to confirm this action by entering the code that will come to your e-mail.</p>

                                <p className="auth_desc content-font-color-custom">Install Google Authenticator to your device :</p>
                            </div>

                        </div>
                    </div>

                </div>



            </div>
        );
    }
}

export default ProfileEdit;
