import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
class emailVerify extends Component {

    constructor(props) {

        super(props);

        this.state = {
           
            
        };
       
    }

    componentDidMount () {
        const { id } = this.props.match.params;
        //console.log(id);
        axios.post('https://hoteliercoins.co/api/v1/emailconfirmation', {token: id})
            .then( res => {
                if(res.data.status) {
                    //console.log(res, "IF")
                    // this.setState({token : response.data.data.token}); 
                    toastr.success('Email verification successfully!');
                    this.props.history.push("/login");  

                             
                }
                else {
                    toastr.error(res.data.message);
                    this.props.history.push("/");
                    //console.log(res,"ELSE");
                  
                   
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    
  
    render() {
       
        return (
            <div id="large-header" className=""> 
           
             </div>
             
         
        );
    }
}

export default emailVerify;



