import React, { Component } from 'react';
import Sidebar from "../cabinet/sidebar";
import TopBar from "./topBar"
import account from '../public/css/account.css';
import '../public/css/account-custom.css';
import './tokensale.css';
import { Link } from 'react-router-dom';
import ChangePassword from './changePassword'
import Coin from "../public/img/coin_logo.png";
import axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import $ from "jquery";
class Profile extends Component {

    constructor(props){
        super(props)
        
        this.state = {
            profile : [],
            show : true,
            showEmail:true,
            request_id:localStorage.getItem('request_id'),
            otp:'',
            old_password:'',
            new_password:'',
            email:''     
           

        }
        this.contactData={
            countryCode:'',
            phone:''
        };

        this.otpData= {
            request_id:localStorage.getItem('request_id'),
            otp:'',
        }

       
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onEmailSubmit = this.onEmailSubmit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);        
        this.handlePasswordChangeSubmit = this.handlePasswordChangeSubmit.bind(this);
    }
    onEmailSubmit(){
        this.setState({showEmail:false});
        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }
        const {email} = this.state.profile;
        const emailData=email;
        axios.post('https://hoteliercoins.co/api/v1/email/resend',{email:emailData},{headers: header.headers})
        .then(res=>{
            //console.log(res);
            if(res.data.status == true){
               // localStorage.setItem('request_id',res.data.data.request_id);
               // //console.log("request_id"+res.data.data.request_id);
                toastr.success('Email verification instructions sent successfully!');
            }
            else if(res.data.message == "jwt expired"){
                localStorage.clear();
                this.props.history.push('/');
            }
            else{
                //console.log("error");
                //ToastsStore.info(res.data.message);
                toastr.error(res.data.message)
            }
            
           
        })
    }
    onSubmit(){
       
         this.setState({show:false});
        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }
        
        axios.post('https://hoteliercoins.co/api/v1/otp/resend',this.contactData,{headers: header.headers})
        .then(res=>{
            //console.log(res);
            if(res.data.status == true){
                localStorage.setItem('request_id',res.data.data.request_id);
                //console.log("request_id"+res.data.data.request_id);
                toastr.success('OTP sent successfully!');
            }
            else if(res.data.message == "jwt expired"){
                localStorage.clear();
                this.props.history.push('/');
            }
            else{
                //console.log("error");
                //ToastsStore.info(res.data.message);
                toastr.error(res.data.message)
            }
            
           
        })
    }
    handleChange(e) {
        const { name, value } = e.target;
        // this.otpData={
        //     request_id:localStorage.getItem('request_id'),
        //     otp:value
        // }
        this.setState({ [name]: value });
        //console.log(this.state)
    }
    handleEmailSubmit(e){
        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }
        const {otp} = this.state;
        const data ={
            request_id:localStorage.getItem('request_id'),
            otp
        }
        //console.log(data)
        e.preventDefault();       
        axios.post('https://hoteliercoins.co/api/v1/verify-phone', data,{headers: header.headers})
            .then( res => {
                if(res.data.status) {
                    //console.log(res, "IF")
                    
                    toastr.success('Email verification successfully!'); 
                    window.location.reload();
                }
                else if(res.data.message == "jwt expired"){
                    localStorage.clear();
                    this.props.history.push('/');
                }
                else {
                    //console.log(res,"ELSE");

                    toastr.error(res.data.message);
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
    }
    handleSubmit(e) {
        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }
        const {otp} = this.state;
        const data ={
            request_id:localStorage.getItem('request_id'),
            otp
        }
        //console.log(data)
        e.preventDefault();       
        axios.post('https://hoteliercoins.co/api/v1/verify-phone', data,{headers: header.headers})
            .then( res => {
                if(res.data.status) {
                    //console.log(res, "IF")
                    
                    toastr.success('Phone verification successfully!'); 
                    window.location.reload();
                }
                else if(res.data.message == "jwt expired"){
                    localStorage.clear();
                    this.props.history.push('/');
                }
                else {
                    //console.log(res,"ELSE");

                    toastr.error(res.data.message);
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
    }
    handlePasswordChangeSubmit(e){
        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }
        const {old_password,new_password} = this.state;
        const dataPassword ={
            old_password,
            new_password
        }
        //console.log(dataPassword);
        e.preventDefault();       
        axios.post('https://hoteliercoins.co/api/v1/profile/changepassword', dataPassword,{headers: header.headers})
            .then( res => {
                if(res.data.status) {
                    //console.log(res, "IF")
                    
                    toastr.success('Your Password was successfully changed');   
                    window.location.reload();
                }
                else if(res.data.message == "jwt expired"){
                    localStorage.clear();
                    this.props.history.push('/');
                }
                else {
                    //console.log(res,"ELSE");

                    toastr.error(res.data.message);
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
    }
    componentDidMount(){
        localStorage.removeItem('request_id');
        let current=this;
        if(!localStorage.getItem('authorization')){
            current.props.history.push("/login");
        }


        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }


        axios.get('https://hoteliercoins.co/api/v1/userInfo/profile',{headers:header.headers})
            .then(res => {
                if(res.data.status){
                    //console.log(res.data.data);
                
                    this.setState({profile: res.data.data});
                    this.contactData={
                        countryCode:res.data.data.countryCode,
                        phone:res.data.data.phone
                    }
                    //console.log(this.contactData)
                }
                else if(res.data.message == "jwt expired"){
                    localStorage.clear();
                    this.props.history.push('/');
                }
                else{
                    toastr.error(res.data.message)
                }
               
               
            })
            .catch(function (error) {
            });

    }

    render() {
        const isEmailVerified = this.state.profile.isEmailVerified;
        const isPhoneVerified = this.state.profile.isPhoneVerified;
        let statusEmail, mobileStatus;
        //console.log(isEmailVerified)
        if (isEmailVerified) {
            statusEmail =  <span className="text-success"><i className="mdi mdi-shield-check"></i></span>
            $(".email_resend").hide();
          }
           else {
            statusEmail =  <span className="text-danger">Not verified</span>
          }
          if (isPhoneVerified) {
            mobileStatus =  <span className="text-success"><i className="mdi mdi-shield-check"></i></span>
            $(".mobile_resend").hide();
          } else {
            mobileStatus =  <span className="text-danger">Not verified</span>
          }
        return (
            <div className="inner_main profile_main_i">
               
                <TopBar />
                <div className="user_details_col token_sale_user_details">
                                   
                        <div className="media">
                                <span className="short_name mr-3">
                                  <img src={Coin} />
                                </span>
                                <div className="media-body">
                                   <h5 className="mt-0 mb-0">{this.state.profile.email}</h5> 
                                  {localStorage.getItem('userDepositAddress')}
                                  <i className="mdi mdi-content-copy"></i>
                                </div>
                          </div>

                         <div className="htlc_balance_top d-none" >
                          <div className="row">
                          <div class="col-md-4">
                            <div class="wrapper count-title d-flex">
                             
                              <div class="name"><strong class="text-uppercase">Main Balance</strong>
                                {/* <div class="count-number">{ balance.userBalance && balance.userBalance  > 0 ? (balance.userBalance).toFixed(2)  : 0 } HTLC (~ { balance.userBalance && balance.userBalance ? (balance.userBalance*1.2).toFixed(2) : 0 } USD)</div> */}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="wrapper count-title d-flex">
                              
                              <div class="name"><strong class="text-uppercase">Escrow Balance</strong>
                              {/* <div class="count-number">{ balance.userBalanceEscrow && balance.userBalanceEscrow  > 0 ? (balance.userBalanceEscrow).toFixed(2)  : 0 } HTLC (~ { balance.userBalanceEscrow && balance.userBalanceEscrow ? (balance.userBalanceEscrow*1.2).toFixed(2) : 0 } USD)</div> */}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="wrapper count-title d-flex">
                             
                              <div class="name"><strong class="text-uppercase">Locked Balance</strong>
                                {/* <div class="count-number">{ balance.lockedBalance && balance.lockedBalance  > 0 ? (balance.lockedBalance).toFixed(2)  : 0 } HTLC (~ { balance.lockedBalance && balance.lockedBalance ? (balance.lockedBalance*1.2).toFixed(2) : 0 } USD)</div> */}
                              </div>
                            </div>
                          </div>
                          </div>
                         </div>
                
           
        </div>
                <div className="page">  
   
               
                 <section className="statistics">
        <div className="container-fluid">
         
          <div className="row ">
                <div className="col-lg-6">
                       
                        <div className="card">
                              <div className="card-header d-flex align-items-center">
                                <h4>Personal Details</h4>
                              </div>
                              <div className="card-body">
                                
                              <table className="table">
                                        <tr>
                                            <td>Name</td>
                                            <td style={{textTransform: 'capitalize'}}>{this.state.profile.name}</td>
                                        </tr>                                       
                                            <tr>
                                            <td>Email</td>
                                            <td className="d-flex justify-content-between">{this.state.profile.email}
                                            <div className="email_status_main">
                                           {statusEmail}
                                            <a className="ml-2 text-primary email_resend" onClick={this.onEmailSubmit} href="javascript:void(0)">
                                                Resend
                                            </a>
                                            </div>
                                               
                                            </td>
                                        </tr>
                                        
                                       
                                        { this.state.show ? <tr>
                                            <td >Mobile</td>
                                            <td className="d-flex justify-content-between">+{this.state.profile.countryCode+this.state.profile.phone}
                                             <div className="email_status_main">
                                           {mobileStatus}
                                            <a className="ml-2 text-primary mobile_resend"  onClick={this.onSubmit} href="javascript:void(0)">
                                                Resend
                                            </a>
                                            </div> </td>
                                        </tr> 
                                        : <tr>
                                            <td>OTP</td>
                                            <td className="d-flex justify-content-between">
                                                <form onSubmit={this.handleSubmit}>
                                                <div class="input-group mb-3">
                                                <input type="text" class="form-control" name="otp" onChange={this.handleChange} placeholder="Enter OTP"  />
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" type="button" id="button-addon2" onClick={this.handleSubmit}>Submit</button>
                                                </div>
                                                </div>
                                                </form>
                                            
                                            </td>
                                        </tr>
                                    }
                                       
                                    </table>
                              </div>
                            </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                      <div className="card">
                              <div className="card-header ">
                                <h4>KYC</h4>
                              </div>
                              <div className="card-body d-flex align-items-center justify-content-center">
                                  No Documents
                             </div>
                            </div>
                           
                           
                          </div>
          </div>
          <div className="row mt-3">
              <div className="col-md-6">
              <div className="card">
                  <div className="card-body">
                  <form  >
                      <div className="form-group">
                          <label>Old Password</label>
                          <input className="form-control" name="old_password"
                                       required="required" onChange={this.handleChange} autoComplete="current-password" placeholder="Enter your old password" />
                      </div>
                      <div className="form-group">
                          <label>New Password</label>
                          <input className="form-control" name="new_password"
                                       required="required" onChange={this.handleChange} autoComplete="new-password" placeholder="Enter your new password" />
                      </div>
                      <div className="form-group">
                          <label>Confirm New Password</label>
                          <input className="form-control" name="confirm_new_password" required="required" autoComplete="new-password"  placeholder="Confirm new password" />
                      </div>
                      
                      <div className="form-group">
                          <button type="button" onClick={this.handlePasswordChangeSubmit} className="btn btn-primary">Submit</button>
                      </div>
                  </form>
                  </div>
                  
                </div>
              </div>
          </div>
        </div>
      </section>
      </div>

            </div>
        );
    }
}

export default Profile;
