import React from "react"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import Plebiscite from "./public/landing/img/partners/plebiscite.png"
import Sragy from "./public/landing/img/partners/sragy.jpeg"
import RMS from "./public/landing/img/partners/rms.png"
import Momentum from "./public/landing/img/partners/momentum.png"
import TIcon from "./public/landing/img/icons/t_logo.PNG"
import CoinIcon from "./public/landing/img/icons/coin.png"
import Fintech from "./public/landing/img/icons/Fintech.png"
import Tokenizing from "./public/landing/img/icons/tokenizing.png"
import Travel from "./public/landing/img/icons/Travel.png"
import Triptock from "./public/landing/img/icons/Triptock.png"
import Icon1 from "./public/landing/img/icons/icon_1.png"
import Home1 from "./public/img/home_1.PNG"
import Home2 from "./public/img/home_2.PNG"
import Boom from "./public/img/boom.jpg"
import Banner from "./public/landing/img/banner/banner_crypto.png"
import Banner1 from "./public/landing/img/banner/banner_how.jpg"
import GraphImg from "./public/landing/img/Graph.png"

import $ from "jquery"

window.jQuery = $

class StrategicPartners extends React.Component {
  responsive = {
    0: { items: 1 },
    600: { items: 1 },
    960: { items: 3 },
  }

  stagePadding = {
    paddingLeft: 30,
    paddingRight: 30,
  }

  componentDidMount() {
    // partners info

    $("click", ".read_more", function (e) {
      e.preventDefault()

      let $partner = $(this).parent()
      $(this).toggleClass("opened")
      if ($(this).hasClass("opened")) {
        $(this).text("Show less")
        $($partner).find(".partner-description").css({
          height: "auto",
        })
      } else {
        $(this).text("Show more...")
        $($partner).find(".partner-description").css({
          height: "3.1em",
        })
      }
    })
  }

  render() {
    return (
      <section id="partners" className="h_t_l_m_sec">
        <div className="content" style={{ paddingTop: "0px" }}>
          {/* <AliceCarousel duration={2000} buttonsDisabled={true} autoPlay={true}  mouseDragEnabled={true} onSlideChanged={console.debug} infinite={true} dotsDisabled={true} showSlideInfo={false} responsive={this.responsive} stagePadding={this.stagePadding}>
                        <div className="item">
                            <div className="partner-wrap">
                                <div className="partner-div">
                                    <a href="https://www.rmscloud.com/about-us/our-partners/"
                                       target="_blank" className="partner">
                                        <img src={RMS} alt="rms cloud" style={{width: '160px', height: '40px', marginTop: '20px',marginBottom: '10px'}} />
                                    </a>
                                    <div className="partner-description">
                                        <p>
                                            RMS, The Hospitality Cloud, is a world-renowned software company
                                            headquartered in Australia.
                                            RMS specializes in producing and supporting Online Booking,
                                            Channel Management and Front Office
                                            Systems to the world’s hospitality industry.
                                        </p>
                                        <p>
                                            RMS has been at the forefront of property and reservations
                                            management systems development for
                                            over 30 years for the Holiday Park, Caravan and RV Park, Hotel,
                                            Motel and Apartment segments with
                                            specialty applications for the Mining, Defense, Facility and
                                            Shopping Center industries.
                                        </p>
                                        <p>
                                            The RMS Property Management System offers the unique ability to
                                            provide a real-time online booking
                                            functionality, enabling the collection of bookings from a
                                            property’s website, global distribution
                                            system and an expansive variety of third-party travel websites.
                                        </p>
                                        <p>
                                            Currently, RMS supports over 6,000 properties across 30
                                            countries, enabling them to complete online
                                            bookings, maintain channel management and operate a front office
                                            system in a single application.
                                        </p>
                                    </div>
                                    <a className="read_more" href="https://www.rmscloud.com" target="_blank" style={{fontWeight: 'bolder'}}>Know more...</a>
                                </div>
                            </div>

                        </div>
                        <div className="item">
                            <div className="partner-wrap">
                                <div className="partner-div">
                                    <a href="https://momentumchannel.com/"
                                       target="_blank" className="partner">
                                        <img src={Momentum} alt="Momentum" style={{height: '130px', marginTop: '-20px',marginBottom: '-20px'}} />
                                    </a>
                                    <div className="partner-description">
                                        <p>
                                        Momentum Channel make an impression! Millions around the world have seen our Television shows, flipped through one of the many of the thousands of lifestyles, fashion, celebrity and sports articles we have published, watched one of the movies we helped produce around the world, or engaged with one of the celebrities and social media stars we work with.
                                        </p>                                       
                                       
                                    </div>
                                    <a className="read_more" href="https://www.rmscloud.com" target="_blank" style={{fontWeight: 'bolder'}}>Know more...</a>
                                </div>
                            </div>

                        </div>
                    </AliceCarousel> */}

          {/* <img src={Home1} style={{ width: "100%" }} />

          <img className="mt-3" src={Home2} style={{ width: "100%" }} /> */}

          <div className="one_travel_wrap boom_wrap">
            <h2 className="section_title">
              A Holistic Travel Loyalty Marketplace
            </h2>
            <div className="row">
              <div className="col-md-6">
                <div className="c_img_content">
                  <img src={TIcon} />

                  <ul>
                    <li>
                      <strong>Travelers Club</strong>
                    </li>
                    <li>A Holistic Loyalty Program</li>
                    <li>Digital Identity in Blockchain</li>
                    <li>AI-Powered Travelers Preference Management</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="c_img_content">
                  <img src={Tokenizing} />

                  <ul>
                    <li>
                      <strong>Tokenize Travel Marketplace</strong>
                    </li>
                    <li>Travel Commodity Trading Platform</li>

                    <li>Leisure Services & Room Night Token Exchange</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="c_img_content">
                  <img src={Fintech} />

                  <ul>
                    <li>
                      <strong>Fintech & Digital Banking</strong>
                    </li>
                    <li>For boutique Hotel, Vacation Homeowners /</li>
                    <li>travel Agency Owners & Individual Banking –</li>
                    <li>Travelers & Leisure Service Providers</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="c_img_content">
                  <img src={CoinIcon} />

                  <ul>
                    <li>
                      <strong>Cryptocurrency</strong>
                    </li>
                    <li>Gold-Backed Travel Payment Currency</li>
                    <li>Hoteliercoin – The Travel Reward Currency, Pegged</li>
                    <li>With Demand Of The Marketplace</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="c_img_content">
                  <img src={Triptock} />

                  <ul>
                    <li>
                      <strong>Travel Social Reward Platform</strong>
                    </li>
                    <li>Verified Review, Rating & Travel Posts</li>

                    <li>
                      Data Is Connected With Hash For Data Ownership, &
                      Monetization – It Gamifies The Travel.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="c_img_content">
                  <img src={Travel} />

                  <ul>
                    <li>
                      <strong>
                        Hospitality Supplier’s Marketplace & Human Resource
                        Management System
                      </strong>
                    </li>
                    {/* <li>Human Resource Management System</li> */}
                  </ul>
                </div>
              </div>
            </div>
            <h2 className="section_title">NEXT-GENERATION TRAVELER'S CLUB</h2>
            <div className="row">
              <div className="col-md-6">
                <p style={{ textAlign: "justify" }}>
                  New-age loyalty is about creating a traveler-centric, a
                  holistic travel booking, rewarding & social infrastructure,
                  where everyone wins. The nucleus of the ecosystem is the
                  traveler - from owning and monetizing the data to access
                  wholesale rates while celebrating the personalized recognition
                  irrespective of brand or non-branded hotels/homes.
                </p>
                <br />
                <h5 style={{ textAlign: "justify" }}>
                  ONE blockchain-backed database for the entire hospitality &
                  travel infrastructure.
                </h5>
              </div>
              <div className="col-md-6">
                <img src={GraphImg} className="graph_img_xx" />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default StrategicPartners
