import React from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import './book.css';


class Book extends React.Component {

    render() {

        return (
            <section className="content book" style={{marginBottom:'0px'}}>
                <div className={'book'} style={{width: '100%', height: '100px', background: '#1a092e'}}>

                    <div className={'book'} style={{display: 'flex', padding: '12px 5px 5px 5px'}}>
                        <div className={'book'} style={{width: '30%'}}>
                            <img src="https://www.hoteliermart.com/static/image/logo.svg" width="150" />
                            <input type="text" name="location" id="location" className="input" placeholder="Enter a City, Location or Hotel" style={{width: '100%'}} autoComplete="off" />
                        </div>
                        <div style={{width: '20%', margin: '0px 5px'}}>
                            <div>
                                <label>Trip Type</label>
                            </div>
                            <div style={{textAlign: 'right', padding:'0px 10px'}}>
                                <select name="traveling_type" style={{height: '40px', width: '100%', border: '0px', color: 'gray'}}>
                                    <option value="1">Leisure</option>
                                    <option value="2">Work</option>
                                    <option value="3">Family</option>
                                </select>
                            </div>
                        </div>


                        <div style={{width: '20%', padding:'0px 10px'}}>
                            <label htmlFor="checkin">Check In - Check out</label>
                            <input type="text" className="input" name="daterange" placeholder="Enter Dates" id="checkin" style={{width: '100%'}} />
                        </div>

                        <div style={{width: '20%', padding:'0px 10px'}}>
                            <label htmlFor="rooms">Adult/Child &amp; Rooms</label>
                            <input type="text" className="input" id="rooms" placeholder="1 room, 1 adult" style={{width: '100%'}} />
                        </div>

                        <div style={{ width: '15%',padding: '0px 5px', paddingTop: '15px', fontSize: '12px', textAlign: 'center'}}>
                            <input type="button" className="btn" style={{marginTop: '5px', width: '80%', padding: '0px', borderRadius: '5px', fontSize: '12px'}} value="Search" />
                        </div>

                    </div>

                    <div style={{ padding: '0px 15px'}}>
                        <span style={{float: 'right', marginTop: '-5px'}}><a href="https://www.hoteliermart.com/terms/" target="_blank" style={{color: 'white', textDecoration: 'none', fontSize: '8px'}}>Terms and Conditions*</a></span>
                    </div>
                </div>
            </section>
        )
    }
}

export default Book;

