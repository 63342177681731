import React, { useEffect } from "react"
import Platfrom from "./public/landing/img/platformnew.png"
import $ from "jquery"
import Navbar from "./includes/navbar"
import Footer from "./includes/footer"

const HowItWork = () => {
  useEffect(() => {
    $(".faq_list li").on("click", function () {
      $(this).toggleClass("active")
      $(this).find(".hint").slideToggle(200)
    })
  }, [])
  return (
    <>
      <header
        style={{
          backgroundImage: "none",
          minHeight: "auto",
          marginBottom: "0",
        }}
      >
        <Navbar />
      </header>

      <section className="content how_it_work_sec" id="platform">
        {/* <h2
          className="section_header section_header_line"
          style={{ marginTop: "0px", textAlign: "left" }}
        >
          How does it work?
        </h2> */}
        <img src={Platfrom} alt="platform" />
      </section>
      <div className="home_banner_bea">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="vimeo-wrapper token_sale_wrapper">
                <iframe
                  src="https://player.vimeo.com/video/356599089?background=1&autoplay=1&loop=1&byline=0&title=0"
                  frameborder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                ></iframe>
                <div className="video_content_banner">
                  <h5>Book Hotels at Travolier.com</h5>
                  <a
                    className="btn btn-primary book_now_token_sale"
                    href="https://travolier.com/join"
                    target="_blank"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default HowItWork
