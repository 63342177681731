import React, { Component } from 'react';
import '../public/css/login-custom.css';
import '../public/css/auth.css';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Logo from '../public/img/hotelier_logo.svg';
import Eye from "../public/img/eye.svg";
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-google';
import { ToastsContainer, ToastsStore } from "react-toasts";
import  codes from '../codes.json'
class Register extends Component {

    componentDidMount() {
        loadReCaptcha();
    }

    constructor(props) {

        super(props);

        this.state = {

            first: 'NA',
            last:'NA',
            password: 'NA',
            cPassword: '',
            phone:'',
            countryCode:'',
            email: '',
            accept: '',
            submitted: false,
            loading: false,
            error: '',
            tempid: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        //console.log(this.state)
    }

    handleSubmit(e) {
        e.preventDefault();

        const {password, cPassword} = this.state

        // perform all neccassary validations
        if (password !== cPassword) {
            ToastsStore.error("Password and Confirm Password did not Match");
            return
        }

        this.setState({ submitted: true });
        this.setState({ loading: true });
        let abcd=this;
        axios.post('https://hoteliercoins.co/api/v1/register', this.state)
            .then( response => {
                if(response.data.status){
                    //console.log(response, "IF")
                    this.setState({tempid : response.data.data.tempid})
                    localStorage.setItem("tempid", this.state.tempid );
                    localStorage.setItem("phone", this.state.phone);
                    localStorage.setItem("countrycode",this.state.countryCode)
                    ToastsStore.success("OTP Successfully Sent");
                    setTimeout(function(){
                        abcd.props.history.push("/verify-mobile");
                    }, 3000)
                }
                else {
                    //console.log(response, "ELSE")
                    ToastsStore.info(response.data.message);
                }

            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    render() {
        //console.log(this.state)
        return (
            <div id="large-header" className="large-header login-background-custom">
                <div className="wrap">
                    <div className="logo" style={{marginTop:'100px'}}><a href="/"><img src={Logo} alt="logo" /></a></div>
                    <div className="form main-block-custom-login">
                        <div className="form_content">
                            <h1 className="register_page login-title-color-custom">Welcome to Hotelier Coin!</h1>
                            <p className="welcome_message register_page content-font-color-custom-login">Please fill out the fields</p>
                            <form name="fos_user_registration_form" method="post" className="form-placeholder-custom" onSubmit={this.handleSubmit}>
                                <div className="field-wrap">
                                    <input type="text" onChange={this.handleChange} name="first" required="required" maxLength="180"
                                           pattern=".{2,}" placeholder="Name" className="input-custom" />
                                </div>
                                <div className="field-wrap">
                                    <input type="email" id="fos_user_registration_form_email" onChange={this.handleChange} name="email" required placeholder="E-mail"
                                           className="input-custom" />
                                </div>



                                <div className="field-wrap">
                                    <div style={{display:'flex'}}>
                                    <select className="form-control isCode" name="countryCode" onChange={this.handleChange}
                                     style={{width:'20%', height:'50px', marginRight:'10px'}}>

                                        {codes.countries.map((code) =>
                                            <option>{code.callingCodes}</option>
                                        )}

                                    </select>

                                    <input type="number" name="phone" required="required" placeholder="Mobile" className="input-custom" style={{width:'80%'}} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="field-wrap field-pass">
                                    <input type="password" onChange={this.handleChange} name="password" required="required" autoComplete="new-password" placeholder="Password" className="input-custom" />
                                    {/*  <div className="show_password show-password-custom">
                                                                                    <img src={Eye} alt="eye" />
                                                                                </div> */}
                                </div>
                                <div className="field-wrap field-pass">
                                    <input onChange={this.handleChange} type="password" name="cPassword" required="required" autoComplete="new-password" placeholder="Confirm password" className="input-custom" />
                                    {/*  <div className="show_password show-password-custom">
                                            <img src={Eye} alt="eye" />
                                        </div> */}
                                </div>
                                <div className="field-wrap field-terms">
                                <ReCaptcha ref={(el) => {this.captchaDemo = el;}}  size="normal" render="explicit" sitekey="6LdVeREUAAAAAKGqsB0k27X_AwVO6j35EZ9QKOEe" onloadCallback={this.onLoadRecaptcha} verifyCallback={this.verifyCallback} />
                                </div>

                                <div className="field-wrap field-terms">
                                    <span style={{color:'white'}}>
                                    <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1" />
                                     By continuing, you confirm that you are over 18 years old, and you agree to our Terms of Use  <a href="/terms" target="_blank" className="terms_conditions_link">terms and conditions</a>
                                    </span>
                                </div>

                                <input type="hidden" id="fos_user_registration_form__token" name="fos_user_registration_form[_token]" value="xLZG-4xBJgi3-EaWbhXmozx3hRglNIekX-P-dHq_-GA" />
                                <button className="button create button-custom" onClick={this.handleSubmit}>Create</button>
                                <div>
                                    <ToastsContainer store={ToastsStore} />
                                </div>
                            </form>
                        </div>
                        <p className="info content-font-color-custom-login">I have an account. <a href="/login" className="underline-custom">Sign in!</a></p>
                    </div>

                </div>
            </div>

        );
    }
}

export default Register;
