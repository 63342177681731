import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css';
import Logo from '../public/img/logo_dark.png';
import loader from "../public/img/loader_dots.gif";
import $ from "jquery";
class ResetPassowrd extends Component {

    constructor(props) {

        super(props);

        this.state = {
                password:''
                };
                this.handleChange = this.handleChange.bind(this);
                this.onSubmit = this.onSubmit.bind(this);
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    onSubmit(){
        const { id } = this.props.match.params;
        //console.log(id);
        //console.log(this.state.password);
        axios.post('https://hoteliercoins.co/api/v1/reset-password', {token: id,password:this.state.password})
            .then( res => {
                if(res.data.status) {
                    //console.log(res, "IF")
                    // this.setState({token : response.data.data.token}); 
                    toastr.success('Email verification success!');
                    this.props.history.push("/login");  

                             
                }
                else {
                    toastr.error(res.data.message);
                   // this.props.history.push("/");
                    //console.log(res,"ELSE");
                  
                   
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    componentDidMount () {
        var materialInputs = $('input.input-material');

        // activate labels for prefilled values
        materialInputs.filter(function () {
            return $(this).val() !== "";
        }).siblings('.label-material').addClass('active');
    
        // move label on focus
        materialInputs.on('focus', function () {
            $(this).siblings('.label-material').addClass('active');
        });
    
        // remove/keep label on blur
        materialInputs.on('blur', function () {
            $(this).siblings('.label-material').removeClass('active');
    
            if ($(this).val() !== '') {
                $(this).siblings('.label-material').addClass('active');
            } else {
                $(this).siblings('.label-material').removeClass('active');
            }
        });
    }

    
  
    render() {
       
        return (
            <div id="large-header" className=""> 
            <div className="page login-page">
 <div className="">
 <div className="vimeo-wrapper">             
             <iframe src="https://player.vimeo.com/video/355503337?background=1&autoplay=1&loop=1&byline=0&title=0"
                     frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
         </div>
   <div className="form-outer text-center d-flex align-items-center">
     <div className="form-inner">
       <div className="logo text-uppercase">
           <a href="/">
         <img src={Logo}/>  </a>  
       </div>
     
       {/* <p>Welcome to Hotelier Coin dashboard</p> */}
       <form method="get" className="text-left form-validate" autoComplete="hide" >        
       <p class="mt-3 mb-2">Enter your new password</p>
         <div className="form-group-material">
           <input id="login-password" type="password" name="password" required="required"   onChange={this.handleChange} className="input-material"/>
           <label for="login-password" className="label-material">Enter password</label>
         </div>
         <div className="form-group-material">
           <input id="login-cpassword" type="password" name="cpassword" required="required"   onChange={this.handleChange} className="input-material"/>
           <label for="login-cpassword" className="label-material">Confirm password</label>
         </div>
         <div className="form-group text-center">
             <button onClick={this.onSubmit} id="login" type="button"  className="btn btn-primary btn-block">
                 <div className="action_loader" style={{display:'none'}}>
                     <img src={loader} />
                 </div>
                 Submit
                 </button>               
         </div>
       </form>
       {/* <a href="javascript:void(0)" className="forgot-pass" onClick={this.handleResend}>Resend OTP</a> */}
     </div>
     
   </div>
 </div>
</div>

          
</div>
             
         
        );
    }
}

export default ResetPassowrd;



