import React, { Component } from 'react';
import Sidebar from "../cabinet/sidebar";
import TopBar from "./topBar"
import '../public/css/account.css';
import '../public/css/account-custom.css';
import './tokensale.css';
import './withdraw.css';
import Coin from "../public/img/coin_logo.png";
import {Link} from "react-router-dom";
import axios from 'axios';

class Withdraw extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tableData: [{
                time: '',
                tokenName: '',
                toWallet: '',
                amount: '',
                links: '',
                txID: '',
            }],
            form: [{
                userId : 'kawaldeepsingh93@gmail.com',
                amount: '',
                tokenName: 'HTL',
                userName: 'kawaldeep',
                toWallet: '',
            }]
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }
        
        axios.post('https://hoteliercoins.co/api/v1/withdrawal/new', this.state.form, header)
        .then(function (response) {
            if(response.data.status === true) {
                 alert("Done");
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
    }

    componentDidMount () {
        let ref=this;
        if(!localStorage.getItem('authorization')){
            ref.props.history.push("/");
        }
        const header = {
            headers: {
                "authorization" : localStorage.getItem('authorization')
            }
        }

        axios.get('https://hoteliercoins.co/api/v1/withdrawal/user', header).then(response => {
                ref.setState({ tableData: response.data });
        });
    }
    render() {

        return (
            <div className="inner_main withdraw_main_i">
                {/* <Sidebar/> */}
                <TopBar />
                <div className="user_details_col token_sale_user_details">
            <div className="container-fluid ">
                                   
                        <div className="media">
                                <span className="short_name mr-3"><img src={Coin} /></span>
                                <div className="media-body">
                                  {/* <h5 className="mt-0 mb-0">{this.state.profile.email}</h5>  */}
                                  {localStorage.getItem('userDepositAddress')} 
                                  <i className="mdi mdi-content-copy"></i>
                                </div>
                              </div>
                
            </div>
        </div>
                <div className="page">  
    
                <section className="statistics">
                    <div className="overlay"></div>
                    <h2 className="c-w-d">Currently withdraw is disabled</h2>
        <div className="container-fluid">
          <div className="row d-flex">
                <div className="col-lg-6">
                       
                        <div className="card">
                              <div className="card-header d-flex align-items-center">
                                <h4>HTL Withdrawal</h4>
                              </div>
                              <div className="card-body">
                                <p>Minimum withdrawal: 50 HTL</p>
                                <p>Do not withdraw directly to a crowdfund or ICO address, as your account will not be credited with tokens from such sales.</p>
                                <form className="mt-4 mb-4">
                                        <div className="form-group">       
                                  
                                                <input type="text" placeholder="Enter your wallet address" className="form-control"/>
                                              </div>
                                  <div className="form-group">       
                                  
                                    <input type="text" placeholder="Enter amount" className="form-control"/>
                                  </div>
                                  <div className="form-group">       
                                    <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                  </div>
                                </form>
                                <h5>Please note</h5>
                                <p>* Once you have submitted your withdrawal request, we will send a confirmation email. Please then click on the confirmation link in your email.</p>
                                <p>* After making a withdrawal, you can track its progress on the history page.</p>
                                
                              </div>
                            </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                           
                            <div id="new-updates" className="card updates recent-updated">
                              <div id="updates-header" className="card-header d-flex justify-content-between align-items-center">
                                <h4 >History</h4>
                                <a href="#">View All</a>
                              </div>
                              
                              <ul className="news list-unstyled">
                                 
                                 <li className="d-flex justify-content-between"> 
                                   <div className="left-col d-flex">
                                    
                                     <div ><strong>No Transactions </strong>
                                      
                                     </div>
                                   </div>
                                
                                 </li>
                                 </ul>
                              {/* <div id="updates-box" role="tabpanel" >
                                <ul className="news list-unstyled">
                                 
                                  <li className="d-flex justify-content-between"> 
                                    <div className="left-col d-flex">
                                     
                                      <div ><strong>Lorem ipsum dolor sit amet.</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                      </div>
                                    </div>
                                    <div className="right-col text-right">
                                      <div className="update-date">24<span className="month">Jan</span></div>
                                    </div>
                                  </li>
                                 
                                  <li className="d-flex justify-content-between"> 
                                    <div className="left-col d-flex">
                                     
                                      <div ><strong>Lorem ipsum dolor sit amet.</strong>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                      </div>
                                    </div>
                                    <div className="right-col text-right">
                                      <div className="update-date">24<span className="month">Jan</span></div>
                                    </div>
                                  </li>
                                 
                                  <li className="d-flex justify-content-between"> 
                                        <div className="left-col d-flex">
                                         
                                          <div><strong>Lorem ipsum dolor sit amet.</strong>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                          </div>
                                        </div>
                                        <div className="right-col text-right">
                                          <div className="update-date">24<span className="month">Jan</span></div>
                                        </div>
                                      </li>
                                  
                               
                                </ul>
                              </div> */}
                            </div>
                           
                          </div>
           
            
          </div>
         
        </div>
      </section>
      </div>
                {/* <div className="main-div">
                    <div className="main_block calc main-block-custom" style={{marginTop: '40px'}} id="activeCurrency" data-rate="5412.16477899" data-symbol="BTC">
                        <div className="wrap">
                            <div className="box" style={{opacity:'0.3'}}>
                                <div className="row3">
                                    <div className="custom-col6">
                                        <h3>Important</h3>
                                        <ul className={'withnote'}>
                                            <li>Minimum withdrawal: 50 HTL</li>
                                            <li>Do not withdraw directly to a crowdfund or ICO address, as your account will not be credited with tokens from such sales.</li>
                                        </ul>
                                        <br />
                                        <br />
                                        <h3>HTL Withdrawal Address</h3>
                                        <br />
                                        <form onSubmit={this.handleSubmit}>
                                            <input type={'text'} className={'input-custom'} style={{ maxWidth: '100%'}} placeholder={'Enter you wallet address'} onChange={this.handleChange} />
                                            <input type={'text'} className={'input-custom'} style={{ maxWidth: '100%', marginTop: '15px'}} placeholder={'Enter Amount'} onChange={this.handleChange} />
                                            <br />
                                            <input type={'submit'} className={'btn'} style={{ maxWidth: '100%', height: '50px'}} />
                                        </form>
                                        <h3 style={{marginTop:'30px'}}>Please note</h3>
                                        <ul className={'withnote'} style={{marginBottom:'50px'}}>
                                            <li>Once you have submitted your withdrawal request, we will send a confirmation email. Please then click on the confirmation link in your email.</li>
                                            <li>After making a withdrawal, you can track its progress on the history page.</li>
                                        </ul>
                                    </div>
                                    <div className="custom-col6" style={{verticalAlign: 'top',overflowX : 'scroll'}}>
                                        <div className='' style={{height:'400px', width:'100%'}}>
                                            <div style={{width: '100%', background: '#F4F4F4', height: '50px', borderRadius:'5px'}}>
                                                <p style={{fontSize: '16px', fontWeight: 'bold', padding:'15px'}}>History  <span style={{float:'right', color: '#6C2574', fontSize: '12px',     paddingTop: '5px'}}>Your Recent Withdrawals <span style={{ color: '#404040', borderLeft: '2px solid #e0e0e0', padding: '3px 10px',     marginLeft: '5px'}}><Link to={'history'}>View All</Link></span></span></p>
                                            </div>
                                            <div className="table_wrap" tabIndex="1" style={{overflowX: 'scroll', outline: 'none', padding: '20px 10px', width: '100%'}}>
                                                { this.state.tableData.length ?
                                                    <table className="history-undreline-custom dinamic_table">
                                                        <tbody>
                                                        <tr>
                                                            <th className="content-font-color-custom">Date</th>
                                                            <th className="content-font-color-custom">WalletTo</th>
                                                            <th className="content-font-color-custom">Amount</th>
                                                            <th className="content-font-color-custom">TextId</th>
                                                        </tr>
                                                        {
                                                        this.state.tableData.map((item) => {
                                                        return<tr>
                                                        <td> {item.time} </td>
                                                        <td> {item.toWallet} </td>
                                                        <td> {item.amount} </td>
                                                        <td> {item.txID} </td>
                                                        </tr>
                                                        })
                                                        }
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <div id="table_error_message_start"
                                                         className="error_message error-message-custom"
                                                         style={{display:'flex'}}>
                                                        <p>No transactions</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width:'100%', height:'100%', position: 'absolute', top:'300px' }}>
                                <h3 style={{textAlign:'center', color:'black', marginLeft: '-60px'}}>Withdrawals are currently Disabled</h3>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default Withdraw;
