import React from "react"
import "./roadmap.css"
import "./map"
import $ from "jquery"
import Navbar from "./includes/navbar"
import Footer from "./includes/footer"

class roadMap extends React.Component {
  componentDidMount() {
    /* Check the location of each element */
    $(".content").each(function (i) {
      var bottom_of_object = $(this).offset().top + $(this).outerHeight()
      var bottom_of_window = $(window).height()

      if (bottom_of_object > bottom_of_window) {
        $(this).addClass("hidden")
      }
    })
    $(".swiper-button-next").click(function () {
      resizeHeight()
    })
    $(window).resize(function () {
      resizeHeight()
    })
    function resizeHeight() {
      if ($(document).width() <= 767) {
        setTimeout(function () {
          $(".swiper-container").height(
            $(".swiper-slide-active .swiper-slide-content").height() + 30
          )
        }, 1000)
      } else {
        $(".swiper-container").removeAttr("style")
      }
    }
    $(window).scroll(function () {
      /* Check the location of each element hidden */
      $(".hidden").each(function (i) {
        var bottom_of_object = $(this).offset().top + $(this).outerHeight()
        var bottom_of_window = $(window).scrollTop() + $(window).height()

        /* If the object is completely visible in the window, fadeIn it */
        if (bottom_of_window > bottom_of_object) {
          $(this).animate({ opacity: "1" }, 700)
        }
      })
    })
  }

  render() {
    return (
      <>
        <header
          style={{
            backgroundImage: "none",
            minHeight: "auto",
            marginBottom: "0",
          }}
        >
          <Navbar />

          <div className="mobile_menu visible" style={{ display: "none" }}>
            <ul>
              <li>
                <a href="#partners" className="mobile_menu_link">
                  Partners
                </a>
              </li>
              <li>
                <a href="#platform" className="mobile_menu_link">
                  Ecosystem
                </a>
              </li>
              <li>
                <a href="#token" className="mobile_menu_link">
                  HTL Token
                </a>
              </li>
              <li>
                <a href="#team" className="mobile_menu_link">
                  Team
                </a>
              </li>
              <li>
                <a href="/faq" className="mobile_menu_link">
                  FAQ
                </a>
              </li>
              <li>
                <a href="/#Roadmap">Roadmap</a>
              </li>
              {/*<li><a href="pdf/HotelierCoinLP.pdf" target="_blank">Lightpaper</a></li>*/}
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/travolierclub" className="link_btn">
                  Travolier's Club
                </a>
              </li>
              <li>
                <a href="/" className="link_btn">
                  Private Sale
                </a>
              </li>
              {/*<li><a href="#subscribe" className="mobile_menu_link">Subscribe</a></li>*/}
            </ul>
          </div>
        </header>
        <div class="timeline-container content road_map_sec" id="Roadmap">
          <h2 className="section_title">Roadmap</h2>
          <div class="road_main">
            <p className="p1">
              <div className="row">
                <div className="col-md-6">
                  <h5>
                    <strong>Q4</strong>{" "}
                  </h5>
                  TRAVOLIER.com LIVE with 500K Hotels (beta), <br /> Earn
                  HotelierCoin (HTLC) as cashback & pay with HTLC,
                </div>
                <div className="col-md-6">
                  <h5>
                    <strong>Winner</strong>
                  </h5>{" "}
                  Travolution Award, By Travel Weekly U.K., Best Travel
                  Technology Innovation Company
                </div>
              </div>
            </p>
            <p className="p2">
              <div className="row">
                <div className="col-md-6">
                  <h5>
                    <strong>Q1</strong>
                  </h5>{" "}
                  Pay with Crypto – Bitcoin / Ethereum / EOS / Dash
                </div>
                <div className="col-md-6">
                  <h5>
                    <strong>Q2</strong>
                  </h5>{" "}
                  700+ Airline, including Low-Cost Carrier
                </div>

                <div className="col-md-6 mt-3">
                  <h5>
                    <strong>Q3</strong>
                  </h5>{" "}
                  250K + Vacation Homes <br /> Extranet for direct contracts
                  with Hotels
                </div>
                <div className="col-md-6 mt-3">
                  <h5>
                    <strong>Q4</strong>
                  </h5>
                  Fintech Wallets / Debit Card
                </div>
              </div>
            </p>
            <p className="p3">
              <div className="row">
                <div className="col-md-6">
                  <h5>
                    <strong>Q1</strong>
                  </h5>
                  Earn HTLC with Travel Posts, Reviews on TRAVOLIER.com
                </div>
                <div className="col-md-6">
                  <h5>
                    <strong>Q2</strong>
                  </h5>
                  Digital Profile on Blockchain
                </div>
                <div className="col-md-6 mt-3">
                  <h5>
                    <strong>Q4</strong>
                  </h5>
                  Multi-branded Loyalty Point Conversion to HotelierCoin
                </div>
              </div>
            </p>
            <p className="p4">
              <div className="row">
                <div className="col-md-6">
                  <h5>
                    <strong>Q1</strong>
                  </h5>
                  World's first HOTEL ROOM EXCHANGE - B2B
                </div>
              </div>
            </p>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default roadMap
