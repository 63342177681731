/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import "./roadmap.css"
import "./map"
import "owl.carousel/dist/assets/owl.carousel.css"
import $ from "jquery"
import Navbar from "./includes/navbar"
import Footer from "./includes/footer"
import cardHome from "./public/landing/img/card_home.png"
import Ravi from "./public/landing/img/team/ravi.png"
import Suraj from "./public/landing/img/team/suraj.jpeg"
import George from "./public/landing/img/team/george.jpeg"
import SaketJoshi from "./public/landing/img/team/SaketJoshi.jpg"
import Rana from "./public/landing/img/team/RanaMukherji.jpg"
import Vipin from "./public/landing/img/team/vipinmahasayan.jpg"
import ChristophGanster from "./public/landing/img/team/ChristophGanster.jpg"
import WinnerAward from "./public/landing/img/award.jpeg"
import John from "./public/landing/img/team/johnbardis.jpg"
import IgorKhmel from "./public/landing/img/team/IgorKhmel.jpg"
import Yateesh from "./public/landing/img/team/yateesh.jpeg"
import jamesPrhodes from "./public/landing/img/team/james.jpeg"
import KateBentley from "./public/landing/img/team/KateBentley.jpg"
import Melida from "./public/landing/img/team/melida.jpg"
import Neha from "./public/landing/img/team/neha.jpeg"
import Melcom from "./public/landing/img/team/malcom.jpeg"
import Kat_Gai from "./public/landing/img/team/KAT.jpg"
import Stefan from "./public/landing/img/team/StefanHanekon.jpg"
import Felix from "./public/landing/img/team/felix.jpeg"
import Taniya from "./public/landing/img/team/taniya.jpg"
import Sameer from "./public/landing/img/team/sameer.jpg"
import Madhavan from "./public/landing/img/team/Madhavan.png"

var jQuery = $
window.jQuery = $

// dynamically require owl
require("owl.carousel")

class OurTeam extends React.Component {
  componentDidMount() {
    $(".team-carousel.owl-carousel").owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      navText: ["", ""],
      dots: false,
      autoplay: false,
      autoplayTimeout: 1500,

      responsive: {
        0: {
          items: 1,
        },
        590: {
          items: 1,
        },
        860: {
          items: 3,
        },
      },
    })

    //popups
    $(".close-popup").on("click", function (e) {
      e.preventDefault()
      $(".popup_wrap").hide()
      $(
        "#member-info-popup .member-popup-left, #member-info-popup .member-popup-right"
      ).empty()
    })

    $(document).on("mousedown", function (e) {
      if ($(".popup").has(e.target).length === 0) {
        $(".popup_wrap").hide()
        $(
          "#member-info-popup .member-popup-left, #member-info-popup .member-popup-right"
        ).empty()
      }
    })

    //member info popup

    $("#team, #advisors, #founding_advisor, #founding_partners").on(
      "click",
      ".member a.read_more",
      function (e) {
        e.preventDefault()

        let $thisMember = $(this).parent().clone()
        let $thisMemberInfo = $($thisMember).find(".member-info").clone()

        $($thisMember).css("height", "100%").find(".member-info").remove()
        $($thisMember).find(".read_more").remove()

        $("#member-info-popup .member-popup-left").append($thisMember)
        $("#member-info-popup .member-popup-right").append($thisMemberInfo)
        $("#member-info-popup").css("display", "flex")
      }
    )
  }

  render() {
    return (
      <>
        <header
          style={{
            backgroundImage: "none",
            minHeight: "auto",
            marginBottom: "0",
          }}
        >
          <Navbar />
        </header>
        <section className="strip_section thankyou_sec">
          <div class="content">
            <h2
              style={{
                marginTop: "0px",
                textAlign: "left",
                marginBottom: "0",
                marginLeft: 15,
                color: "#212529",
              }}
            >
              Dear Travolier Members,
            </h2>
            <div class="ui grid stackable strip mt-3">
              <div class="ten wide column">
                <p>Thank you for choosing us as your travel partner. </p>
                <p>
                  We reward your loyalty regardless of where you decide to stay.
                  You can even earn HotelierCoin while booking flights, car
                  rentals, and various leisure activities. 
                </p>
                <p>
                  We created our loyalty program to be tailored to you — which
                  is why HotelierCoin can be traded or monetized whenever you
                  choose. Unlike with loyalty points, the valuation is not set
                  by individual hotels or airline brands but is determined
                  by tokenomics and managed by smart contracts. 
                </p>

                <p>
                  New-age loyalty is about creating a traveler's centric - a
                  holistic travel infrastructure, where everyone wins. The core
                  of the ecosystem is the traveler-powered with ONE
                  blockchain-backed database for the entire hospitality & travel
                  infrastructure. 
                </p>
                <p>
                  Our goal is to create a holistic decentralized Travel Market
                  Infrastructure that benefits everyone, from travelers to hotel
                  owners and vendors. With Hoteliercoin, our mission is to give
                  power back to the people & businesses that fuel the
                  hospitality & travel industry with a transparent, secure
                  ecosystem that benefits everyone. 
                </p>
                <p>Welcome to the future of travel and loyalty!</p>
                <p className="rana_p">
                  <img src={Rana} />
                  Rana Mukherji <br /> CEO & Founder
                </p>
              </div>
              <div class="six wide column card-col">
                <img src={cardHome} alt="hotelier mart logo" />
              </div>
            </div>
          </div>
        </section>
        <section id="team">
          <div className="content">
            <h2 className="section_title">Our team</h2>
            <div className="team inner owl-carousel team-carousel owl-loaded owl-drag">
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transform: "translate3d(-2874px, 0px, 0px)",
                    transition: "all 0s ease 0s",
                    width: "11140px",
                  }}
                >
                  {/*<div className="owl-item cloned" style={{width: '349.333px',  marginRight: '10px'}}>*/}
                  {/*<div className="member-wrap">*/}
                  {/*<div className="member">*/}
                  {/*<div className="photo">*/}
                  {/*<img src={GloriaChan} alt="Gloria Chan" />*/}
                  {/*</div>*/}
                  {/*<div className="info">*/}
                  {/*<p className="name">Gloria Chan</p>*/}
                  {/*<p className="role">Regional Director- APAC</p>*/}
                  {/*</div>*/}
                  {/*<ul className="socials">*/}
                  {/*<li>*/}
                  {/*<a href="https://www.linkedin.com/in/gloria-chan-7b6b12ab/"*/}
                  {/*target="_blank" className="linkedin"></a>*/}
                  {/*</li>*/}
                  {/*</ul>*/}
                  {/*<div className="member-info">*/}
                  {/*<p>*/}
                  {/*Gloria Chan’s career revolves around impacting society and shaping*/}
                  {/*the future through technology.*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*Whether it’s representing Microsoft to drive digital transformation*/}
                  {/*working with brands such as Rosewood Hotel Group and Shangri-La*/}
                  {/*Hotels and Resorts or partnering with a leading Hong Kong property*/}
                  {/*developer to consolidate the CRM systems of several shopping malls*/}
                  {/*across China and Hong Kong, Gloria specializes in leveraging the*/}
                  {/*latest technology to create unique experiences and help*/}
                  {/*organizations achieve more across the Asian market.*/}
                  {/*</p>*/}
                  {/*<p>*/}
                  {/*After helping over 50 international projects successfully enter the*/}
                  {/*Asian market, Gloria Chan has become increasingly interested and*/}
                  {/*knowledgeable about the blockchain industry. Now, she combines her*/}
                  {/*innovation-centered mindset and extensive experience across Taiwan,*/}
                  {/*Hong Kong and the Greater China Region to join Hotelier Coin as*/}
                  {/*Regional Director, APAC ready to rethink the hospitality industry,*/}
                  {/*build communities, boost support and maximize Hotelier Coin’s reach.*/}
                  {/*</p>*/}
                  {/*</div>*/}
                  {/*<a className="read_more" href="#">Read more...</a>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*</div>*/}

                  <div
                    className="owl-item active"
                    style={{ width: "349.333px", marginRight: "10px" }}
                  >
                    <div className="member-wrap">
                      <div className="member">
                        <div className="photo">
                          <img src={Rana} alt="Rana Mukherji" />
                        </div>
                        <div className="info">
                          <p className="name">Rana Mukherji</p>
                          <p className="role">Founder &amp; CEO</p>
                        </div>
                        <ul className="socials">
                          <li>
                            <a
                              href="https://www.linkedin.com/in/rana-mukherji-6277703/"
                              target="_blank"
                              className="linkedin"
                            ></a>
                          </li>
                        </ul>
                        <div className="member-info">
                          <p>
                            Rana has been managing, operating and running luxury
                            hotels across the Middle East, India, North America
                            and Africa for nearly 20 years. He has worked with
                            some of the leading brands in the industry,
                            including Marriott, Regent, and Fairmont Raffles
                            Hotels International. Most recently, Rana served as
                            the General Manager Hospitality for IFA Hotel
                            Investments, with an asset value of $2 billion and
                            spearheaded a Bespoke Hotels Middle East &amp;
                            Africa joint venture.
                          </p>
                          <p>
                            Fascinated by the enormous potential that blockchain
                            brings to the hospitality industry, he decided to
                            create Hotelier Coin on a mission to rethink the
                            industry, challenge ineffective processes and find
                            solutions to the plethora of challenges he
                            encountered over the years.
                          </p>
                        </div>
                        <a className="read_more" href="#">
                          Read more...
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item active"
                    style={{ width: "349.333px", marginRight: "10px" }}
                  >
                    <div className="member-wrap">
                      <div className="member">
                        <div className="photo">
                          <img src={Madhavan} alt="B Madhavan " />
                        </div>
                        <div className="info">
                          <p className="name">B Madhavan</p>
                          <p className="role">COO</p>
                        </div>
                        <ul className="socials">
                          <li>
                            <a
                              href="https://www.linkedin.com/in/bmadhavan/"
                              target="_blank"
                              className="linkedin"
                            ></a>
                          </li>
                        </ul>
                        <div className="member-info">
                          <p>
                            Madhavan has been a known face in the travel domain
                            with over two decades of travel and hospitality
                            experience in hotel distribution, air-connectivity,
                            and travel management. Madhavan started his career
                            with the airline - connectivity companies Galileo,
                            the bird group (Lufthansa & star Alliance), to hotel
                            connectivity like Micros, Sabre, IBS to Oracle,
                            including successful travel start-up fastbooking -
                            later sold to Accor Hotels. For the last few years,
                            Madhavan has been incubating and mentoring some
                            successful start-up like Bedlinker, training first,
                            etc.
                          </p>
                        </div>
                        <a href="#" className="read_more">
                          Read more...
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div
                  className="owl-item active"
                  style={{ width: "349.333px", marginRight: "10px" }}
                >
                  <div className="member-wrap">
                    <div className="member">
                      <div className="photo">
                        <img src={ChristophGanster} alt="Christoph Ganster" />
                      </div>
                      <div className="info">
                        <p className="name">Christoph Ganster</p>
                        <p className="role">Chief Hospitality Officer</p>
                      </div>
                      <ul className="socials">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/ganster/"
                            target="_blank"
                            className="linkedin"
                          ></a>
                        </li>
                      </ul>
                      <div className="member-info">
                        <p>
                          With over 25 years of experience as an international
                          hotelier, Christoph Ganster is a luxury hospitality
                          industry veteran who has worked in senior management
                          positions for some of thetop luxury hospitality brands
                          across the Maldives, Mauritius, Switzerland, the
                          United States, and Germany.
                        </p>
                        <p>
                          It was in the United Arab Emirates, while serving as
                          Hotel Manager at Raffles Dubai, that Christoph hired
                          and worked with Rana. Now, Christoph joins Rana and
                          the Hotelier Coin team to innovate and reorganize the
                          hospitality industry with win-win solutions for all
                          stakeholders.
                        </p>
                        <p>
                          Christoph Ganster graduated from the School of Tourism
                          &amp; Hotel Management Schloss Klessheim, Salzburg,
                          Austria and went on to build a thriving career in the
                          luxury hospitality industry. He has served as Managing
                          Director of Laucala Island, Fiji, and worked with FRHI
                          Hotels &amp; Resorts for over a decade, serving as
                          General Manager atRaffles Seychelles, Fairmont Grand
                          Hotel Kyiv, Ukraine, and Fairmont Heliopolis &amp;
                          Towers Cairo, Egypt.Christoph is also an active member
                          of the Board of the World Luxury Hotel Awards.
                        </p>
                      </div>
                      <a className="read_more" href="#">
                        Read more...
                      </a>
                    </div>
                  </div>
                </div> */}
                  <div
                    className="owl-item active"
                    style={{ width: "349.333px", marginRight: "10px" }}
                  >
                    <div className="member-wrap">
                      <div className="member">
                        <div className="photo">
                          <img
                            src={Felix}
                            alt="Felix"
                            style={{
                              objectFit: "contain",
                              height: "306px",
                              marginTop: "-68px",
                            }}
                          />
                        </div>
                        <div className="info">
                          <p className="name">Felix Mago</p>
                          <p className="role">
                            Director of Partnership - Blockchain
                          </p>
                        </div>
                        <ul className="socials">
                          <li>
                            <a
                              href="https://www.linkedin.com/in/felix-mago/"
                              target="_blank"
                              className="linkedin"
                            ></a>
                          </li>
                        </ul>
                        <div className="member-info">
                          <p>
                            Felix is Co-Founder of Dash NEXT & Dash Thailand.
                            Dash is the leading cryptocurrency for real life
                            payments. Dash Next is focused on biz dev and
                            partnerships in Asia & beyond. Dash Thailand has
                            established the first real life crypto payment
                            ecosystem in Asia. The Author of the “Bitcoin
                            Handbook” is also Co-Founder of FUTERIO, a Southeast
                            Asia based gateway to Blockchain and Co-Founder of
                            the German Blocktech Institute, helping startups and
                            corporations from all sectors finding and
                            implementing their Blockchain potentials.
                          </p>
                        </div>
                        <a className="read_more" href="#">
                          Read more...
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item"
                    style={{ width: "349.333px", marginRight: "10px" }}
                  >
                    <div className="member-wrap">
                      <div className="member">
                        <div className="photo">
                          <img src={Taniya} alt="Tetyana Polunina" />
                        </div>
                        <div className="info">
                          <p className="name">Tetyana Polunina</p>
                          <p className="role">Executive VP – Brand </p>
                        </div>
                        <ul className="socials">
                          <li>
                            <a
                              href="https://www.linkedin.com/in/tetyana-polunina-b1a046ba/"
                              target="_blank"
                              className="linkedin"
                            ></a>
                          </li>
                        </ul>
                        <div className="member-info">
                          <p>
                            Over ten years of a proven track record in Sales &
                            Marketing, with experience in Leading Luxury
                            International Hotels, such as Raffles Singapore,
                            Raffles Seychelles, G.H.M. Hotels - Chedi & Accor
                            hotels - across Singapore, Seychelles, U.A.E. &
                            Egypt. Successfully opened Chedi Sharjah &
                            positioned as the top 15 Hotels in the Middle East
                            by Conde Nast Traveler Readers' Choice Awards 2019;
                            winning the MODAMA Award for Green Hotels & Resorts
                            and be named Robb Report's Most Luxurious Resort in
                            U.A.E. Resort's rank on TripAdvisor number one in
                            less than a year of operation with only 5-star
                            reviews.
                          </p>
                        </div>
                        <a className="read_more" href="#">
                          Read more...
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item active"
                    style={{ width: "349.333px", marginRight: "10px" }}
                  >
                    <div className="member-wrap">
                      <div className="member">
                        <div className="photo">
                          <img
                            src={Ravi}
                            alt="Ravi"
                            style={{
                              filter: "grayscale(100%)",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="info">
                          <p className="name">Ravi Raj Singhail </p>
                          <p className="role">VP - Blockchain</p>
                        </div>
                        <ul className="socials">
                          <li>
                            <a
                              href="https://www.linkedin.com/in/ravi-raj-singhail-08571b102/"
                              target="_blank"
                              className="linkedin"
                            ></a>
                          </li>
                        </ul>
                        <div className="member-info">
                          <p>
                            Ravi Raj Singhail is a Blockchain &amp; ICO Advisor
                            and Co-founder of Plebiscite IT Services Pvt Ltd. He
                            loves to code and is an ex-IBM{" "}
                          </p>
                        </div>
                        <a className="read_more" href="#">
                          Read more...
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div
                  className="owl-item"
                  style={{ width: "349.333px", marginRight: "10px" }}
                >
                  <div className="member-wrap">
                    <div className="member">
                      <div className="photo">
                        <img
                          src={jamesPrhodes}
                          style={{ filter: "grayscale(100%)" }}
                          alt="James Rhodes"
                        />
                      </div>
                      <div className="info">
                        <p className="name">James Rhodes</p>
                        <p className="role">PR Director</p>
                      </div>
                      <ul className="socials">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/jamesprhodes/"
                            target="_blank"
                            className="linkedin"
                          ></a>
                        </li>
                      </ul>
                      <div className="member-info">
                        <p>
                          James fell in love with digital content at an early
                          age. When he was in HS, he built a fitness blog to
                          over 5,000 monthly views and it was love at first
                          sight.
                        </p>
                      </div>
                      <a className="read_more" href="#">
                        Read more...
                      </a>
                    </div>
                  </div>
                </div> */}

                  {/* <div
                  className="owl-item"
                  style={{ width: "349.333px", marginRight: "10px" }}
                >
                  <div className="member-wrap">
                    <div className="member">
                      <div className="photo ferdi">
                        <img src={Melida} alt="Melida Kacapor" />
                      </div>
                      <div className="info">
                        <p className="name">Melida Kacapor</p>
                        <p className="role">Institutional Investment – Asia</p>
                      </div>
                      <ul className="socials">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/melida-kacapor-061481a4/"
                            target="_blank"
                            className="linkedin"
                          ></a>
                        </li>
                      </ul>
                      <div className="member-info"></div>
                     
                    </div>
                  </div>
                </div> */}

                  <div
                    className="owl-item"
                    style={{ width: "349.333px", marginRight: "10px" }}
                  >
                    <div className="member-wrap">
                      <div className="member">
                        <div className="photo ferdi">
                          <img src={Sameer} alt="Al Osh" />
                        </div>
                        <div className="info">
                          <p className="name">Sameer Kumarr Goel</p>
                          <p className="role">CFO</p>
                        </div>
                        <ul className="socials">
                          <li>
                            <a
                              href="https://www.linkedin.com/in/sameer-kumarr-goel-72838110/"
                              target="_blank"
                              className="linkedin"
                            ></a>
                          </li>
                        </ul>
                        <div className="member-info">
                          <p>
                            Over 20+years of progressive career in both
                            Start-ups & established organizations like PWC, GE
                            Capital, Reebok, and Kohler Co, with perseverance,
                            passion with meticulous financial leadership &
                            conviction to deliver a better tomorrow for the
                            community. Business/Commercial acumen & detailed eye
                            to execution with impeccable tangible results
                            benefitted the organization to grow in 100 folds.
                          </p>
                        </div>
                        <a className="read_more" href="#">
                          Read more...
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div className="owl-item" style={{width: '349.333px',  marginRight: '10px'}}>
                                    <div className="member-wrap">
                                        <div className="member">
                                            <div className="photo">
                                                <img src={Suraj} alt="Suraj"
                                                     style={{filter: 'grayscale(100%)'}} />
                                            </div>
                                            <div className="info">
                                                <p className="name">Suraj Chaudhary</p>
                                                <p className="role">Blockchain Advisor</p>
                                            </div>
                                            <ul className="socials">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/surajchaudhary/"
                                                       target="_blank" className="linkedin"></a>
                                                </li>
                                            </ul>
                                            <div className="member-info">
                                                <p>
                                                    Professional Blockchain and fintech expert, a seasoned business analyst
                                                    with more than 4 years of experience in the IT business. Suraj is a
                                                    serial entrepreneur who has successfully founded not only IT and ICO
                                                    companies, but also skill learning courses for Cyber Security.
                                                </p>

                                            </div>
                                            <a className="read_more" href="#">Read more...</a>
                                        </div>
                                    </div>
                                </div> */}
                  {/* <div className="owl-item" style={{width: '349.333px',  marginRight: '10px'}}>
                                    <div className="member-wrap">
                                        <div className="member">
                                            <div className="photo">
                                                <img src={George} alt="George"
                                                     style={{filter: 'grayscale(100%)'}} />
                                            </div>
                                            <div className="info">
                                                <p className="name">George Michael </p>
                                                <p className="role">UI/UX Expert</p>
                                            </div>
                                            <ul className="socials">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/george-michael-828ab7106/"
                                                       target="_blank" className="linkedin"></a>
                                                </li>
                                            </ul>
                                            <div className="member-info">
                                                <p>
                                                    George Michael is a co-founder of Plebiscite IT Services Pvt
                                                    Ltd.George is a UI/UX Expert having experience of more than 2 years.
                                                    He loves to design and develop with the latest technologies.</p>

                                            </div>
                                            <a className="read_more" href="#">Read more...</a>
                                        </div>
                                    </div>
                                </div>
                                */}

                  {/*                                
                                <div className="owl-item" style={{width: '349.333px',  marginRight: '10px'}}>
                                    <div className="member-wrap">
                                        <div className="member">
                                            <div className="photo">
                                                <img src={SaketJoshi} alt="Saket Joshi" />
                                            </div>
                                            <div className="info">
                                                <p className="name">Saket Joshi</p>
                                                <p className="role">Head of Innovation</p>
                                            </div>
                                            <ul className="socials">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/saket-joshi-47633a139/" target="_blank" className="linkedin"></a>
                                                </li>
                                            </ul>
                                            <div className="member-info">
                                                <p>
                                                    At the age of 18, Saket Joshi is one of the youngest ICO advisors in
                                                    the UAE. He has worked as a content creator and digital marketer for
                                                    a few companies and is now pursuing a computer science degree in
                                                    Dubai. Saket is a Blockchain enthusiast and a Cryptocurrency
                                                    investor whose independent nature, positive outlook, persistency and
                                                    inquisitive mind impressed Rana. Equipped with an unstoppable
                                                    enthusiasm and creativity, Saket joins Hotelier Coin as Head on
                                                    Innovation.
                                                </p>
                                            </div>
                                            <a className="read_more" href="#">Read more...</a>
                                        </div>
                                    </div>
                                </div> */}

                  {/*<div className="owl-item" style={{width: '349.333px',  marginRight: '10px'}}>
                                    <div className="member-wrap">
                                        <div className="member">
                                            <div className="photo">
                                                <img src={KateBentley} alt="Kate Bentley" />
                                            </div>
                                            <div className="info">
                                                <p className="name">Kate Bentley</p>
                                                <p className="role">Director of Revenue</p>
                                            </div>
                                            <ul className="socials">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/kate-bentley-5b136043/"
                                                       target="_blank" className="linkedin"></a>
                                                </li>
                                            </ul>
                                            <div className="member-info">
                                                <p>
                                                    Kate Bentley specializes in the development of hotel and resort
                                                    systems and frameworks to maximize value for brands, license owner
                                                    holders, owners and operators, and is the Director of Vroom Hotels,
                                                    a company that delivers revenue management and sales services to the
                                                    independent hotel sector.
                                                </p>
                                                <p>
                                                    For the past 15+ years, Kate has worked with leading hospitality
                                                    brands and operators, including Best Western International, De Vere
                                                    Group, Bespoke Hotels International, Gotham, Malmaison, Hotel du
                                                    Vin. As an enthusiastic advocate for effective Revenue Management
                                                    practices and Channel Management tools that positively impact the
                                                    industry, she joins the Hotelier Coin team as a Revenue Management
                                                    Advisor on a mission to help transform the future of hospitality.
                                                </p>
                                            </div>
                                            <a className="read_more" href="#">Read more...</a>
                                        </div>
                                    </div>
                                </div>*/}

                  {/* <div className="owl-item" style={{width: '349.333px',  marginRight: '10px'}}>
                                    <div className="member-wrap">
                                        <div className="member">
                                            <div className="photo">
                                                <img src={Yateesh} alt="Yateesh"
                                                     style={{filter: 'grayscale(100%)'}} />
                                            </div>
                                            <div className="info">
                                                <p className="name">Yateesh Bhardwaj</p>
                                                <p className="role">Associate Partner</p>
                                            </div>
                                            <ul className="socials">
                                                <li>
                                                    <a href="https://www.linkedin.com/in/yateesh-bhardwaj/"
                                                       target="_blank" className="linkedin"></a>
                                                </li>
                                            </ul>
                                            <div className="member-info">
                                                <p>
                                                    Yateesh Bhardwaj is a Serial Entrepreneur and co-founder of Plebiscite IT Services Pvt Ltd. Yateesh is a Blockchain Enthusiast and strategic thinker. He loves to solve problems with the most advanced and technical approach.</p>

                                            </div>
                                            <a className="read_more" href="#">Read more...</a>
                                        </div>
                                    </div>
                                </div> */}
                  {/* <div className="owl-item" style={{width: '349.333px',  marginRight: '10px'}}>
                                    <div className="member-wrap">
                                        <div className="member">
                                            <div className="photo">
                                                <img src={Neha} alt="Neha" style={{filter: 'grayscale(100%)', width:'180px', height:'180px'}} />
                                            </div>
                                            <div className="info">
                                                <p className="name">Neha Rai</p>
                                                <p className="role">Partnership Manager</p>
                                            </div>
                                            <ul className="socials">
                                                <li>
                                                    <a href="#" target="_blank" className="linkedin"></a>
                                                </li>

                                            </ul>
                                            <div className="member-info">
                                                <p>Driven by new challenges and a constant desire to learn new skills, Neha joined Hotelier Coin as Partnership’s Manager. From completing her MBA in Marketing and Human Resources, Neha is a perfect fit for our goals. Post-graduation, she gained two years of experience in the travel industry...</p>
                                            </div>
                                            <a className="read_more" href="#">Read more...</a>
                                        </div>
                                    </div>
                                </div> */}
                </div>
              </div>
              <div className="owl-nav">
                <div className="owl-prev"></div>
                <div className="owl-next"></div>
              </div>
              <div className="owl-dots disabled"></div>
            </div>
          </div>

          <div
            id="member-info-popup"
            className="popup_wrap"
            style={{ display: "none" }}
          >
            <div className="popup">
              <a href="#" className="close-popup">
                Close
              </a>
              <div className="popup-content">
                <div className="member-popup-left">
                  <div className="member" style={{ height: "100%" }}>
                    <div className="photo">
                      <img
                        src="/landing/img/team/RanaMukherji.jpg"
                        alt="Rana Mukherji"
                      />
                    </div>
                    <div className="info">
                      <p className="name"></p>
                      <p className="role"></p>
                    </div>
                    <ul className="socials">
                      <li>
                        <a
                          href="https://www.linkedin.com/in/rana-mukherji-6277703/"
                          target="_blank"
                          className="linkedin"
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="member-popup-right">
                  <div className="member-info">
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="team_banner_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <a
                  target="_blank"
                  href="https://www.travolution.com/articles/113230/travolution-awards-2019-winners-revealed"
                >
                  <img src={WinnerAward} style={{ width: "100%" }} />
                </a>
              </div>
              <div className="col-md-8 ttt_ttt_ttt">
                <h4>
                  HotelierCoin Wins Best Technological Innovation at the
                  Travolution Awards
                </h4>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    )
  }
}

export default OurTeam
