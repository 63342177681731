import React, { Component } from 'react';
import Navbar from './includes/navbar';
import Footer from './includes/footer';
import appStore from './public/landing/img/appstore.png';
import playStore from './public/landing/img/playstore.png';
import './career.css';
import $ from "jquery";


class PrivateSale extends Component {


    componentDidMount() {
        // Mobile menu

        $('.toggle_menu').mouseup(function () {
            $(this).toggleClass("on");
            if($(window).width() <= 550) {
                if($(".mobile_menu").hasClass('visible')) {
                    $('.mobile_menu').hide();
                }
                else {
                    $('.mobile_menu').show();
                }
                $('.mobile_menu').toggleClass('visible');
            } else {
                if($(".topline_bg nav").hasClass('visible')) {
                    $('.topline_bg nav').hide();
                }
                else {
                    $('.topline_bg nav').show();
                }
                $('.topline_bg nav').toggleClass('visible');
            }

        });

        const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    let countDown = new Date('Oct 31, 2019 00:00:00').getTime(),
    x = setInterval(function() {

      let now = new Date().getTime(),
          distance = countDown - now;

        document.getElementById('days').innerText = Math.floor(distance / (day));
        document.getElementById('hours').innerText = Math.floor((distance % (day)) / (hour));
        document.getElementById('minutes').innerText = Math.floor((distance % (hour)) / (minute));
        document.getElementById('seconds').innerText = Math.floor((distance % (minute)) / second);
      
      

    }, second)

    }


    render() {
        return (


            <div className="private-sale-main">

                <header style={{marginBottom: '0px'}} className="careers-bg private_bg">
                    <Navbar />

                    <div className="header_content content" style={{paddingTop: '0',position:'relative'}}>
                        <div className="row">
                            <div className="col-sm-6">
                            <span style={{color: 'white', fontSize: '3.5em', fontWeight:'bold'}}>Save Big on Your Future Travel Plans</span>
                        <p style={{color: 'white', fontSize: '2em', fontWeight:'bold'}}>Join Our Private Sale of HotelierCoin</p>
                            </div>
                            <div className="col-sm-6">
                            <div className="right-top-col private-sale-presale">
                        <div className="timer-container">
                          <h3 id="head">PRESALE ENDS IN</h3>
                           <ul>
                            <li><span id="days"></span>days</li>
                            <li><span id="hours"></span>Hours</li>
                            <li><span id="minutes"></span>Minutes</li>
                            <li><span id="seconds"></span>Seconds</li>
                          </ul>                         
                          <div className="download_btn">
                          <a href="/register/" className="btn">Buy Now</a>
                           {/* <a href="#"><img src={appStore} /></a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.app.hoteliermart"><img src={playStore} /></a> */}
                            </div>
                        </div>
                        
                        </div>
                                </div>
                        </div>
                       
                        
                    </div>
                </header>


                <div className="main sm-sz">

                    <section className="video_section" id="about">
                        <div className="content" style={{display:'inherit'}}>
                            <h2 className="section_header section_header_line" style={{textAlign:'left'}}>Join the Sale & Lock in Your Savings</h2>

                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-6 left_col">
                                       <p> HTLC Worth = $1.2</p>
                                       
                                       <hr></hr>
                                       <p>upto 35% Off Travel Experiences</p>
                                    </div>

                                    <div className="col-sm-6 right_col">
                                       <p> Take advantage of our</p>
                                       <p>private sale and save upto 35%</p>
                                       <p>on hotels, vacation homes,</p>
                                       <p>car rentals, flights and more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                   


                    


                    


                   

                </div>


                <Footer />


            </div>
                    );
    }
}

export default PrivateSale;
