import React, { Component } from 'react';
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import logo from '../public/img/logo_white.png';
import axios from "axios";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import $ from "jquery";
class topBar extends Component {
    constructor (props){
        super(props);

        this.state = {
          profile : [],
          showMenu:false,
         userEmailCheck:''
      };
        
    }
    logout(e){
        e.preventDefault();
        localStorage.clear();
        this.props.history.push('/');
    }
    componentDidMount(){     
      let current=this;
      if(!localStorage.getItem('authorization')){
          current.props.history.push("/login");
      }


      const header = {
          headers: {
              "authorization" : localStorage.getItem('authorization')
          }
      }


      axios.get('https://hoteliercoins.co/api/v1/userInfo/profile',{headers:header.headers})
          .then(res => {
              if(res.data.status){
                  console.log(res.data.data);
                 
                  this.setState({profile: res.data.data});
                  this.userEmailCheck="dallas@mmffund.com";                  
                  if(this.userEmailCheck == this.state.profile.email){                    
                    this.setState({showMenu:true});                    
                  }
                  else{
                    this.setState({showMenu:false});
                  }
                
                 
                 
              }
              else if(res.data.message == "jwt expired"){
                  localStorage.clear();
                  this.props.history.push('/');
              }
              else{
                  toastr.error(res.data.message)
              }
             
             
          })
          .catch(function (error) {
          });
        $(window).scroll(function() {
            if ($(this).scrollTop() > 100){  
              
                $('body').addClass("sticky");

            }
            else{
                $('body').removeClass("sticky");
            }
        });

        $('#menuToggle input[type="checkbox"]').click(function(){
          if($(this).prop("checked") == true){
             $("body").addClass('menubar-collapsed');
          }
          else if($(this).prop("checked") == false){
            $("body").removeClass('menubar-collapsed');
          }
      });
    }
    render() {
        return (
            // <div className="top-line top-line-custom">
            //     <div className="user">
            //     <Link onClick={this.logout.bind(this)}>
            //     <p className="dropdown topline-font-custom">Logout</p>
            //     </Link>
            //         <div className="dropdown-custom" style={{display: 'none'}}>
            //             <p className="dropdown topline-font-custom active">George</p>
            //             <a href="/cabinet/profile" className="topline-font-custom">Account</a>
            //             <a href="/logout" className="topline-font-custom">Log Out</a>
            //         </div>
            //     </div>
            // </div>
            <div class="inner_header">
            <nav class="navbar not_mobile_menu">
              <div class="container-fluid">
                <div class="navbar-holder d-flex align-items-center justify-content-between">
                  <div class="navbar-header"><a href="/" class="navbar-brand">
                      <div class="brand-text d-none d-md-inline-block">
                          <img src={logo}/>
                    </div></a></div>
                  <ul class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center right_nav">
                   
                    <li class="nav-item">
                         <a  href="/cabinet/tokensale"  class="nav-link"><i class="mdi mdi-view-dashboard"></i>Dashboard</a>                       
                    </li>
                    {
                      this.state.showMenu ?
                      <li class="nav-item">
                      <a  href="/cabinet/airdrop-dashboard"  class="nav-link"><i class="mdi mdi-view-dashboard"></i>Airdrop Dashboard</a>                       
                   </li>
                 :null                 
                    }
                   
                    <li class="nav-item">
                            <a  href="/cabinet/withdraw"  class="nav-link"><i class="mdi mdi-cash-register"></i>Withdraw</a>                       
                       </li>
                       <li class="nav-item">
                            <a  href="/cabinet/history"  class="nav-link"><i class="mdi mdi-file-chart"></i>History</a>                       
                       </li>
                       <li class="nav-item">
                            <a  href="/cabinet/profile"  class="nav-link"><i class="mdi mdi-account-circle"></i>Profile</a>                       
                       </li>
                   
                   
                    <li class="nav-item"><a href="javascript:void(0)" onClick={this.logout.bind(this)} class="nav-link"> <i class="mdi mdi-logout"></i>Logout</a></li>
                  </ul>
                </div>
              </div>
            </nav>


            <nav className="mobile_navbar" role="navigation">
            <div id="menuToggle">
              <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
               
            <ul id="menu">
              <li><a href="/cabinet/tokensale">Dashboard</a></li>
              {
                      this.state.showMenu ?
              <li><a href="/cabinet/airdrop-dashboard">Airdrop  Dashboard</a></li>
              :null
              }
              <li><a href="/cabinet/withdraw">Withdraw</a></li>
              <li><a href="/cabinet/history">History</a></li>
              <li><a href="/cabinet/profile">Profile</a></li>
              <li><a href="javascript:void(0)" onClick={this.logout.bind(this)} >Logout</a></li>
            </ul>
           </div>
           <img src={logo}/>
          </nav>
            
      </div>


        );
    }
}

export default (withRouter(topBar));

