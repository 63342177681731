import React, { Component } from "react"
import Navbar from "./includes/navbar"
import Footer from "./includes/footer"
import "./career.css"
import SCF from "./public/img/scf.png"
import HTLC from "./public/img/htlc.png"

import $ from "jquery"

class Disclaimer extends Component {
  componentDidMount() {
    // Mobile menu
  }

  render() {
    return (
      <div className={"container"}>
        <header
          style={{
            marginBottom: "0px",
            backgroundImage: "none",
            minHeight: "50px",
          }}
        >
          <Navbar />
        </header>

        <section className="disclaimer_sec">
          <h2 className="section_title">Important Information</h2>

          <h3>Introduction</h3>

          <p>
            In September 2020, Global Hotelier PTE. LTD. Singapore. (
            <strong>"HotelierCoin"</strong>) substantially completed its Interim
            ERC-20 tokens (the <strong>"HTLC"</strong>).
          </p>
          <p>
            By purchasing and/or holding the HTLC, each potential Purchaser and
            holder of the HTLC (<strong>"Purchaser"</strong>) accepts and agrees
            that to the extent permitted by law, HotelierCoin disclaims all
            liability, damages, cost, loss or expense (including, without
            limitation, legal fees, costs and expenses) to it in respect of its
            purchase and/or holding of the HTLC.
          </p>
          <p>Holder Considerations</p>
          <p>
            Each Purchaser should carefully consider all factors involved in
            purchasing and/or holding the HTLC, including, but not limited to,
            those listed below and, to the extent necessary, consult an
            appropriate professional or other experts (including an expert in
            cryptographic tokens or blockchain-based software systems). If any
            of the following considerations are unacceptable to a Purchaser,
            that Purchaser should not purchase the HTLC. These considerations
            are not intended to be exhaustive and should be used as guidance
            only.
          </p>

          <ul className="disclaimer_ul">
            <li>
              The HotelierCoins are not intended to represent any formal or
              legally binding investment.
            </li>
            <li>
              Cryptographic tokens that possess value in public markets, such as
              Ether and Bitcoin, have demonstrated extreme fluctuations in price
              over short periods on a regular basis. Purchasers should be
              prepared to expect similar fluctuations in the price of the HTLC,
              and Purchasers may experience a complete and permanent loss of
              their initial purchase.
            </li>
            <li>
              Cryptocurrency exchanges on which the HTLC may trade in the future
              may be relatively new and unregulated and may therefore be more
              exposed to fraud and failure than established regulated exchanges.
            </li>
            <li>
              The use of the HotelierCoin Blockchain System could be affected by
              a number of factors, including slowing demand for its services,
              increasing competition, decreases in growth of the overall
              cryptocurrency market, or failure to capitalize on growth
              opportunities.
            </li>
            <li>
              Hackers, individuals, other malicious groups or organizations may
              attempt to interfere with the HotelierCoin Blockchain System and
              the HTLC in various ways such as cryptographic attacks, malware
              attacks, denial of service attacks, consensus-based attacks, Sybil
              attacks, smurfing and spoofing.
            </li>
            <li>
              The regulatory status of cryptographic tokens, blockchain, and
              distributed ledger technology, as well as its applications, are
              unclear or unsettled in many jurisdictions and it is difficult to
              predict how or whether governments or regulatory agencies may
              implement changes to the law or apply existing regulation with
              respect to such technology and its applications, including the
              HotelierCoin Blockchain System and the HTLC.
            </li>
            <li>
              The tax characterizations of the HTLC are unclear and uncertain in
              many jurisdictions. Purchasers must seek their own tax advice in
              connection with purchasing the HTLC, which may result in adverse
              tax consequences.
            </li>
          </ul>
          <h3 className="mt-3">Certain Restrictions</h3>
          <p>
            The HotelierCoins are not intended to be securities (or any other
            regulated instrument) under the laws of any jurisdiction where they
            are intended to be, or will be, purchased or sold, and no action has
            been or will be taken in any jurisdiction by HotelierCoin or any of
            its affiliates that would permit a public offering, or any other
            offering under the circumstances not permitted by applicable law of
            the HTLC, in any country or jurisdiction where action for that
            purpose is required. Accordingly, the HTLC may not be offered or
            sold, directly or indirectly, by any Purchaser, in or from any
            country or jurisdiction, except in circumstances which will result
            in compliance with all applicable rules and regulations of any such
            country or jurisdiction. In particular:
          </p>
          <h5 style={{ textDecoration: "underline" }}>Singapore</h5>
          <p>
            The HTLC are not "securities" under the Securities and Futures Act
            (Cap. 289) of Singapore.
          </p>
          <h5 style={{ textDecoration: "underline" }}>Reference:</h5>
          <strong>Monetary Authority of Singapore</strong>
          <p>
            <a href="https://www.loc.gov/law/help/cryptoassets/singapore.php">
              https://www.loc.gov/law/help/cryptoassets/singapore.php
            </a>
          </p>
          <p>
            The “Limited Purpose Digital Payment Token” exclusion refers to
            payment services, which involve non-monetary consumer loyalty or
            reward points or in-game assets or similar digital representations
            of value, which cannot be returned to the issuer or sold,
            transferred or exchanged for money. On the other hand, the “Central
            Bank Digital Payment Token” exception is one where a central bank or
            financial institution provides services for dealing in or
            facilitating the exchange of central bank digital payment tokens. In
            the former, Parliament has considered such activities to not pose
            sufficient risk to warrant regulation under the licensing regime. In
            respect of the latter, the rationale was that such institutions
            would have already been sufficiently regulated.
          </p>
        </section>

        <Footer />
      </div>
    )
  }
}

export default Disclaimer
