import React, {Component} from 'react';
import Logo from '../public/img/hotelier_logo.svg';
import {Link} from 'react-router-dom';
import Card from '../public/img/card.svg';
import Account from '../public/img/account.svg';
import Bounty from '../public/img/bounty.svg';
import Faq from '../public/img/faq.svg';
import History from '../public/img/history.svg';
import Partner from '../public/img/partner.svg';
import Withdraw from '../public/img/withdraw.svg';
import HtLogo from '../public/img/ht-logo.svg';
import './sidebar.css';

class Home extends Component {

    constructor(props) {
        super(props);
        this.activeFn = this.activeFn.bind(this);
    }
    logout(e){
        alert("hello");
        e.preventDefault();
        localStorage.clear();
        this.props.history.push('/');
    }
    activeFn(e) {
        document.getElementsByClassName("nav-icon-custom").className = 'active';
    }

    render() {
        return (
            <div className="nav nav-custom">
                <div className="logo_wrap">
                    <a href="/">
                        <img src={Logo} alt="logo" className="logo_main"/>
                        <img src={HtLogo} alt="logo" className="logo_mini"/>
                    </a>
                </div>
                <ul className={'li-p-xxx'}>
                    <li className="nav-icon-custom" onClick={document.getElementsByClassName('nav-icon-custom').className = 'active'}>
                        <Link to="/cabinet/tokensale">
                            {/* <img src={Card} className="card-class" alt="Card"/> */}
                            <i className="mdi mdi-view-dashboard"></i>
                            <span className="nav-font-custom">Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-icon-custom">
                        <Link to="/cabinet/withdraw">
                            <img src={Withdraw} className="card-class" alt="Card"/>
                            <span className="nav-font-custom">Withdraw</span>
                        </Link>
                    </li>
                    <li className="nav-icon-custom">
                        <Link to="/cabinet/history">
                            <img src={History} className="card-class" alt="Card"/>
                            <span className="nav-font-custom">History</span>
                        </Link>
                    </li>
                    <li className="nav-icon-custom">
                        <Link to="/cabinet/profile">
                            <img src={Account} className="card-class" alt="Card"/>
                            <span className="nav-font-custom">Profile</span>
                        </Link>
                    </li>
                    {/*<li className="nav-icon-custom last">*/}
                        {/*<Link to="/cabinet/faq">*/}
                            {/*<img src={Faq} className="card-class" alt="Card"/>*/}
                            {/*<span className="nav-font-custom">F.A.Q.</span>*/}
                        {/*</Link>*/}
                    {/*</li>*/}
                    {/* <li className="nav-icon-custom">
                    <Link onClick={this.logout.bind(this)}>
                        <i className="mdi mdi-logout"></i>
                      <span className="nav-font-custom">Logout</span>
                </Link>
                    </li> */}
                </ul>
            </div>
        );
    }
}

export default Home;
