import React, { useEffect } from "react"
import FaqIcon from "./public/landing/img/faq_icon.png"
import $ from "jquery"
import Navbar from "./includes/navbar"
import Footer from "./includes/footer"

const FaqHome = () => {
  useEffect(() => {
    $(".faq_list li").on("click", function () {
      $(this).toggleClass("active")
      $(this).find(".hint").slideToggle(200)
    })
  }, [])
  return (
    <>
      <header
        style={{
          backgroundImage: "none",
          minHeight: "auto",
          marginBottom: "0",
        }}
      >
        <Navbar />
      </header>
      <section id="faq" className="content">
        <h2 className="section_title" style={{ textAlign: "left" }}>
          F.A.Q.
        </h2>
        <div className="faq">
          <div className="faq_icon make-me-sticky">
            <h4>We Have the Answer You Were Looking For</h4>
            <img src={FaqIcon} alt="faq" />
          </div>
          <div className="faq_list">
            <ul>
              <li>
                <div className="item">
                  <p>What is HotelierCoin (HTLC)?</p>
                </div>
                <div className="hint">
                  <p>
                    Winner of the 2019 Best Technology Innovation Award,
                    HotelierCoin is a travel reward currency that holds the
                    potential to disrupt the entire travel loyalty industry.
                    HotelierCoin introduces blockchain technology in order to
                    tie together all the individual complex mechanisms of
                    leisure and travel, combining them into one efficient system
                    that rewards much greater than any other loyalty program.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>Why HotelierCoin?</p>
                </div>
                <div className="hint why_H_List">
                  <ul className="faq__list">
                    <li>
                      HotelierCoin (HTLC) will club all independent hotels/homes
                      & small hotel brands to provide massive collections of
                      unique hotels in one platform.
                    </li>
                    <li>
                      HTLC- the travel reward currency is earned as cashback for
                      all travel transaction from booking hotels, airlines,
                      debit card payment to sharing travel contents.
                    </li>
                    <li>
                      HTLC can be monetized, traded, or utilized for all travel
                      related products at members-only, wholesale rates.
                    </li>
                    <li>
                      HTLC bonus rewards will airdrop to encourage high usage of
                      HTLC.
                    </li>
                    <li>
                      HTLC can be purchased with all major cryptocurrencies.
                    </li>
                    <li>
                      Other brands loyalty points will be exchanged with
                      hoteliercoin.
                    </li>
                  </ul>
                  <p>
                    <b>
                      “Ownership of never expiring loyalty tokens, and
                      transparency of emission of tokens, secured with
                      demand-pegged tokenomics & smart contracts will empower
                      the travelers & independent hotel owners.” “None of the
                      solutions in the market offer end to end holistic solution
                      for the entire travel loyalty spectrum.”
                    </b>
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>How much is HotelierCoin worth?</p>
                </div>
                <div className="hint">
                  <p>
                    At its initial website HotelierCoin is priced at $1.2 USD
                    per coin. This is a very respectable figure when compared
                    with the value of many other cryptocurrencies, some of which
                    are priced at fractions of a cent.
                  </p>
                  <p>
                    On the Travolier website, the value of each coin will never
                    drop below this price, ensuring that the rewards for your
                    loyalty will never diminish.
                  </p>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>Can I lose money with HotelierCoin?</p>
                </div>
                <div className="hint">
                  <p>
                    HotelierCoin is not about losing money, it is designed to
                    relieve the stresses of travel and earn you money by
                    rewarding you for each and every adventure that you take.
                  </p>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>How can I spend my HotelierCoin?</p>
                </div>
                <div className="hint">
                  <p>
                    Now HotelierCoin you can cash in your loyalty coins any time
                    you choose. You can also exchange your HTLC for a wide range
                    of cryptocurrencies that are also available on the platform.
                  </p>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>How can I buy HTLC?</p>
                </div>
                <div className="hint">
                  <p>
                    You can{" "}
                    <a href="https://www.travolier.com/" target="_blank">
                      purchase your HTLC directly from the HotelierCoin website.
                    </a>{" "}
                    There is no limit to how much you can buy, and once you have
                    your wallet set up, you can deposit any HTLC that you have
                    earned from your travels directly into it.
                  </p>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>Is HTLC it secured?</p>
                </div>
                <div className="hint">
                  <p>
                    Designed by a group of hospitality experts and fintech
                    specialists, the team of HotelierCoin understand the
                    importance of your right to privacy. Your wallet is secured
                    by the latest military-grade cryptography methods to ensure
                    that your funds stay safe.
                  </p>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>How can I earn rewards?</p>
                </div>
                <div className="hint">
                  <p>
                    You can earn your HTLC rewards by simply signing up to the{" "}
                    <a href="https://www.travolier.com/" target="_blank">
                      Travolier official website.
                    </a>{" "}
                    By selecting your stay from one of the hundreds of thousands
                    of beautiful hotels and luxury vacation homes connected to
                    their network, you can enjoy an experience of a lifetime and
                    get rewarded for it.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>Are Travolier and HotelierCoin the same?</p>
                </div>
                <div className="hint">
                  <p>
                    No, but they are intimately connected. By booking your
                    vacation with Travolier, you can select from the biggest
                    hotel brands, vacation homes and luxury villas throughout
                    the world’s most stunning and captivating locations. Each
                    time you book, each time you travel, you earn HotelierCoin
                    which can be held in your wallet to be spent on your next
                    holiday or exchanged at a time of your choosing for fiat or
                    even another cryptocurrency.
                  </p>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>Will HotelierCoin make me rich?</p>
                </div>
                <div className="hint">
                  <p>
                    It is no secret that the volatility of the cryptocurrency
                    market has the potential to earn investors some truly
                    impressive gains. What makes HotelierCoin unique is the way
                    in which it rewards the traveller. Your wallet grows as you
                    enrich your horizons through travel.
                  </p>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>
                    Will I be able to pay for goods and services using my HTLC?
                  </p>
                </div>
                <div className="hint">
                  <p>
                    Yes. By booking your vacations through Travolier.com, you
                    have the option to spend your HotelierCoins on your next
                    holiday. You will also be able to exchange your coins for
                    fiat and many other digital currencies.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>Can I buy HotelierCoin using other crypto?</p>
                </div>
                <div className="hint">
                  <p>
                    Yes. By using the HotelierCoin.io website you can purchase
                    HTLC using over 20 other cryptocurrencies. As the platform
                    continues to develop and evolve, even more cryptocurrencies
                    will be made available for you to exchange for HotelierCoin.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>What is our objective and mission?</p>
                </div>
                <div className="hint">
                  <p>
                    <b>
                      Our core mission is to become the ultimate decentralized
                      travel application by rewarding & synchronizing the global
                      hospitality & travel industry.
                    </b>
                  </p>
                  <p>
                    HotelierMart aims to become a one-stop shop platform for
                    stand-alone hotel operators, provide substantial benefits
                    for everyone in the value chain, and build a decentralized
                    hospitality community of service providers, hospitality
                    vendors and tech solutions powered by Hotelier-StableCoin,
                    The travel reward currency.
                  </p>
                  <p>
                    HotelierCoin’s mission is to disrupt the travel loyalty
                    program by replacing loyalty points with loyalty
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>
                    What are the unique features of HotelierCoin compared to the
                    traditional loyalty program?
                  </p>
                </div>
                <div className="hint">
                  <p>
                    The HotelierCoin is created by a team of hospitality,
                    Blockchain & travel tech experts by keeping travelers &
                    independent hotel and vacation homeowners as a key focal
                    point. The team is determined to build a B2B, B2C, and
                    Peer-to-Peer solution that could connect consumers,
                    businesses, and investors directly. A revolutionary system
                    of trade that is beneficial to all parties.
                  </p>

                  <ul className="uf-list">
                    <li>
                      Tokenomics and market drive valuation of the coin not by
                      an issuer (brands).
                    </li>
                    <li>
                      HotelierCoins are backed with Demand (cashback) & smart
                      contract managed token emission
                    </li>
                    <li>
                      HotelierCoin Rewards are for both traveler and direct
                      contracted hotels, vacation homes & leisure service
                      providers.
                    </li>
                    <li>
                      HotelierCoins can be monetized through crypto exchanges.
                    </li>
                    <li>
                      HotelierCoin members maximize the value for money with
                      access to discounted hotel rates and earn monetary rewards
                      at the same time.
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>Why will the HotelierCoin token grow in value?</p>
                </div>
                <div className="hint">
                  <p>
                    The rise in token value is directly connected to demand
                    drivers within the HotelierMart platform.
                  </p>
                  <ul className="uf-list">
                    <li>
                      Cashback for all transaction will subsequently increase
                      token demand.
                    </li>
                    <li>
                      Tokens are released as per the cashback, managed by smart
                      contracts.
                    </li>
                    <li>
                      From 150M tokens, there are 70M tokens designed for
                      cashback, token sale through various exchanges and private
                      sale, 30M for team, advisor and community building, and
                      remaining tokens are released for a sole purpose of
                      cashback. Tokens will be issued against the cashback, not
                      for any other purpose.
                    </li>
                    <li>
                      After completion of 150M tokens, new tokens are minted and
                      released only for cashback, managed with smart contract.
                      Since HotelierCoins are demand-backed tokens, it will
                      automatically maintain the equilibrium of supply and
                      demand.
                    </li>
                  </ul>
                  <p>
                    HotelierCoin employs hoteliermart.com, Travolier.com and
                    dTMI. HotelierCoin has over 600K hotels, 700 airlines, 150K
                    vacation homes, airport transfers, and leisure service
                    providers and more — platform both to B2C, B2B & B2E. All
                    the factors encourage the demand for HotelierCoin.
                  </p>
                  <p>
                    Furthermore, HotelierCoins, which are held by large volume
                    travelers, increase currency HODL (HOLD), bring stability
                    and usage of the booking platform, triggers the increase in
                    demand for the HotelierCoin.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p> How does your loyalty program work?</p>
                </div>
                <div className="hint">
                  <p>
                    Travelers earn HotelierCoins with every transaction within
                    Travolier.com.
                  </p>
                  <p>
                    Travelers can earn HotelierCoin by booking from multiple
                    hotel brands, stand-alone hotels, vacation homes, airlines,
                    car rentals, vacation packages, including usage of
                    independent leisure service providers like city hosts or
                    city chefs. Now travelers don’t need to be loyal to one
                    brand to earn loyalty rewards.
                  </p>

                  <p>
                    Best of all, it is not just for travelers but hotel owners,
                    and service providers who are directly contracted with
                    Travolier. When you make a purchase, you will be rewarded
                    with a cashback of your transaction in HotelierCoin
                    equivalent for B2C and B2B.
                  </p>

                  <p>
                    HotelierCoin holders get airdrop every month as per the
                    transaction volume.
                  </p>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>How big is HotelierCoin ecosystem?</p>
                </div>
                <div className="hint">
                  <ul className="uf-list">
                    <li>600000 hotels in 185+ countries, over 5 000+ cities</li>
                    <li>700 Airlines</li>
                    <li>Airport transfers in 120 countries</li>
                    <li>Car rentals available in 140 countries</li>
                    <li>150 000+ Vacation homes</li>
                    <li>Leisure Service Providers</li>
                  </ul>
                  <p>And numbers keep growing!</p>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>What is a Hotelier – Stable Coin?</p>
                </div>
                <div className="hint">
                  <p>
                    Hotelier-StableCoin is a borderless travel currency for
                    cross border payments in our platform at HotelierMart.com.
                    Hotelier-Stablecoin – Euros for cross border, leisure
                    operators & peer to peer payments. HotelierCoin-StableCoin
                    will be the heart of HotelierMart.com. Hotelier-StableCoin
                    will be integrated with a FinTech wallet, powering global
                    travel debit cards.
                  </p>
                  <p>
                    Leisure operators, vacation home, and boutique hotel owners
                    can directly receive payments for the service rendered at a
                    bare minimum transaction cost.
                  </p>
                </div>
              </li>

              <li>
                <div className="item">
                  <p>What is a Hotelier Mart?</p>
                </div>
                <div className="hint">
                  <p>
                    HotelierMart.com is a blockchain-powered hotel & travel
                    booking platform that utilizes digital currency’s attributes
                    in the form of HotelierCoin to reward travelers wherever
                    they go, whichever hotel or travel product they choose. Now,
                    you don’t need to be loyal to a brand, be loyal to your
                    purpose of visit and still get rewarded.
                  </p>
                  <p>
                    The platform offers hotel, vacation homes, airlines, car
                    rental, travel packages, aggregated, and curated by the
                    virtual travel agent. HotelierCoin is synchronizing and
                    rewarding the hotel and travel industry within our
                    Ethereum-built ecosystem.
                  </p>
                  <p>
                    HotelierMart.com booking engine powers B2E booking extranet
                    for small and medium enterprise for corporate travel
                    booking. Small corporates get access to a wide range of
                    hotels and travel products to reduce travel costs and earn
                    rewards at the same time.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>What is a Travolier?</p>
                </div>
                <div className="hint">
                  <p>
                    Our loyalty program is Travolier. A program set aside to
                    reward travelers, hotel and vacation homeowners, leisure
                    service providers and travel influencers.
                  </p>
                  <p>
                    With it, travelers get access to exclusive hotel rates,
                    travel managers, personalised fintech wallet and debit
                    cards. You can also earn cashback in HotelierCoin with every
                    transaction within our parent website Hoteliermart.com to
                    become a Travolier as members that hold HotelierCoin are
                    called.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>
                    What is the benefit of using Blockchain and smart contracts?
                  </p>
                </div>
                <div className="hint">
                  <p>
                    The technology of blockchain and smart contracts enables a
                    decentralized platform and a seamless ecosystem where all
                    operations and transactions have higher efficiency and
                    security. Combined with the vision of HotelierCoin, this
                    system has the potential to transform the entire hospitality
                    industry for the benefit of all parties.
                  </p>
                  <p>
                    All travelers, vacation homes, hotels, independent leisure
                    service providers are in blockchain, avoid duplication, and
                    promote verified reviews from verified travelers. All
                    leisure service providers contracts will be backed with
                    smart contracts.
                  </p>
                  <p>
                    HotelierCoin replaces the traditional loyalty points, and
                    provide anonymity to the valuation of coins, and usage power
                    goes back to the coin owners than the points issuer.
                    Furthermore, cross-border functionality of cryptocurrency
                    helps to reduce the cost of the transaction for hotel
                    owners.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>How big is the travel market?</p>
                </div>
                <div className="hint">
                  <p>
                    In 2016, the tourism sector added over USD 7.61 trillion to
                    the global economy, nearly 10% of the Global GDP. In the
                    same year, revenue generated through online travel bookings
                    amounted to 513 billion U.S. dollars, forecasted to reach
                    USD 567 billion by 2019. Moreover, last year, the Leisure
                    market potential was USD 129 billion (3x the size of the car
                    rental market) projected to reach $183 billion by 2020.
                  </p>
                  <p>
                    2018, Expedia’s annual revenue reached USD 11.2 Billion,
                    with gross bookings of $99.73 billion, a 13% rise over 2017.
                  </p>
                  <p>
                    As more consumers participate in the brand loyalty program,
                    more of those loyalty points are going unclaimed – to the
                    tune of USD 100 Billion, according to marketing firm Bond
                    Brand Loyalty.
                  </p>
                  <p>
                    Despite the exponential growth and potential, the Hotel &
                    Travel industry continues to lack a solution that simplifies
                    operations, reduces fees, and connects all the moving parts
                    in the sector. HotelierCoin’s platform, App, and HOTEL
                    currency work in conjunction to bridge the gap and connect
                    all stakeholders, including travelers, crypto-investors,
                    service providers, hospitality owners, and operators.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>How did the HotelierCoin start?</p>
                </div>
                <div className="hint">
                  <p>
                    The founder, Rana Mukherji, realized in 2015, while working
                    for Fairmont Hotels & Resorts in Canada, that the entire
                    hospitality industry needs a revamp across the globe. Having
                    worked across Africa, Middle East, India, and North America,
                    all continents have similar underlining issues. Moreover,
                    stand-alone hotels are suffering the most for identity,
                    recognition, and traveller with trust and a genuinely global
                    loyalty program across the eco-system.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>Who is behind HotelierCoin?</p>
                </div>
                <div className="hint">
                  <p>
                    The founder, Rana Mukherji, handpicked all partners and team
                    members. The team is comprised of top experts in hotel
                    management, vacation rentals, travel tech, blockchain
                    developers, and FinTech. Ranges from an absolute legend with
                    50-years’ experience to a highly talented computer science
                    professional. In addition to an outstanding team of experts,
                    we have partnered with leading award-winning companies,
                    advisors, and service providers around the globe that
                    specialize in their respective areas. Founder and core team
                    members have worked for some leading brands like Fairmont,
                    Marriott, Raffles, Accor, Oberoi, Regent, Ritz Carlton,
                    Bespoke Hotels, Always-on Vacation, Rategain, Microsoft,
                    Orange, Delta Airlines, & Priceline.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>Where are the companies based?</p>
                </div>
                <div className="hint">
                  <p>
                    <b>Parent company - Singapore</b>
                  </p>
                  <p>
                    Global Hotelier Pte. Ltd.
                    <br /> 160 Robinson Road #14-04
                    <br />
                    Singapore 068914
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>Who are the partners and team?</p>
                </div>
                <div className="hint">
                  <p>
                    All partners and team members are seasoned experts in their
                    respective fields, from hotel management and finance to
                    blockchain and cryptocurrencies. We have also expanded our
                    knowledge base and operations by partnering with blockchain
                    specialists ‘Plebiscite IT Services’, cryptocurrency
                    enthusiasts ‘Sragy’ and the world-renowned Australian
                    company, ‘RMS, The Hospitality Cloud.’
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>What are the company’s work culture and core value?</p>
                </div>
                <div className="hint">
                  <h5>Our Work Culture</h5>
                  <p>
                    We believe in growing together. Success is when everyone
                    succeeds. We are driven by passion, zeal, and motivation to
                    do something new and challenging every day. We believe in a
                    theory that if you do what you love, it becomes your
                    passion. And work becomes fun when you are passionate about
                    it.
                  </p>
                  <p>
                    At HotelierCoin, work is always fun. Work Hard, and Party
                    Harder is not just a phrase, but it is actually practiced
                    here.
                  </p>
                  <h5>Our Core Values</h5>
                  <ul className="uf-list">
                    <li>
                      Excellence – Every day we are striving to achieve
                      excellence in everything we do.
                    </li>
                    <li>
                      Mutual Respect – We believe in treating everyone with love
                      and respect.
                    </li>
                    <li>
                      Accountability and Integrity – We demonstrate ownership
                      and responsibility of our actions, and we demonstrate
                      integrity in all our actions.
                    </li>
                    <li>
                      Growing Together – Holistic growth is when we all grow
                      together.
                    </li>
                    <li>
                      Keep Evolving - Growing every day is essential; hence we
                      keep evolving continuously.
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>What other services can you offer?</p>
                </div>
                <div className="hint">
                  <p>
                    HotelierCoin offers a complete hospitality and travel
                    solution. From car rental services and tour packages to
                    hiring hospitality products and flights, HotelierCoin has
                    got you covered. We are also proud to introduce the ‘City
                    Chefs’ and ‘City Hosts’ services to all our customers.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>What are city chefs and city hosts?</p>
                </div>
                <div className="hint">
                  <p>
                    This is a truly original concept that is currently being
                    launched by HotelierCoin to be accessed through the
                    HotelierMart.com platform. These allow a customer the
                    opportunity to hire an individual, who lives in the local
                    area to provide home-cooking as a personal chef (City Chefs)
                    or hire a guide to safely show them the sights of the city
                    (City Hosts). The schemes will not only serve to improve the
                    experience of the travellers vastly but also provide a boost
                    to local economies.
                  </p>
                </div>
              </li>
              <li>
                <div className="item">
                  <p>
                    What is the View of the Monetary Authority of Singapore?
                  </p>
                </div>
                <div className="hint">
                  <p>
                    Monetary Authority of Singapore has excepted Loyalty Coins
                  </p>
                  <a
                    target="_blank"
                    href="https://www.loc.gov/law/help/cryptoassets/singapore.php"
                  >
                    https://www.loc.gov/law/help/cryptoassets/singapore.php
                  </a>
                  <p>
                    The “Limited Purpose Digital Payment Token” exclusion refers
                    to payment services, which involve non-monetary consumer
                    loyalty or reward points or in-game assets or similar
                    digital representations of value, which cannot be returned
                    to the issuer or sold, transferred or exchanged for money.
                    On the other hand, the “Central Bank Digital Payment Token”
                    exception is one where a central bank or financial
                    institution provides services for dealing in or facilitating
                    the exchange of central bank digital payment tokens. In the
                    former, Parliament has considered such activities to not
                    pose sufficient risk to warrant regulation under the
                    licensing regime. In respect of the latter, the rationale
                    was that such institutions would have already been
                    sufficiently regulated.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default FaqHome
