import React, { Component } from 'react';
import Navbar from './includes/navbar';
import Footer from './includes/footer';
import './career.css';
import $ from "jquery";


class Careers extends Component {


    componentDidMount() {
        // Mobile menu

        $('.toggle_menu').mouseup(function () {
            $(this).toggleClass("on");
            if($(window).width() <= 550) {
                if($(".mobile_menu").hasClass('visible')) {
                    $('.mobile_menu').hide();
                }
                else {
                    $('.mobile_menu').show();
                }
                $('.mobile_menu').toggleClass('visible');
            } else {
                if($(".topline_bg nav").hasClass('visible')) {
                    $('.topline_bg nav').hide();
                }
                else {
                    $('.topline_bg nav').show();
                }
                $('.topline_bg nav').toggleClass('visible');
            }

        });

    }


    render() {
        return (


            <div>

                <header style={{marginBottom: '0px'}} className="careers-bg">
                    <Navbar />

                    <div className="mobile_menu visible" style={{display: 'none'}}>
                        <ul>
                            <li><a href="#partners" className="mobile_menu_link">Partners</a></li>
                            <li><a href="#platform" className="mobile_menu_link">Ecosystem</a></li>
                            <li><a href="#token" className="mobile_menu_link">HTL Token</a></li>
                            <li><a href="#team" className="mobile_menu_link">Team</a></li>
                            <li><a href="#faq" className="mobile_menu_link">FAQ</a></li>
                            <li><a href="/#Roadmap">Roadmap</a></li>
                            {/*<li><a href="pdf/HotelierCoinLP.pdf" target="_blank">Lightpaper</a></li>*/}
                            <li><a href="/careers">Careers</a></li>
                            <li><a href="/travolierclub" className="link_btn">Travolier's Club</a></li>
                                    <li><a href="/" className="link_btn">Private Sale</a></li>
                            {/*<li><a href="#subscribe" className="mobile_menu_link">Subscribe</a></li>*/}
                        </ul>
                    </div>

                    <div className="header_content content" style={{paddingTop: '100px', marginTop:'200px'}}>
                        <span className="b-text" style={{color: 'white', fontSize: '3.5em', fontWeight:'bold'}}>Hotelier Coin, a place where passionate brains bring innovation at work</span>
                    </div>
                </header>


                <div className="main sm-sz">

                    <section className="video_section" id="about">
                        <div className="content">
                            <h2 className="section_header section_header_line" style={{textAlign:'left'}}>Our Work Culture</h2>

                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-6" style={{padding: '20px 40px 20px 0px'}}>
                                        <span>We believe in growing together. Success is when everyone succeeds.<br /><br /> We are driven by passion, zeal, and motivation to do something new and challenging everyday. <br/><br/>We believe in a theory that if you do what you love, it becomes your passion and work becomes fun when you are passionate about it. <br/><br/>At Hotelier Coin, work is always fun.Work hard &amp; party harder is not just a phrase, but it is actually practiced here. <br/><br/> If you love challenges and think out of the box or think in new boxes, join us for a skyrocketing career today!</span>
                                    </div>

                                    <div className="col-sm-6" style={{textAlign: 'center'}}>
                                        <img className="career-ad" width="460" style={{marginTop: '20px', height: '265px', border: '2px solid #6b2574', display: 'block', maxWidth: '100%;'}} src="https://cdn.pixabay.com/photo/2015/07/15/11/49/success-846055_960_720.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="content">
                            <h2 className="section_header section_header_line" style={{textAlign:'left'}}>Our Core Values</h2>
                            <div className="container">
                                <div className="row">


                                    <div className="col-sm-6">
                                        <h3 className="values" style={{marginTop: '10px'}}>Excellence</h3>
                                        <span style={{color: '#1d1350'}}>Everyday we are striving to achieve excellence in everything we do.</span>

                                        <h3 className="values">Mutual Respect</h3>
                                        <span style={{color: '#1d1350'}}>We believe in treating everyone with love and respect.</span>

                                        <h3 className="values">Accountability and Integrity </h3>
                                        <span
                                            style={{color: '#1d1350'}}>We demonstrate ownership and responsibility of our actions, and we demonstrate integrity in all our actions.</span>


                                        <h3 className="values">Growing Together</h3>
                                        <span style={{color: '#1d1350'}}> Holistic growth is when we all grow together.</span>

                                        <h3 className="values">Keep Evolving</h3>
                                        <span style={{color: '#1d1350'}}>Growing everyday is essential; hence we keep evolving continuously.</span>
                                    </div>

                                    <div className="col-sm-6 text-center" style={{marginTop: '20px', textAlign: 'center'}}>
                                        <img src="https://cdn.pixabay.com/photo/2015/01/09/11/09/startup-594091_960_720.jpg" width="450" style={{border: '2px solid #6b2574',display: 'block', maxWidth: '100%'}} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>


                    <div className="subscribe_now hotelier_in_numbers __web-inspector-hide-shortcut__" style={{padding: '80px 0px'}}>
                        <div className="content">
                            <div style={{ maxWidth: '100%', padding: '80px 60px', textAlign: 'left'}}>
                                <h1 className="s-j" style={{ fontFamily: 'inherit', textAlign: 'left', fontSize:'2em', fontWeight: '600', color: '#383838'}}>Great things in organizations are
                                    never done by one person. They're done by a team of people.</h1>
                                <p style={{textAlign: 'left', marginTop: '25px'}}>— Steve Jobs</p>
                            </div>
                        </div>
                    </div>


                    <section className="content">
                        <h2 className="section_header section_header_line" style={{textAlign:'left'}}>Why Join Us</h2>
                        <div className="join" style={{textAlign: 'left'}}>
                            <p>Be a Part of the Future of Hospitality</p>

                            <span style={{lineHeight: '1.5em'}}>We believe that there is life at work and life outside of work too. Our team is our family and family cares for each other. We want our team members to enjoy their work, be healthy, spend vacation with family, and be financially secure and happy at the same time. Hence, we try our best to create a workplace where you feel welcome and can contribute your very best. We love to contribute to the community, all while maintaining a beautiful work experience.</span>
                        </div>
                    </section>


                    <section className="content">
                        <h2 className="section_header section_header_line" style={{textAlign:'left'}}>Join Us</h2>
                        <div className="container join" style={{background: '#f9f6fb', padding: '30px',textAlign: 'left'}}>
                            <p>Have questions?</p>
                            <span>Contact us at : <a href="mailto:careers@hoteliercoin.com">careers@hoteliercoin.com</a>
                            <br />
                            </span><br /><br />
                        </div>
                    </section>

                </div>


                <Footer />


            </div>
                    );
    }
}

export default Careers;
