import React, { Component } from 'react';
import Navbar from './includes/navbar';
import Footer from './includes/footer';

import mailBox from './public/landing/img/mailbox.png';
import './emailverification.css';
import $ from "jquery";


class EmailVerification extends Component {


    componentDidMount() {
        // Mobile menu

        $('.toggle_menu').mouseup(function () {
            $(this).toggleClass("on");
            if($(window).width() <= 550) {
                if($(".mobile_menu").hasClass('visible')) {
                    $('.mobile_menu').hide();
                }
                else {
                    $('.mobile_menu').show();
                }
                $('.mobile_menu').toggleClass('visible');
            } else {
                if($(".topline_bg nav").hasClass('visible')) {
                    $('.topline_bg nav').hide();
                }
                else {
                    $('.topline_bg nav').show();
                }
                $('.topline_bg nav').toggleClass('visible');
            }

        });

   

    

    }


    render() {
        return (


            <div className="email_verification_main">

                <header style={{marginBottom: '0px'}} className="email_verification_main_bg">
                    <Navbar />                   
                </header>


                <div className="main">
                    <div className="verification_box">
                        <img className="mailbox_img" src={mailBox} />
                        <h2>Email Verification</h2>
                        <p className="m_p">We've sent an email to your registered email address: ia***@***.com Follow the instructions in the email to get started!</p>
                        <button className="btn">Go to email and verify</button>
                        <p>If you haven't received the email, please try the following</p>
                        <ul>
                            <li>Make sure the email address you provided is correct.</li>
                            <li> Check your Spam or Junk mail folders.</li>
                            <li>Add Hoteliercoin to your email address whitelist.</li>
                            <li> Make sure your email is functioning normally.</li>
                        </ul>
                        <a href="#" className="btn_link">Resend email</a>
                    </div>
                </div>


                <Footer />


            </div>
                    );
    }
}

export default EmailVerification;
